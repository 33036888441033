import React from 'react';
import { FlexBox, Box } from 'tuxedo';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import PasswordCheckInfo from './PasswordCheckInfo';
import { Body } from 'tuxedo-v2';

const StyledPasswordCheckContainer = styled(Box)`
  padding: ${themeGet('space.4')}px;
  margin-top: ${themeGet('space.3')}px;
  border: ${themeGet('borders.1')}px solid ${themeGet('colors.grey.2')};
  background-color: ${themeGet('colors.grey.0')};
  border-radius: ${themeGet('radii.2')}px;
`;

interface Props {
  isMinimumChar: boolean;
  isUpperChar: boolean;
  isLowerChar: boolean;
  isNumericChar: boolean;
  isSpecialChar: boolean;
}

const PasswordCheckerComponent = ({
  isMinimumChar,
  isUpperChar,
  isLowerChar,
  isNumericChar,
  isSpecialChar
}: Props): JSX.Element => {
  return (
    <StyledPasswordCheckContainer>
      <FlexBox flexDirection='column'>
        <Body size='small' weight='medium'>
          Password must have
        </Body>
        <PasswordCheckInfo
          label='Minimum 8 characters'
          isActive={isMinimumChar}
        />
        <PasswordCheckInfo
          label='At least 1 uppercase letter'
          isActive={isUpperChar}
        />
        <PasswordCheckInfo
          label='At least 1 lowercase letter'
          isActive={isLowerChar}
        />
        <PasswordCheckInfo label='At lease 1 number' isActive={isNumericChar} />
        <PasswordCheckInfo
          label='At least 1 special character'
          isActive={isSpecialChar}
          showTooltip
          tooltipText={
            `Special characters allowed are
            !"#$%&'()*+,-./:;<=>?@[\\]^_{|}~ ` + '`'
          }
        />
      </FlexBox>
    </StyledPasswordCheckContainer>
  );
};

export default PasswordCheckerComponent;
