import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface AuthMetaData {
  code: string;
  synaptic_session_exp: number;
}

export interface SSOLoginRequestBody {
  email: string;
  code: string;
  provider: string;
}

export interface SSOLoginResponse {
  data: {
    auth_url: string;
    errors: [
      {
        [key: string]: string;
      }
    ];
    is_error: boolean;
    additional_messages: {
      attempts: string;
      subtext: string;
      error_text: string;
    };
  };
  meta: AuthMetaData;
  login_session_expired?: boolean;
}

const service = 'auth/authorizations/auth_url';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useSSOLoginApi(): UseMutationResult<SSOLoginResponse> {
  return useMutation((requestBody: SSOLoginRequestBody) => {
    return api.get<SSOLoginResponse>(serverURL, requestBody);
  });
}
