import React, { useState } from 'react';
import { Input, Body, Box, FlexBox } from 'tuxedo-v2';
import { Spacings } from 'theme/types';
import { InputRef } from 'tuxedo-v2/components/components/input/types';

interface Props {
  inputHeader?: string;
  placeholder?: string;
  isError?: boolean;
  errorText?: string;
  mt?: keyof Spacings;
  password?: boolean;
  value?: string;
  type?: 'number' | 'text' | 'password' | 'email';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  tabIndex?: number;
}

function TextInput(
  {
    inputHeader,
    placeholder,
    isError = false,
    errorText,
    mt = 6,
    value,
    type = 'text',
    onChange,
    onEnter,
    tabIndex
  }: Props,
  forwardRef: React.RefObject<InputRef>
): JSX.Element {
  const [isCapsLockOn, setIsCapsLockOn] = useState<boolean>(false);

  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if (keyEvent.key === 'Enter' && onEnter) {
      onEnter();
    }
    if (keyEvent.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <Box mt={mt}>
      {inputHeader && (
        <FlexBox mb={1}>
          <Body size='small' weight='regular'>
            {inputHeader}
          </Body>
        </FlexBox>
      )}
      <Input.Base
        size='large'
        placeholder={placeholder}
        hasError={isError}
        value={value}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={forwardRef as React.RefObject<InputRef>}
        tabIndex={tabIndex}
        notBlurOnSubmit
      />

      {isError && errorText && (
        <Body size='small' weight='regular' color='error'>
          {errorText}
        </Body>
      )}
      {type === 'password' && isCapsLockOn && !isError && (
        <Body size='small' weight='regular' color='warning'>
          Caps Lock is on
        </Body>
      )}
    </Box>
  );
}

export default React.forwardRef(TextInput);
