import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface AuthMetaData {
  code: string;
  synaptic_session_exp: number;
}

export interface ApiResponseBody {
  message: string;
  sub_message: string;
  additional_messages: {
    attempts: string;
    subtext: string;
    error_text: string;
  };
  is_error: boolean;
}

export interface SignInRequestBody {
  email: string;
  password: string;
  code: string;
}

export interface SignInResponse {
  data: {
    authorization_code: string;
    incorrect_mfa: ApiResponseBody;
    resend_mfa: ApiResponseBody;
    errors: { [key: string]: string }[];
    additional_messages: {
      attempts: string;
      subtext: string;
      error_text: string;
    };
    is_error: boolean;
  };
  meta: AuthMetaData;
  login_session_expired?: boolean;
}

const service = 'auth/sessions/sign_in';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useSignInApi(): UseMutationResult<SignInResponse> {
  return useMutation((requestBody: SignInRequestBody) => {
    return api.post<SignInResponse>(serverURL, requestBody);
  });
}
