import React, { useState, Fragment } from 'react';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from './mode';
import defaultTheme from '../themes/default';
import { ThemeMap, createTheme } from './util';
import { Theme } from '../types';

interface Props {
  children: React.ReactNode;
  defaultMode?: 'light' | 'dark';
  themeName?: keyof typeof ThemeMap;
}

const Provider = ({
  children,
  defaultMode = 'light',
  themeName = 'default'
}: Props): JSX.Element => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    setTheme(createTheme(themeName, defaultMode));
  }, [themeName, defaultMode]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider
        theme={(themeParent: Theme) => ({
          ...themeParent,
          v2: { ...theme, themeMode: defaultMode }
        })}
      >
        <Fragment>{children}</Fragment>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default Provider;

export * from './mode';
