import React, { useEffect, useRef } from 'react';
import { ButtonV2, FlexBox, Body, Box, FlatButton } from 'tuxedo-v2';
import TextInput from 'shared/components/password-checker/TextInput';
import { useValidateOtp } from 'shared/modules/auth-module/hooks';
import { SignInResponse } from 'shared/modules/auth-module/types';
import { InputRef } from 'tuxedo-v2/components/components/input/types';

import StatusCard from './StatusCard';
import { StyledHeaderText } from '../styled';
import { getUiAutomationIdentifierClass } from '../utils';
import { APP_NAME } from '../constants';

interface Props {
  signInResponse: SignInResponse | undefined;
}

function OtpForm({ signInResponse }: Props): JSX.Element {
  const inputRef: React.RefObject<InputRef> = useRef(null);

  const {
    defaultMessage,
    defaultSubText,
    isLoading: isValidateOtpLoading,
    handleValidateOtp,
    isError,
    securityCode,
    setSecurityCode,
    validateStatusLabel,
    validateStatusSubLabel,
    isWarning,
    errorText,
    isErrorTextShown,
    // Resend
    isResendError,
    isResendLoading,
    handleGenerateOtp,
    resendStatusLabel,
    resendStatusSubLabel,
    // MFA attempts exhausted
    mfaAttemptExceededLabel,
    mfaAttemptExceededSubLabel,
    showMfaAttemptsExhaustedError,
    hideInput
  } = useValidateOtp(signInResponse);

  const handleResendOtp = () => {
    handleGenerateOtp();
  };

  const otpValidateHandler = () => {
    handleValidateOtp();
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current?.focusInput();
    }
  }, []);

  return (
    <FlexBox flexDirection='column'>
      <StyledHeaderText>Sign In to {APP_NAME}</StyledHeaderText>

      <FlexBox flexDirection='column'>
        <Body size='large' weight='medium'>
          {defaultMessage}
        </Body>
        <Box mt={1}>
          <Body weight='regular' color='text2'>
            {defaultSubText}
          </Body>
        </Box>
      </FlexBox>

      {isResendError && (
        <StatusCard
          type={isResendError ? 'error' : 'warning'}
          label={resendStatusLabel}
          subLabel={resendStatusSubLabel}
          mt={4}
        />
      )}
      {showMfaAttemptsExhaustedError && (
        <StatusCard
          type='error'
          label={mfaAttemptExceededLabel}
          subLabel={mfaAttemptExceededSubLabel}
          mt={4}
          addSupport
        />
      )}
      {isError && (
        <StatusCard
          type={isWarning ? 'warning' : 'error'}
          label={validateStatusLabel}
          subLabel={validateStatusSubLabel}
          mt={4}
          addSupport={!isWarning}
        />
      )}
      {!hideInput && (
        <FlexBox flexDirection='column'>
          <TextInput
            value={securityCode}
            onChange={setSecurityCode}
            inputHeader='SECURITY CODE'
            placeholder='Enter Security Code'
            type='text'
            isError={isError && isErrorTextShown}
            ref={inputRef}
            tabIndex={1}
            onEnter={otpValidateHandler}
          />
          {!isErrorTextShown && (
            <Body size='small' weight='regular' color='error'>
              {errorText}
            </Body>
          )}
          {isResendError || showMfaAttemptsExhaustedError ? (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam.
              </Body>
            </Box>
          ) : (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam or{' '}
                {isResendLoading ? (
                  'resend code'
                ) : (
                  <FlatButton
                    text='resend code'
                    className={getUiAutomationIdentifierClass(
                      'totp',
                      'Resend code button'
                    )}
                    tabIndex={3}
                    onClick={handleResendOtp}
                    inline
                  />
                )}
              </Body>
            </Box>
          )}
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              isLoading={isValidateOtpLoading}
              onClick={otpValidateHandler}
              text='Continue'
              className={getUiAutomationIdentifierClass(
                'totp',
                'Validate button'
              )}
              tabIndex={2}
            />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default OtpForm;
