import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired,
  isInternetAvailable
} from 'shared/services';
import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import {
  useForgotPasswordApi,
  ForgotPasswordResponse
} from 'shared/api/v5/auth/passwords/forgot_password/get';
import { SetInput } from '../types';
import { useModuleState } from '../module-state';

interface Return {
  isLoading: boolean;
  forgotPasswordResponse: ForgotPasswordResponse | undefined;
  handleForgotPassword: () => void;
  isError: boolean;
  email: string;
  setEmail: SetInput;
  isValidEmail: boolean;
  errorTextEmail: string;
  isWarning: boolean;
  statusLabel: string;
  statusSubLabel: string;
  responseMessage: string;
  responseSubMessage: string;
  hideErrorState: () => void;
}

export const useForgotPassword = (): Return => {
  const [
    {
      isErrorForgotPassword: isError,
      isWarningForgotPassword: isWarning,
      statusLabelForgotPassword: statusLabel,
      statusSubLabelForgotPassword: statusSubLabel,
      responseMessageForgotPassword: responseMessage,
      responseSubMessageForgotPassword: responseSubMessage
    },
    setModuleState
  ] = useModuleState();
  const { setIsOfflineErrorShown } = useInternetConnection();
  const {
    mutate,
    data: forgotPasswordResponse,
    isLoading
  } = useForgotPasswordApi();

  const [email, setEmail, isValidEmail, errorTextEmail, touched, setTouched] =
    useInput('email');

  const hideErrorState = () => {
    setModuleState({
      isWarningForgotPassword: false,
      isErrorForgotPassword: false,
      responseMessageForgotPassword: ''
    });
    setTouched(false);
  };

  const handleForgotPassword = async () => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      setTouched(true);
      const { code } = await getAuthDataFromStorage(['code']);
      if (isValidEmail) {
        mutate(
          { email, code },
          {
            onSuccess: (response: ForgotPasswordResponse) => {
              if (response?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                setModuleState({
                  responseMessageForgotPassword: response?.data?.message || '',
                  responseSubMessageForgotPassword:
                    response?.data?.sub_message || ''
                });

                if (
                  response?.data?.additional_messages?.attempts &&
                  response?.data?.additional_messages?.attempts !== ''
                ) {
                  setModuleState({
                    statusLabelForgotPassword:
                      response?.data?.additional_messages?.attempts || '',
                    statusSubLabelForgotPassword:
                      response?.data?.additional_messages?.subtext || '',
                    isWarningForgotPassword: true
                  });
                }
                if (
                  response?.meta?.code &&
                  response?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: response?.meta?.code,
                    synaptic_session_exp: response?.meta?.synaptic_session_exp
                  });
                }
                if (response?.data?.is_error) {
                  setModuleState({ isErrorForgotPassword: true });
                }
              }
            },
            onError: (error: { data: ForgotPasswordResponse }) => {
              const { data: errorResponse } = error;
              if (errorResponse?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                if (
                  errorResponse?.meta?.code &&
                  errorResponse?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: errorResponse?.meta?.code,
                    synaptic_session_exp:
                      errorResponse?.meta?.synaptic_session_exp
                  });
                }
                setModuleState({
                  responseMessageForgotPassword:
                    errorResponse?.data?.message || '',
                  responseSubMessageForgotPassword:
                    errorResponse?.data?.sub_message || '',
                  isErrorForgotPassword: true
                });
              }
            }
          }
        );
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  const isInputError = touched && (isError || !isValidEmail);

  return {
    isLoading,
    forgotPasswordResponse,
    handleForgotPassword,
    isError: isInputError,
    email,
    setEmail,
    isValidEmail,
    errorTextEmail,
    isWarning,
    statusLabel,
    statusSubLabel,
    responseMessage,
    responseSubMessage,
    hideErrorState
  };
};
