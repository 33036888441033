import React from 'react';
import themeGet from '../../../utils/theme-get';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import rotate from '../../../keyframes/rotate';
import { SpaceProps } from '../../../props';
import { IconSizeKeys, IconColors } from 'theme-v2/types';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import { ThemeMode, useThemeMode } from 'theme-v2/provider/index';

export interface StyledIProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'color'>,
    SpaceProps {
  color?: keyof IconColors;
  size?: IconSizeKeys;
  spin?: boolean;
  themeMode?: ThemeMode;
}

const StyledI = styled.i<StyledIProps>`
  ${space}
  display: inline-block;
  ${props => {
    return css`
      font-size: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      height: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      width: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      ${props.color
        ? `color: ${themeGet(`colors.icon.${props.themeMode}.${props.color}`)(
            props
          )};`
        : ''}
      ::before {
        ${props.spin
          ? css`
              display: inline-block;
              animation: 1.5s ${rotate} infinite;
            `
          : ''}
      }
    `;
  }};
`;

export type FontIconProps = Omit<StyledIProps, 'themeMode'> & {
  type: string;
};

const FontIcon = ({
  type,
  className = '',
  size = 0,
  spin = false,
  ...rest
}: FontIconProps): JSX.Element => {
  const [themeMode] = useThemeMode();
  // replace icon- with icon-v2-
  const typeV2 = type.replace('icon-', 'icon-v2-');

  return (
    <StyledI
      className={`${typeV2} ${className}`}
      size={size}
      spin={spin}
      themeMode={themeMode}
      {...rest}
    />
  );
};

FontIcon.displayName = 'FontIcon';

export default WithV2Theme(FontIcon);
