import {
  FONT_SIZE_100,
  FONT_SIZE_200,
  FONT_SIZE_300,
  FONT_SIZE_75,
  FONT_SIZE_400,
  FONT_SIZE_500,
  FONT_SIZE_600,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_SEMI_BOLD,
  FONT_WEIGHT_BOLD,
  LINE_HEIGHT_50,
  LINE_HEIGHT_100,
  LINE_HEIGHT_200,
  LINE_HEIGHT_300,
  LINE_HEIGHT_500,
  LINE_HEIGHT_700
} from '../../design-variables';

const fontSizes = {
  75: FONT_SIZE_75,
  100: FONT_SIZE_100,
  200: FONT_SIZE_200,
  300: FONT_SIZE_300,
  400: FONT_SIZE_400,
  500: FONT_SIZE_500,
  600: FONT_SIZE_600
};

export const fontWeights = {
  regular: FONT_WEIGHT_REGULAR,
  medium: FONT_WEIGHT_MEDIUM,
  'semi-bold': FONT_WEIGHT_SEMI_BOLD,
  bold: FONT_WEIGHT_BOLD
};

export const lineHeights = {
  50: LINE_HEIGHT_50,
  100: LINE_HEIGHT_100,
  200: LINE_HEIGHT_200,
  300: LINE_HEIGHT_300,
  500: LINE_HEIGHT_500,
  700: LINE_HEIGHT_700
};

export default {
  fontSizes,
  fontWeights,
  lineHeights
};
