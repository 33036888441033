import { User, Window } from 'shared/types';
import { clone } from 'lodash';

declare const window: Window;

export function getUser(): User | null {
  return window.user;
}

export function setUser(userObj: User | null): void {
  window.user = clone(userObj);
}

export function getUserFromStorage(): Promise<User | null> {
  return new Promise((resolve, reject): void => {
    try {
      const user = window.localStorage.getItem('user');
      const userObj = user !== null ? JSON.parse(user) : null;

      window.synaptic_user = userObj;
      window.user = userObj !== null ? clone(userObj) : null;
      resolve(userObj);
    } catch (e) {
      reject(e);
    }
  });
}

export function setUserToStorage(userObj: User | null): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      window.localStorage.setItem('user', JSON.stringify(userObj));

      window.synaptic_user = userObj;
      window.user = userObj !== null ? clone(userObj) : null;
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
