import React from 'react';

import { StyledFooterContainer } from '../styled';
import { Body } from 'tuxedo-v2';
import Constants from 'shared/constants/config';
function FooterComponent(): JSX.Element {
  return (
    <StyledFooterContainer>
      <a
        href={Constants.PRIVACY_POLICY_URL}
        target={'_blank'}
        style={{ textDecoration: 'none' }}
      >
        <Body size='small' weight='regular' color='text2'>
          Privacy Policy
        </Body>
      </a>
    </StyledFooterContainer>
  );
}

export default FooterComponent;
