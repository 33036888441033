export enum APP_NAMES {
  PORTFOLIO_IQ = 'portfolio-iq',
  SYNAPTIC = 'synaptic',
  FORGE = 'forge'
}

export const APP_NAME_MAPPING: Record<string, string> = {
  'portfolio-iq': 'PortfolioIQ',
  synaptic: 'Synaptic',
  forge: 'Forge'
};

export const THEME_NAME_MAPPING: Record<string, string> = {
  forge: 'forge'
};

export const APP_NAME = APP_NAME_MAPPING[__APP_NAME__ || APP_NAMES.SYNAPTIC];

export const THEME_NAME = THEME_NAME_MAPPING[__APP_NAME__] || 'default';
