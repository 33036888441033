import {
  usePasswordAcceptApi,
  PasswordAcceptRequestBody,
  PasswordAcceptResponse
} from 'shared/api/v5/auth/passwords/accept/get';
import { getAuthDataFromStorage } from 'shared/services';

interface Return {
  isLoading: boolean;
  passwordAcceptResponse: PasswordAcceptResponse | undefined;
  handlePasswordAccept: (requestBody: PasswordAcceptRequestBody) => void;
  isError: boolean;
  resetErrorState: () => void;
}

export const usePasswordAccept = (): Return => {
  const {
    mutate,
    data: passwordAcceptResponse,
    isLoading,
    isError
  } = usePasswordAcceptApi();

  const setIsError = (arg: boolean) => {
    console.log(arg);
  };

  const resetErrorState = () => {
    setIsError(false);
  };

  const handlePasswordAccept = async (
    requestBody: PasswordAcceptRequestBody
  ) => {
    const { code } = await getAuthDataFromStorage(['code']);
    mutate(
      { ...requestBody, code },
      {
        onError: () => {
          setIsError(true);
        }
      }
    );
  };

  return {
    isError,
    isLoading,
    passwordAcceptResponse,
    handlePasswordAccept,
    resetErrorState
  };
};
