import React from 'react';
import { Body, FlexBox, FlatButton, Box, Img } from 'tuxedo-v2';
import LinkImg from 'theme/assets/images/auth-module/link.png';
import SessionImg from 'theme/assets/images/auth-module/session-error.png';
import Error403 from 'theme/assets/images/auth-module/error-403.png';
import InternalServerError from 'theme/assets/images/auth-module/internal-error.png';
import ServerDownError from 'theme/assets/images/auth-module/down-error.png';
import Image404 from 'theme/assets/images/auth-module/error-404.png';

import { useErrorState } from 'shared/modules/auth-module/hooks';
import { ErrorStateType } from 'shared/modules/auth-module/types';

import { StyledErrorPageImageContainer, StyledAnchorTag } from '../styled';

import { imageConfig } from '../config';

interface Props {
  type: string;
}

export const HomeNavigationLink = (): JSX.Element => {
  return (
    <Box mt={1}>
      <Body weight='regular' color='text2'>
        Go to{' '}
        <FlatButton
          onClick={() => (window.location.href = '/login')}
          tabIndex={1}
          text='Home'
          inline
        />{' '}
        or{' '}
        <FlatButton inline>
          <StyledAnchorTag href='mailto:support@synaptic.com'>
            Contact us
          </StyledAnchorTag>
        </FlatButton>
      </Body>
    </Box>
  );
};

const ErrorPageView = ({ type }: Props): JSX.Element => {
  const { isGeneric, isBroken, isSession, isAccess, is500, is504 } =
    useErrorState(type as ErrorStateType);

  return (
    <FlexBox
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      _height='100vh'
    >
      {imageConfig.logo}
      {isGeneric && (
        <FlexBox flexDirection='column' alignItems='center'>
          <FlexBox my={8}>
            <Img src={Image404} />
          </FlexBox>
          <Body size='large' weight='medium'>
            A little lost? Let's try again.
          </Body>
          <HomeNavigationLink />
        </FlexBox>
      )}
      {isBroken && (
        <FlexBox flexDirection='column' alignItems='center'>
          <FlexBox mt={22} mb={12}>
            <Img src={LinkImg} />
          </FlexBox>
          <Body size='large' weight='medium'>
            This link seems broken or might have expired
          </Body>
          <HomeNavigationLink />
        </FlexBox>
      )}
      {isSession && (
        <FlexBox flexDirection='column' alignItems='center'>
          <StyledErrorPageImageContainer mb={11}>
            <Img src={SessionImg} />
          </StyledErrorPageImageContainer>
          <Body size='large' weight='medium'>
            Sorry, signing you in took longer than expected
          </Body>
          <Box mt={1}>
            <Body weight='regular' color='text2'>
              <FlatButton
                onClick={() => (window.location.href = '/login')}
                tabIndex={1}
                text='Sign in'
                inline
              />{' '}
              again to continue
            </Body>
          </Box>
        </FlexBox>
      )}
      {is504 && (
        <FlexBox flexDirection='column' alignItems='center'>
          <FlexBox mt={14} mb={9}>
            <Img src={ServerDownError} />
          </FlexBox>
          <Body size='large' weight='medium'>
            Sorry, we’re down for scheduled maintenance right now
          </Body>
          <HomeNavigationLink />
        </FlexBox>
      )}
      {is500 && (
        <FlexBox flexDirection='column' alignItems='center'>
          <FlexBox mt={7}>
            <Img src={InternalServerError} />
          </FlexBox>
          <Body size='large' weight='medium'>
            An error has occurred and we’re working to fix the problem!
          </Body>
          <HomeNavigationLink />
        </FlexBox>
      )}
      {isAccess && (
        <FlexBox flexDirection='column' alignItems='center'>
          <FlexBox mt={17} mb={15}>
            <Img src={Error403} />
          </FlexBox>
          <Body size='large' weight='medium'>
            Looks like you don’t have access to this page
          </Body>
          <HomeNavigationLink />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default ErrorPageView;
