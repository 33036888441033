import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface GenerateTokenRequestBody {
  authorization_code?: string;
  code_verifier?: string;
  refresh_token?: string;
  client_id: string;
}

export interface GenerateTokenResponse {
  data: {
    access_token: string;
    refresh_token: string;
    errors: { [key: string]: string }[];
  };
}

const service = 'auth/sessions/token_post';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useGenerateTokenApi(): UseMutationResult<GenerateTokenResponse> {
  return useMutation((requestBody: GenerateTokenRequestBody) => {
    return api.post<GenerateTokenResponse>(serverURL, requestBody);
  });
}

export function generateTokenApi(
  requestBody: GenerateTokenRequestBody
): Promise<GenerateTokenResponse> {
  return api.post<GenerateTokenResponse>(serverURL, requestBody);
}
