import { ThemeMode } from 'theme-v2/provider/mode';
import { IconSizeKeys } from 'theme-v2/types';

import {
  IconState,
  ButtonState,
  IconButtonProps,
  ButtonSize,
  ButtonColor,
  ButtonVariant,
  FlatButtonBaseStyleProps
} from './types';

export const sizeProps: {
  [key in ButtonSize]: {
    fontSize: string;
    gap: string;
    iconSize: IconSizeKeys;
    lineHeight: string;
  };
} = {
  small: {
    fontSize: 'fontSizes.1',
    gap: 'space.1',
    iconSize: 2,
    lineHeight: 'longformLineHeights.1'
  },
  medium: {
    fontSize: 'fontSizes.1',
    gap: 'space.1',
    iconSize: 2,
    lineHeight: 'longformLineHeights.1'
  }
};

export const sizeIconStateProps: {
  [key in ButtonSize]: {
    [key in IconState]: {
      paddingTop: string;
      paddingRight: string;
      paddingBottom: string;
      paddingLeft: string;
    };
  };
} = {
  small: {
    none: {
      paddingTop: 'oddSpace.1',
      paddingRight: 'space.3',
      paddingBottom: 'oddSpace.1',
      paddingLeft: 'space.3'
    },
    left: {
      paddingTop: 'oddSpace.1',
      paddingRight: 'space.3',
      paddingBottom: 'oddSpace.1',
      paddingLeft: 'space.2'
    },
    right: {
      paddingTop: 'oddSpace.1',
      paddingRight: 'space.2',
      paddingBottom: 'oddSpace.1',
      paddingLeft: 'space.3'
    },
    only: {
      paddingTop: 'oddSpace.1',
      paddingRight: 'oddSpace.1',
      paddingBottom: 'oddSpace.1',
      paddingLeft: 'oddSpace.1'
    }
  },
  medium: {
    none: {
      paddingTop: 'oddSpace.2',
      paddingRight: 'space.4',
      paddingBottom: 'oddSpace.2',
      paddingLeft: 'space.4'
    },
    left: {
      paddingTop: 'oddSpace.2',
      paddingRight: 'space.4',
      paddingBottom: 'oddSpace.2',
      paddingLeft: 'space.3'
    },
    right: {
      paddingTop: 'oddSpace.2',
      paddingRight: 'space.3',
      paddingBottom: 'oddSpace.2',
      paddingLeft: 'space.4'
    },
    only: {
      paddingTop: 'oddSpace.2',
      paddingRight: 'oddSpace.2',
      paddingBottom: 'oddSpace.2',
      paddingLeft: 'oddSpace.2'
    }
  }
};

export const themeVariantButtonStateProps: {
  [key in ThemeMode]: {
    [key in ButtonColor]: {
      [key in ButtonVariant]: {
        [key in ButtonState]: {
          backgroundColor: string;
          color: string;
        };
      };
    };
  };
} = {
  light: {
    brand: {
      primary: {
        default: {
          backgroundColor: 'colors.brand.600',
          color: 'colors.text.light.on'
        },
        hover: {
          backgroundColor: 'colors.brand.700',
          color: 'colors.text.light.on'
        },
        pressed: {
          backgroundColor: 'colors.brand.700',
          color: 'colors.text.light.on'
        },
        disabled: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text3'
        }
      },
      secondary: {
        default: {
          backgroundColor: 'colors.brand.50',
          color: 'colors.text.light.brand'
        },
        hover: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.text.light.brand'
        },
        pressed: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.brand.700'
        },
        disabled: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text3'
        }
      },
      'tertiary-brand': {
        default: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.brand'
        },
        hover: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.text.light.brand'
        },
        pressed: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.brand.700'
        },
        disabled: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text3'
        }
      },
      'tertiary-dark': {
        default: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text1'
        },
        hover: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text1'
        },
        pressed: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.cool-grey.900'
        },
        disabled: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text3'
        }
      }
    },
    negative: {
      primary: {
        default: {
          backgroundColor: 'colors.icon.light.error',
          color: 'colors.text.light.on'
        },
        hover: {
          backgroundColor: 'colors.error.700',
          color: 'colors.text.light.on'
        },
        pressed: {
          backgroundColor: 'colors.error.700',
          color: 'colors.text.light.on'
        },
        disabled: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text3'
        }
      },
      secondary: {
        default: {
          backgroundColor: 'colors.brand.50',
          color: 'colors.text.light.brand'
        },
        hover: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.text.light.brand'
        },
        pressed: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.brand.700'
        },
        disabled: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text3'
        }
      },
      'tertiary-brand': {
        default: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.brand'
        },
        hover: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.text.light.brand'
        },
        pressed: {
          backgroundColor: 'colors.brand.100',
          color: 'colors.brand.700'
        },
        disabled: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text3'
        }
      },
      'tertiary-dark': {
        default: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text1'
        },
        hover: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text1'
        },
        pressed: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.cool-grey.900'
        },
        disabled: {
          backgroundColor: 'colors.transparent',
          color: 'colors.text.light.text3'
        }
      }
    }
  },
  dark: {
    brand: {
      primary: {
        default: {
          backgroundColor: 'colors.brand.600',
          color: 'colors.white'
        },
        hover: {
          backgroundColor: 'colors.brand.700',
          color: 'colors.white'
        },
        pressed: {
          backgroundColor: 'colors.brand.700',
          color: 'colors.white'
        },
        disabled: {
          backgroundColor: 'colors.warm-grey.400',
          color: 'colors.warm-grey.500'
        }
      },
      secondary: {
        default: {
          backgroundColor: 'colors.warm-grey.600',
          color: 'colors.white'
        },
        hover: {
          backgroundColor: 'colors.warm-grey.700',
          color: 'colors.white'
        },
        pressed: {
          backgroundColor: 'colors.warm-grey.700',
          color: 'colors.white'
        },
        disabled: {
          backgroundColor: 'colors.warm-grey.600',
          color: 'colors.warm-grey.500'
        }
      },
      'tertiary-brand': {
        default: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.500'
        },
        hover: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.600'
        },
        pressed: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.600'
        },
        disabled: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.500'
        }
      },
      'tertiary-dark': {
        default: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.25'
        },
        hover: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.200'
        },
        pressed: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.200'
        },
        disabled: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.500'
        }
      }
    },
    negative: {
      primary: {
        default: {
          backgroundColor: 'colors.error.600',
          color: 'colors.white'
        },
        hover: {
          backgroundColor: 'colors.error.700',
          color: 'colors.white'
        },
        pressed: {
          backgroundColor: 'colors.error.700',
          color: 'colors.white'
        },
        disabled: {
          backgroundColor: 'colors.cool-grey.100',
          color: 'colors.text.light.text3'
        }
      },
      secondary: {
        default: {
          backgroundColor: 'colors.warm-grey.600',
          color: 'colors.white'
        },
        hover: {
          backgroundColor: 'colors.warm-grey.700',
          color: 'colors.white'
        },
        pressed: {
          backgroundColor: 'colors.warm-grey.700',
          color: 'colors.white'
        },
        disabled: {
          backgroundColor: 'colors.warm-grey.600',
          color: 'colors.warm-grey.500'
        }
      },
      'tertiary-brand': {
        default: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.500'
        },
        hover: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.600'
        },
        pressed: {
          backgroundColor: 'colors.black',
          color: 'colors.brand.600'
        },
        disabled: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.500'
        }
      },
      'tertiary-dark': {
        default: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.25'
        },
        hover: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.200'
        },
        pressed: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.200'
        },
        disabled: {
          backgroundColor: 'colors.black',
          color: 'colors.warm-grey.500'
        }
      }
    }
  }
};

export const flatButtonSizeProps: {
  [key in FlatButtonBaseStyleProps['size']]: {
    fontSize: string;
    iconSize: IconSizeKeys;
    lineHeight: string;
  };
} = {
  small: {
    fontSize: 'fontSizes.0',
    iconSize: 1,
    lineHeight: 'longformLineHeights.0'
  },
  medium: {
    fontSize: 'fontSizes.1',
    iconSize: 2,
    lineHeight: 'longformLineHeights.1'
  }
};

export const flatButtonThemeVariantStateProps: {
  [key in ButtonState]: {
    color: string;
  };
} = {
  default: {
    color: 'colors.text.light.brand'
  },
  hover: {
    color: 'colors.brand.700'
  },
  pressed: {
    color: 'colors.brand.700'
  },
  disabled: {
    color: 'colors.text.light.text3'
  }
};

export const iconButtonSizeProps: {
  [key in IconButtonProps['size']]: {
    fontSize: string;
    iconSize: IconSizeKeys;
    lineHeight: string;
  };
} = {
  small: {
    fontSize: 'fontSizes.0',
    iconSize: 1,
    lineHeight: 'longformLineHeights.0'
  },
  medium: {
    fontSize: 'fontSizes.1',
    iconSize: 2,
    lineHeight: 'longformLineHeights.1'
  }
};

export const iconButtonThemeVariantStateProps: {
  [key in ButtonState]: {
    color: string;
  };
} = {
  default: {
    color: 'colors.icon.light.icon2'
  },
  hover: {
    color: 'colors.icon.light.icon1'
  },
  pressed: {
    color: 'colors.icon.light.icon1'
  },
  disabled: {
    color: 'colors.icon.light.icon3'
  }
};

export const linkButtonThemeVariantStateProps: {
  [key in ButtonState]: {
    color: string;
  };
} = {
  default: {
    color: 'colors.text.light.brand'
  },
  hover: {
    color: 'colors.brand.700'
  },
  pressed: {
    color: 'colors.brand.700'
  },
  disabled: {
    color: 'colors.text.light.text3'
  }
};
