import React, { useEffect, useState } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Body, FlexBox } from 'tuxedo-v2';
import {
  useIdpList,
  useInitAuthorization,
  useIsExcelAddInInstance,
  useRedirectError,
  useSsoLogin
} from 'shared/modules/auth-module/hooks';
import { IDProvider } from 'shared/modules/auth-module/types';
import { excelPluginCommunication } from 'shared/modules/auth-module/utils/excel-addin';
import LoginViewWrapper from '../LoginViewWrapper';
import LogoContainer from '../../components/LogoContainer';
import LoginForm from '../../components/LoginFormComponent';
import ErrorPageView from '../../components/ErrorPageComponent';
import MagicLinkForm from '../../components/MagicLinkForm';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import SSOLoginForm from '../../components/SSOLoginForm';
import RedirectComponent from '../../components/RedirectComponent';
import { StyledHorizontalLine } from '../../styled';

interface Props {
  location: {
    search: string;
  };
}

function LoginFormView(props: Props): JSX.Element {
  // hooks
  const { data } = useVisitorData();
  const [
    initAuthorization,
    isInitAuthorizationLoading,
    isInitAuthorizationError
  ] = useInitAuthorization();
  const { idpList } = useIdpList();
  const {
    setRedirectError,
    resetErrorState,
    type: redirectErrorType,
    isRedirectError
  } = useRedirectError();
  const { isExcelAddInInstance } = useIsExcelAddInInstance();
  const history = useHistory();

  const { handleGoogleLogin } = useSsoLogin(
    isExcelAddInInstance ? excelPluginCommunication : undefined
  );

  // state
  const [isMagicLinkShown, setIsMagicLinkShow] = useState<boolean>(false);
  const [isForgotPasswordShown, setIsForgotPasswordShown] =
    useState<boolean>(false);
  const [ssoProvider, setSsoProvider] = useState<string | null>(null);

  useEffect(() => {
    const { error, provider } = queryString.parse(props.location.search);
    if (error && error !== '') {
      setRedirectError(String(error), String(provider));
    }
    history.replace({
      search: ''
    });
  }, []);

  useEffect(() => {
    if (data?.visitorId && redirectErrorType !== 'access') {
      initAuthorization(data?.visitorId);
    }
  }, [data]);

  useEffect(() => {
    if (!isInitAuthorizationLoading && isInitAuthorizationError) {
      window.location.href = '/login?error=access';
    }
  }, [initAuthorization, isInitAuthorizationLoading, isInitAuthorizationError]);

  const handleMagicLinkComponent = (): void => {
    resetErrorState();
    setIsMagicLinkShow(prev => !prev);
  };

  const handleForgotPasswordComponent = (): void => {
    resetErrorState();
    setIsForgotPasswordShown(prev => !prev);
  };

  const handleSSOLoginComponent = (idp: IDProvider | null): void => {
    resetErrorState();
    const { slug = '', provider = null } = idp || {};
    if (slug === 'google') {
      handleGoogleLogin((auth_url: string) => {
        window.location.href = auth_url;
      });
    } else {
      setSsoProvider(provider);
    }
  };

  if (!isRedirectError && isInitAuthorizationLoading) {
    return <RedirectComponent />;
  }

  if (isRedirectError && redirectErrorType !== undefined) {
    return <ErrorPageView type={redirectErrorType} />;
  }
  return (
    <LoginViewWrapper>
      <FlexBox flexDirection='column'>
        {!isForgotPasswordShown && !ssoProvider && (
          <>
            {!isMagicLinkShown && (
              <LoginForm
                handleTransition={handleMagicLinkComponent}
                handleForgotPasswordComponent={handleForgotPasswordComponent}
              />
            )}

            {isMagicLinkShown && (
              <MagicLinkForm handleTransition={handleMagicLinkComponent} />
            )}

            {idpList && Array.isArray(idpList) && idpList.length > 0 && (
              <>
                <FlexBox justifyContent='center' alignItems='center' mt={12}>
                  <StyledHorizontalLine />
                  <Body size='small' weight='regular' color='text2'>
                    OR SIGN IN WITH
                  </Body>
                  <StyledHorizontalLine />
                </FlexBox>
                <FlexBox mt={8} mb={18} justifyContent='center'>
                  {idpList.map((idp: IDProvider, index: number) => (
                    <LogoContainer
                      item={idp}
                      key={idp.slug}
                      handleTransition={handleSSOLoginComponent}
                      tabIndex={index + 6}
                    />
                  ))}
                </FlexBox>
              </>
            )}
          </>
        )}

        {isForgotPasswordShown && (
          <ForgotPasswordForm
            handleTransition={handleForgotPasswordComponent}
          />
        )}
        {ssoProvider && (
          <SSOLoginForm
            handleTransition={handleSSOLoginComponent}
            provider={ssoProvider}
            providerName={
              idpList?.find((idp: IDProvider) => idp.provider === ssoProvider)
                ?.name || ssoProvider
            }
          />
        )}
      </FlexBox>
    </LoginViewWrapper>
  );
}

export default LoginFormView;
