import slugify from 'shared/utils/slugify';

export function getUiAutomationIdentifierClass(
  component: string,
  label?: string
): string {
  return label
    ? `UIAutomation--auth-${component}-${slugify(label)}`
    : `UIAutomation--auth-${component}`;
}
