import { useContext } from 'react';
import { EventData } from '../types/event-data';
import EventTrackingContext from '../event-context';

/**
 * React hook to get the EventContext corresponding to component from where it is called. It will
 * contain all the event tracking params added to eventContext upto that component
 *
 * @returns EventContext
 */
export default function useEventContext(): EventData {
  const contextValue = useContext(EventTrackingContext);

  if (contextValue === undefined || contextValue === null) {
    throw new Error(
      'Could not find context to bind do. Have you wrapped component using withEventContext HOC?'
    );
  }

  return contextValue;
}
