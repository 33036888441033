// import { IntrinsicAttributes } from 'react';
import {
  space,
  flex,
  flexBasis,
  layout,
  position,
  textAlign,
  width,
  minWidth
} from 'styled-system';
import {
  SpaceProps,
  FlexProps,
  FlexBasisProps,
  TextAlignProps,
  LayoutProps,
  PositionProps,
  WidthProps,
  MinWidthProps
} from '../../../props';
import cleanComponentProps from '../../../utils/cleanComponentProps';

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    SpaceProps,
    FlexProps,
    FlexBasisProps,
    LayoutProps,
    PositionProps,
    TextAlignProps,
    WidthProps,
    MinWidthProps {}

const BoxBase = cleanComponentProps<BoxProps, HTMLDivElement>('div', [
  ...(position.propNames ? position.propNames : []),
  ...(flex.propNames ? flex.propNames : []),
  ...(flexBasis.propNames ? flexBasis.propNames : []),
  ...(space.propNames ? space.propNames : []),
  ...(layout.propNames ? layout.propNames : []),
  ...(textAlign.propNames ? textAlign.propNames : []),
  ...(width.propNames ? width.propNames : []),
  ...(minWidth.propNames ? minWidth.propNames : [])
])`
    ${space}
    ${flex}
    ${flexBasis}
    ${layout}
    ${position}
    ${textAlign}
    ${width}
    ${minWidth}
`;

BoxBase.displayName = 'Box';

export default BoxBase;
