const URL_MAPPING: Record<string, string> = {
  'excel-add-in-local': 'https://localhost:3000',
  'excel-add-in-staging': 'https://excel.stage.portfolioiq.co',
  'excel-add-in-pl': 'https://excel.dev.portfolioiq.co',
  'excel-add-in': 'https://excel.portfolioiq.co'
};
export const excelPluginCommunication = (): void => {
  const refresh_token = localStorage.getItem('refresh_token');
  const code_verifier = localStorage.getItem('code_verifier');
  const authorization_code = localStorage.getItem('authorization_code');
  const synaptic_device_id = localStorage.getItem('synaptic_device_id');
  const access_token = localStorage.getItem('access_token');
  const targetOrigin = getReferrerUrl() || URL_MAPPING['excel-add-in-local'];
  Office.context?.ui?.messageParent(
    JSON.stringify({
      refresh_token,
      code_verifier,
      authorization_code,
      synaptic_device_id,
      access_token
    }),
    {
      targetOrigin: targetOrigin
    }
  );
  disableExcelAddInInstance();
};

export const getReferrerUrl = (): string => {
  return sessionStorage.getItem('referrer_url') || '';
};

export const setExcelReferrerUrl = (url: string): void => {
  sessionStorage.setItem('referrer_url', url);
};
export const disableExcelAddInInstance = (): void => {
  sessionStorage.removeItem('referrer_url');
};

declare const window: Window & {
  backupHistoryFunctions: {
    pushState?: typeof window.history.pushState;
    replaceState?: typeof window.history.replaceState;
  };
};

/* global Office */
export const initializeExcelAddInLogin = (
  startApp: (isExcelEnabled: boolean) => void
): void => {
  const urlParams = new URLSearchParams(window.location.search);
  let referrerUrl = URL_MAPPING[urlParams.get('referrer') || ''];
  if (referrerUrl) {
    setExcelReferrerUrl(referrerUrl);
  } else {
    referrerUrl = getReferrerUrl();
  }

  if (referrerUrl) {
    window.backupHistoryFunctions = {};
    window.backupHistoryFunctions.pushState = window.history.pushState;
    window.backupHistoryFunctions.replaceState = window.history.replaceState;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js';
    script.onload = function () {
      if (window?.backupHistoryFunctions?.pushState)
        window.history.pushState = window.backupHistoryFunctions.pushState;
      if (window?.backupHistoryFunctions?.replaceState)
        window.history.replaceState =
          window.backupHistoryFunctions.replaceState;

      Office.onReady(() => startApp(true));
    };

    document.head.appendChild(script);
  } else {
    startApp(false);
  }
};
