import {
  fontSize,
  fontWeight,
  space,
  textStyle,
  textAlign
} from 'styled-system';
import {
  SpaceProps,
  TextAlignProps,
  FontSizeProps,
  FontWeightProps,
  TextStyleProps
} from '../../../props';
import cleanComponentProps from '../../../utils/cleanComponentProps';
import { LineHeightForms, TextColors } from 'theme/types';
import { themeGet } from '@styled-system/theme-get';

export interface TextProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'color'>,
    FontSizeProps,
    FontWeightProps,
    SpaceProps,
    TextStyleProps,
    TextAlignProps {
  color?: keyof TextColors;
  lineHeightForm?: LineHeightForms;
  containerClassName?: string;
}
/** text component */

const Text = cleanComponentProps<TextProps>('span', [
  'color',
  'lineHeightForm',
  ...(fontSize.propNames ? fontSize.propNames : []),
  ...(fontWeight.propNames ? fontWeight.propNames : []),
  ...(textStyle.propNames ? textStyle.propNames : []),
  ...(textAlign.propNames ? textAlign.propNames : []),
  ...(space.propNames ? space.propNames : [])
])`
  ${fontSize}
  ${fontWeight}
  ${space}
  ${textStyle}
  ${textAlign}
  ${props =>
    props.color &&
    `
    color: ${themeGet(`colors.text.${props.color}`)(props)};
  `}
  ${props =>
    props.lineHeightForm &&
    props.fontSize !== undefined &&
    themeGet(`${props.lineHeightForm}LineHeights.${props.fontSize}`)(props) &&
    `
    line-height: ${themeGet(
      `${props.lineHeightForm}LineHeights.${props.fontSize}`
    )(props)}px;
  `}

`;

Text.defaultProps = {
  lineHeightForm: 'single'
};

Text.displayName = 'Text';

export default Text;
