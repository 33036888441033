import React, { useState } from 'react';
import { IDProvider } from 'shared/modules/auth-module/types';
import { StyledLogoContainer } from '../styled';
import { getUiAutomationIdentifierClass } from '../utils';
import { Body, Box, FlexBox, Img } from 'tuxedo-v2';

interface Props {
  item: IDProvider;
  handleTransition: (arg: IDProvider | null) => void;
  tabIndex?: number;
}

function LogoContainer({
  item,
  handleTransition,
  tabIndex
}: Props): JSX.Element {
  const { name, logo } = item;
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const keyDownHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    const activeElement = document.activeElement as HTMLElement;
    const currentTabIndex = activeElement.tabIndex;
    if (event.key === 'Enter') {
      event.preventDefault();
      switch (currentTabIndex) {
        case tabIndex:
          setIsClicked(true);
          handleTransition(item);
          return;
        default:
          return;
      }
    }
  };

  return (
    <FlexBox
      flexDirection='column'
      alignItems='center'
      ml={4}
      mr={4}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setIsClicked(true);
        handleTransition(item);
      }}
      className={getUiAutomationIdentifierClass('idp-icon', name)}
    >
      {isClicked ? (
        <StyledLogoContainer tabIndex={undefined} onKeyDown={keyDownHandler}>
          <Img src={logo} />
        </StyledLogoContainer>
      ) : (
        <StyledLogoContainer tabIndex={tabIndex} onKeyDown={keyDownHandler}>
          <Img src={logo} />
        </StyledLogoContainer>
      )}
      <Box mt={2}>
        <Body weight='medium'>{name}</Body>
      </Box>
    </FlexBox>
  );
}

export default LogoContainer;
