import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

import { Window } from 'shared/types';
import { AuthDataStorageType } from 'shared/modules/auth-module/types';

declare const window: Window;

export function generateCodeVerifierAndChallenge(size: number): {
  code_verifier: string;
  code_challenge: string;
} {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
  const charsetIndexBuffer = new Uint8Array(size);
  for (let i = 0; i < size; i += 1) {
    charsetIndexBuffer[i] = (Math.random() * charset.length) | 0;
  }
  const randomChars = [];
  for (let i = 0; i < charsetIndexBuffer.byteLength; i += 1) {
    const index = charsetIndexBuffer[i] % charset.length;
    randomChars.push(charset[index]);
  }

  const code_verifier = randomChars.join('');
  let code_challenge = '';
  try {
    const hashDigest = sha256(code_verifier);
    code_challenge = Base64.stringify(hashDigest);
  } catch (error) {
    code_challenge = '';
  }

  return {
    code_verifier,
    code_challenge
  };
}

export function handleLoginComplete(): void {
  window.location.href = '/';
}

export function handleSessionExpired(): void {
  window.location.href = '/login?error=login_session';
}

export function setAuthDataToStorage(data: {
  [key in AuthDataStorageType]?: string | number;
}): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      for (const _key in data) {
        const value = String(data[_key as AuthDataStorageType]);
        window.localStorage.setItem(_key, value);
        window[_key] = value;
      }
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export function getAuthDataFromStorage(
  keys: AuthDataStorageType[]
): Promise<{ [key in AuthDataStorageType]?: string | null }> {
  return new Promise((resolve, reject): void => {
    const data: { [key in AuthDataStorageType]?: string | null } = {};
    try {
      for (const key of keys) {
        const value = window.localStorage.getItem(key);
        window[key] = value;
        data[key] = value;
      }
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
}

export function getAuthDataFromStorageSynchronous(
  keys: AuthDataStorageType[]
): { [key in AuthDataStorageType]?: string | null } {
  const data: { [key in AuthDataStorageType]?: string | null } = {};
  try {
    for (const key of keys) {
      const value = window.localStorage.getItem(key);
      window[key] = value;
      data[key] = value;
    }
  } catch (e) {
    //
  }

  return data;
}

export function setAuthDataToGlobalObject(data: {
  [key in AuthDataStorageType]?: string | number;
}): void {
  for (const _key in data) {
    const value = data[_key as AuthDataStorageType];
    window[_key] = value;
  }
}

export function getAuthDataFromGlobalObject(keys: AuthDataStorageType[]): {
  [key in AuthDataStorageType]?: string | null;
} {
  const data: { [key in AuthDataStorageType]?: string | null } = {};
  for (const key of keys) {
    const value = window[key];
    data[key] = value;
  }
  return data;
}

export function clearAuthDataFromStorage(): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      [
        'authtoken',
        'user',
        'access_token',
        'refresh_token',
        'code',
        'synaptic_device_id',
        'authorization_code',
        'code_verifier',
        'analyticsSessionId',
        'analyticsSessionIdExpire',
        'synaptic_session_exp'
      ].forEach((key: string) => {
        window.localStorage.removeItem(key);
        delete window[key];
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export function isInternetAvailable(): Promise<boolean> {
  return new Promise((resolve, reject): void => {
    try {
      const isOnline = window.navigator.onLine;
      resolve(isOnline);
    } catch (e) {
      reject(e);
    }
  });
}
