import { useInvitationAcceptApi } from 'shared/api/v5/auth/invitations/accept/get';
import {
  InvitationAcceptRequestBody,
  InvitationAcceptResponse
} from '../types';
import { getAuthDataFromStorage } from 'shared/services';

interface Return {
  isLoading: boolean;
  invitationAcceptResponse: InvitationAcceptResponse | undefined;
  handleInvitationAccept: (requestBody: InvitationAcceptRequestBody) => void;
  isError: boolean;
  resetErrorState: () => void;
}

export const useInvitationAccept = (): Return => {
  const {
    mutate,
    data: invitationAcceptResponse,
    isLoading,
    isError
  } = useInvitationAcceptApi();

  const setIsError = (arg: boolean) => {
    console.log(arg);
  };

  const resetErrorState = () => {
    setIsError(false);
  };

  const handleInvitationAccept = async (
    requestBody: InvitationAcceptRequestBody
  ) => {
    const { code } = await getAuthDataFromStorage(['code']);
    mutate({ ...requestBody, code });
  };

  return {
    isError,
    isLoading,
    invitationAcceptResponse,
    handleInvitationAccept,
    resetErrorState
  };
};
