import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface PasswordAcceptRequestBody {
  invitation_token: string | string[] | null;
  email: string | string[] | null;
  code?: string | string[] | null;
  link_type: string | string[] | null;
}

export interface PasswordAcceptResponse {
  data: {
    invitation_token: string;
  };
}

const service = 'auth/passwords/accept';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function usePasswordAcceptApi(): UseMutationResult<PasswordAcceptResponse> {
  return useMutation((requestBody: PasswordAcceptRequestBody) => {
    return api.get<PasswordAcceptResponse>(serverURL, requestBody);
  });
}
