import React from 'react';
import styled from 'styled-components';
import themeGet from 'tuxedo-v2/utils/theme-get';
import hasValue from 'shared/utils/hasValue';
import Box from '../../atoms/box';
import { ThemeMode } from 'theme-v2/provider/index';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import { FontSizeProps, FontWeightProps, SizeProps } from '../../../props';
// import { ColorProp, TextColorProps } from 'theme-v2/types';

export interface NameIconProps
  extends SizeProps,
    FontWeightProps,
    FontSizeProps {
  name?: string;
  color: string;
  type?: string;
  textColor: string;
  themeMode?: ThemeMode;
  rounded?: boolean;
  borderRadius?: number;
}

const NameIconContainer = styled(Box)<NameIconProps>`
  display: inline-flex;
  background-color: ${props =>
    themeGet(`colors.surface.${props.themeMode}.${props.color}`)(props)};
  border-radius: ${props =>
    props.rounded
      ? '50%'
      : `${themeGet(`space.${props.borderRadius ?? 2}`)(props)}px`};
  height: ${props => themeGet(`space.${props.size}`)(props)}px;
  line-height: ${props => themeGet(`singleLineHeights.${props.size}`)(props)}px;
  width: ${props => themeGet(`space.${props.size}`)(props)}px;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${props => themeGet(`colors.outline.${props.themeMode}.outline3`)};
`;

const Alphabet = styled.span<NameIconProps>`
  color: ${props =>
    themeGet(`colors.text.${props.themeMode}.${props.textColor}`)}};
  font-size: ${props => themeGet(`fontSizes.${props.fontSize}`)}px;
  text-transform: uppercase;
  font-weight: ${props => themeGet(`fontWeights.${props.fontWeight}`)};
`;

/** component to create  icon from first name of a string */
const NameIcon = (props: NameIconProps): JSX.Element => {
  const { name, type } = props;
  const alphabet = hasValue(name) ? name.charAt(0) : '?';
  let alphabetSecondary = null;
  if (type === 'multi') {
    alphabetSecondary = hasValue(name) ? name.charAt(1) : null;
  }

  return (
    <NameIconContainer {...props}>
      <Box>
        <Alphabet {...props}>{alphabet}</Alphabet>
      </Box>
      {alphabetSecondary && (
        <Box ml={1}>
          <Alphabet {...props}>{alphabetSecondary}</Alphabet>
        </Box>
      )}
    </NameIconContainer>
  );
};

NameIcon.displayName = 'NameIcon';

export default WithV2Theme(NameIcon);
