import {
  Colors,
  TextColors,
  IconColors,
  AllColors,
  OutlineColors,
  SurfaceColors
} from '../../types';

const palette: Colors = {
  'cool-grey': {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828'
  },
  brand: {
    25: '#F6FCF2',
    50: '#EFFAE8',
    100: '#E1F5D5',
    200: '#C6E8B3',
    300: '#B3DB9C',
    400: '#7FB063',
    500: '#578A3A',
    600: '#316316',
    700: '#27540D',
    800: '#1E4707',
    900: '#173D02'
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A'
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFF8EB',
    100: '#FEEBC7',
    200: '#FEDB9A',
    300: '#FECD71',
    400: '#FDC04E',
    500: '#FDAE1C',
    600: '#F59F00',
    700: '#DA8E01',
    800: '#BC7A01',
    900: '#936001'
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31'
  },
  teal: {
    25: '#F6FEFC',
    50: '#F0FDF9',
    100: '#CCFBEF',
    200: '#99F6E0',
    300: '#5FE9D0',
    400: '#2ED3B7',
    500: '#15B79E',
    600: '#0E9384',
    700: '#107569',
    800: '#125D56',
    900: '#134E48'
  },
  cyan: {
    25: '#F5FEFF',
    50: '#ECFDFF',
    100: '#CFF9FE',
    200: '#A5F0FC',
    300: '#67E3F9',
    400: '#22CCEE',
    500: '#06AED4',
    600: '#088AB2',
    700: '#0E7090',
    800: '#155B75',
    900: '#164C63'
  },
  blue: {
    25: '#F5F8FF',
    50: '#EFF4FF',
    100: '#D1E0FF',
    200: '#B2CCFF',
    300: '#84ADFF',
    400: '#528BFF',
    500: '#2970FF',
    600: '#155EEF',
    700: '#004EEB',
    800: '#0040C1',
    900: '#00359E'
  },
  purple: {
    25: '#FAFAFF',
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#BDB4FE',
    400: '#9B8AFB',
    500: '#7A5AF8',
    600: '#6938EF',
    700: '#5925DC',
    800: '#4A1FB8',
    900: '#3E1C96'
  },
  pink: {
    25: '#FEF6FB',
    50: '#FDF2FA',
    100: '#FCE7F6',
    200: '#FCCEEE',
    300: '#FAA7E0',
    400: '#F670C7',
    500: '#EE46BC',
    600: '#DD2590',
    700: '#C11574',
    800: '#9E165F',
    900: '#851651'
  },
  rose: {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31B54',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E'
  },
  orange: {
    25: '#FEFAF5',
    50: '#FEF6EE',
    100: '#FDEAD7',
    200: '#F9DBAF',
    300: '#F7B27A',
    400: '#F38744',
    500: '#EF6820',
    600: '#E04F16',
    700: '#B93815',
    800: '#932F19',
    900: '#772917'
  },
  'warm-grey': {
    25: '#FAFAFA',
    50: '#F4F4F5',
    100: '#E3E4E8',
    200: '#D0D0D7',
    300: '#9E9FAD',
    400: '#6C6D7F',
    500: '#50505D',
    600: '#3D3E48',
    700: '#25252C',
    800: '#1A1A1B',
    900: '#050506'
  },
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'transparent'
};

const textLight: TextColors = {
  text1: palette['cool-grey'][800],
  text2: palette['cool-grey'][500],
  text3: palette['cool-grey'][300],
  text4: palette.white,
  text5: palette['teal'][600],
  text6: palette['cool-grey'][900],
  brand: palette.brand[600],
  error: palette.error[600],
  warning: palette.warning[600],
  success: palette.success[600],
  on: palette.white
};

const textDark: TextColors = {
  text1: palette['warm-grey'][50],
  text2: palette['warm-grey'][400],
  text3: palette['warm-grey'][500],
  text4: palette['warm-grey'][900],
  text5: palette['teal'][400],
  text6: palette['warm-grey'][800],
  brand: palette.brand[500],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
  on: palette.white
};

const iconLight: IconColors = {
  icon1: palette['cool-grey'][800],
  icon2: palette['cool-grey'][500],
  icon3: palette['cool-grey'][300],
  icon4: palette['cyan'][400],
  icon5: palette['teal'][600],
  icon6: palette['success'][200],
  icon7: palette['blue'][200],
  icon8: palette['warning'][200],
  icon9: palette.white,
  icon10: palette['orange'][400],
  icon11: palette.warning[900],
  icon12: palette.blue[900],
  icon13: palette.success[900],
  icon14: palette.cyan[900],
  icon15: palette.purple[900],
  icon16: palette['orange'][900],
  icon17: palette.brand[400],
  icon18: palette.error[400],
  brand: palette.brand[600],
  error: palette.error[600],
  warning: palette.warning[600],
  success: palette.success[600],
  on: palette.white
};

const iconDark: IconColors = {
  icon1: palette['warm-grey'][50],
  icon2: palette['warm-grey'][400],
  icon3: palette['warm-grey'][500],
  icon4: palette['cyan'][400],
  icon5: palette['teal'][400],
  icon6: palette['success'][900],
  icon7: palette['blue'][900],
  icon8: palette['warning'][900],
  icon9: palette['warm-grey'][900],
  icon10: palette['orange'][300],
  icon11: palette.warning[400],
  icon12: palette.blue[400],
  icon13: palette.success[400],
  icon14: palette.cyan[400],
  icon15: palette.purple[400],
  icon16: palette['orange'][300],
  icon17: palette.brand[400],
  icon18: palette.error[400],
  brand: palette.brand[500],
  error: palette.error[500],
  warning: palette.warning[500],
  success: palette.success[500],
  on: palette.white
};

const outlineLight: OutlineColors = {
  outline1: palette['cool-grey'][400],
  outline2: palette['cool-grey'][300],
  outline3: palette['cool-grey'][200],
  brand: palette.brand[500],
  error: palette.error[600],
  warning: palette.warning[600],
  success: palette.success[600]
};

const outlineDark: OutlineColors = {
  outline1: palette['warm-grey'][500],
  outline2: palette['warm-grey'][600],
  outline3: palette['warm-grey'][700],
  brand: palette.brand[400],
  error: palette.error[400],
  warning: palette.warning[400],
  success: palette.success[400]
};

const surfaceLight: SurfaceColors = {
  surface1: palette['cool-grey'][100],
  surface2: palette.white,
  surface3: palette['cool-grey'][200],
  surface4: palette['cool-grey'][900]
};

const surfaceDark: SurfaceColors = {
  surface1: palette['warm-grey'][900],
  surface2: palette['warm-grey'][800],
  surface3: palette['warm-grey'][700],
  surface4: palette['warm-grey'][25]
};

const colors: AllColors = {
  ...palette,
  text: {
    light: textLight,
    dark: textDark
  },
  icon: {
    light: iconLight,
    dark: iconDark
  },
  outline: {
    light: outlineLight,
    dark: outlineDark
  },
  surface: {
    light: surfaceLight,
    dark: surfaceDark
  }
};

export default colors;

export const colorPalette = palette;
