import { useContext } from 'react';
import { mergeStates, createStateContext } from 'shared/utils/sharedStateUtils';
import {
  SignInActivityData,
  SortType,
  AppliedFiltersType,
  SignInActivityRequest,
  AlertModalType,
  IDProvider
} from '../types';

export type ModuleState = {
  email: string;
  password: string;
  mfaCode: string;
  password1: string;
  password2: string;
  errors: {
    [key: string]: { isValid: boolean; errorText: string };
  };
  touched: {
    [key: string]: boolean;
  };
  redirectError: string | null;
  redirectErrorSsoProvider: string | null;
  isOffline: boolean;
  isOtpFormShown: boolean;
  isGoogleLoginError: boolean;
  googleLoginErrorText: string;
  googleLoginErrorSubtext: string;
  // honeyBee
  honeyBee: string;
  // useBlockDevice
  isBlockDeviceComplete: boolean;
  // useUnblockDevice
  isUnblockDeviceComplete: boolean;
  // useDeactivateUser
  isDeactivateUserComplete: boolean;
  // useTerminateSession
  isTerminateSessionComplete: boolean;
  // useAuthCallbackMobile
  errorMessageAuthCallbackMobile: string;
  // useFirebaseGoogleLogin
  isFirebaseError: boolean;
  // useGenerateToken
  isLoginComplete: boolean;
  // useForgotPassword
  isErrorForgotPassword: boolean;
  isWarningForgotPassword: boolean;
  statusLabelForgotPassword: string;
  statusSubLabelForgotPassword: string;
  responseMessageForgotPassword: string;
  responseSubMessageForgotPassword: string;
  // useMagicLink
  isErrorMagicLink: boolean;
  isWarningMagicLink: boolean;
  statusLabelMagicLink: string;
  statusSubLabelMagicLink: string;
  responseMessageMagicLink: string;
  responseSubMessageMagicLink: string;
  // useGenerateOtp
  isErrorGenerateOtp: boolean;
  resendStatusLabelGenerateOtp: string;
  resendStatusSubLabelGenerateOtp: string;
  // useResetPassword
  isErrorResetPassword: boolean;
  isErrorInputPassword2ResetPassword: boolean;
  errorTextInputPassword1ResetPassword: string;
  errorTextInputPassword2ResetPassword: string;
  isPasswordResetStatusShownResetPassword: boolean;
  isInvalidUrlResetPassword: boolean;
  // useResetPasswordInvitation
  isErrorResetPasswordInvitation: boolean;
  isErrorPassword1ResetPasswordInvitation: boolean;
  resetError1ResetPasswordInvitation: string;
  resetError2ResetPasswordInvitation: string;
  isErrorPassword2ResetPasswordInvitation: boolean;
  // useSsoLogin
  isErrorSsoLogin: boolean;
  statusLabelSsoLogin: string;
  statusSubLabelSsoLogin: string;
  google_auth_url: string;
  // useValidateOtp
  isErrorValidateOtp: boolean;
  isErrorTextShownValidateOtp: boolean;
  isWarningValidateOtp: boolean;
  validateStatusLabelValidateOtp: string;
  validateStatusSubLabelValidateOtp: string;
  errorTextValidateOtp: string;
  // useSignIn
  statusLabelSignIn: string;
  statusSubLabelSignIn: string;
  isErrorSignIn: boolean;
  isWarningSignIn: boolean;
  // useSignInStripped
  isOtpFormShownSignInStripped: boolean;
  isErrorSignInStripped: boolean;
  // useUpdatePasswordFromSettings
  currentPasswordSettings: string;
  password1Settings: string;
  password2Settings: string;
  isErrorSettings: boolean;
  // useSignInActivity
  tableData: SignInActivityData[];
  lastPageNumber: number;
  // useAdminFilter
  page: number;
  sort: SortType;
  searchParam: string;
  appliedFilters: AppliedFiltersType | null;
  requestBody: SignInActivityRequest | null;
  // useAdminActions
  alertType: AlertModalType | null;
  selectedRows: SignInActivityData[];
  // useIdpList
  idpList: IDProvider[];
  // useInitAuthorization
  isAuthorizationLoading: boolean;
  redirectErrorProvider: string;
  isExcelAddInInstance: boolean;
};

export const InitialState: ModuleState = {
  email: '',
  password: '',
  mfaCode: '',
  password1: '',
  password2: '',
  errors: {
    email: { isValid: false, errorText: 'Please enter your email address' },
    password: { isValid: false, errorText: 'Please enter your password' },
    mfaCode: { isValid: false, errorText: 'Please enter security code' },
    password1: { isValid: false, errorText: 'Please enter your password' },
    password2: { isValid: false, errorText: 'Please confirm your password' }
  },
  touched: {
    email: false,
    password: false,
    mfaCode: false,
    password1: false,
    password2: false
  },
  redirectError: null,
  redirectErrorSsoProvider: null,
  isOffline: false,
  isOtpFormShown: false,
  isGoogleLoginError: false,
  googleLoginErrorText: '',
  googleLoginErrorSubtext: '',
  honeyBee: '',
  isBlockDeviceComplete: false,
  isUnblockDeviceComplete: false,
  isDeactivateUserComplete: false,
  isTerminateSessionComplete: false,
  errorMessageAuthCallbackMobile: '',
  isFirebaseError: false,
  isLoginComplete: false,
  isErrorForgotPassword: false,
  isWarningForgotPassword: false,
  statusLabelForgotPassword: '',
  statusSubLabelForgotPassword: '',
  responseMessageForgotPassword: '',
  responseSubMessageForgotPassword: '',
  isErrorMagicLink: false,
  isWarningMagicLink: false,
  statusLabelMagicLink: '',
  statusSubLabelMagicLink: '',
  responseMessageMagicLink: '',
  responseSubMessageMagicLink: '',
  isErrorGenerateOtp: false,
  resendStatusLabelGenerateOtp: '',
  resendStatusSubLabelGenerateOtp: '',
  isErrorResetPassword: false,
  isErrorInputPassword2ResetPassword: false,
  errorTextInputPassword1ResetPassword: '',
  errorTextInputPassword2ResetPassword: '',
  isPasswordResetStatusShownResetPassword: false,
  isInvalidUrlResetPassword: false,
  isErrorResetPasswordInvitation: false,
  isErrorPassword1ResetPasswordInvitation: false,
  resetError1ResetPasswordInvitation: '',
  resetError2ResetPasswordInvitation: '',
  isErrorPassword2ResetPasswordInvitation: false,
  isErrorSsoLogin: false,
  statusLabelSsoLogin: '',
  statusSubLabelSsoLogin: '',
  isErrorValidateOtp: false,
  isErrorTextShownValidateOtp: false,
  isWarningValidateOtp: false,
  validateStatusLabelValidateOtp: '',
  validateStatusSubLabelValidateOtp: '',
  errorTextValidateOtp: '',
  statusLabelSignIn: '',
  statusSubLabelSignIn: '',
  isErrorSignIn: false,
  isWarningSignIn: false,
  isOtpFormShownSignInStripped: false,
  isErrorSignInStripped: false,
  currentPasswordSettings: '',
  password1Settings: '',
  password2Settings: '',
  isErrorSettings: false,
  tableData: [],
  lastPageNumber: 0,
  page: 1,
  sort: { column: 'sign_in_date', direction: 'desc' },
  searchParam: '',
  appliedFilters: null,
  requestBody: null,
  alertType: null,
  selectedRows: [],
  idpList: [],
  google_auth_url: '',
  isAuthorizationLoading: true,
  redirectErrorProvider: '',
  isExcelAddInInstance: false
};

export const ModuleStateContext = createStateContext<ModuleState>(InitialState);

export function useModuleState(): [
  ModuleState,
  (newState: Partial<ModuleState>) => void
] {
  const { state, setState } = useContext(ModuleStateContext);

  const setModuleState = (newState: Partial<ModuleState>): void => {
    setState(mergeStates<ModuleState>(state, newState));
  };

  return [state, setModuleState];
}
