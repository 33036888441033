import React from 'react';
import { Body, Box, FlexBox, FontIcon } from 'tuxedo-v2';
import { useIconConfigV2 } from 'shared/modules/auth-module/hooks';
import { StatusCardType } from 'shared/modules/auth-module/types';
import { Spacings } from 'theme-v2/types';

import {
  StyledStatusCard,
  StyledIconContainer,
  StyledAnchorTag
} from '../styled';

interface Props {
  type?: StatusCardType;
  label: string;
  subLabel: string;
  mt?: keyof Spacings;
  mb?: keyof Spacings;
  addSupport?: boolean;
}

function StatusCard({
  type = 'success',
  label,
  subLabel,
  mt = 0,
  mb = 0,
  addSupport = false
}: Props): JSX.Element {
  const [iconType, iconColor] = useIconConfigV2(type);

  return (
    <StyledStatusCard type={type} mt={mt} mb={mb}>
      <FlexBox flexDirection='column' gap={1}>
        <FlexBox gap={2} alignItems='center'>
          <StyledIconContainer>
            <FontIcon type={iconType} size={2} color={iconColor} />
          </StyledIconContainer>
          <Body weight='medium'>{label}</Body>
        </FlexBox>
        <Box ml={6}>
          {addSupport ? (
            <Body size='small' weight='regular' color='text2'>
              {subLabel} or{' '}
              <StyledAnchorTag href='mailto:support@synaptic.com'>
                contact support
              </StyledAnchorTag>
              .
            </Body>
          ) : (
            <Body size='small' weight='regular' color='text2'>
              {subLabel}
            </Body>
          )}
        </Box>
      </FlexBox>
    </StyledStatusCard>
  );
}

export default StatusCard;
