import React from 'react';
import { FlexBox, Body, Img } from 'tuxedo-v2';
import Image404 from 'theme/assets/images/auth-module/error-404.png';
import { imageConfig } from 'config';
import { HomeNavigationLink } from 'components/ErrorPageComponent';

const ErrorPage = (): JSX.Element => {
  return (
    <FlexBox
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      _height='100vh'
    >
      {imageConfig.logo}
      <FlexBox flexDirection='column' alignItems='center'>
        <FlexBox my={8}>
          <Img src={Image404} />
        </FlexBox>
        <Body weight='medium' size='large'>
          A little lost? Let's try again.
        </Body>
        <HomeNavigationLink />
      </FlexBox>
    </FlexBox>
  );
};

export default ErrorPage;
