import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { css, keyframes } from 'styled-components';
import { space } from 'styled-system';
import { SpaceProps } from '../../../props';
import { IconSizeKeys, FontIconColors } from 'theme/types';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

interface StyledIProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'color'>,
    SpaceProps {
  color?: keyof FontIconColors;
  size?: IconSizeKeys;
  spin?: boolean;
}

const StyledI = styled.i<StyledIProps>`
  ${space}
  display: inline-block;
  ${props => {
    return css`
      font-size: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      height: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      width: ${themeGet(`iconSizes.${props.size}`)(props)}px;
      ${props.color
        ? `color: ${themeGet(`colors.fontIcon.${props.color}`)(props)};`
        : ''}
      ::before {
        ${props.spin
          ? css`
              display: inline-block;
              animation: 1.5s ${rotate} infinite;
            `
          : ''}
      }
    `;
  }};
`;

export type FontIconProps = StyledIProps & {
  type: string;
};

const FontIcon = ({
  type,
  className = '',
  size = 0,
  spin = false,
  ...rest
}: FontIconProps): JSX.Element => (
  <StyledI
    className={`${type} ${className}`}
    size={size}
    spin={spin}
    {...rest}
  />
);

FontIcon.displayName = 'FontIcon';

export default FontIcon;
