import { createGlobalStyle } from 'styled-components';
import { THEME_V2_CLASS } from 'theme-v2/constants';
import '../assets/font-icons/style.css';

const GlobalFontStyle = createGlobalStyle`
    div.${THEME_V2_CLASS} {
        [class^="icon-v2-"], [class*=" icon-v2-"] {
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'synaptic_icon' !important;
        }
    }
`;

export default GlobalFontStyle;
