import { IconColors } from 'theme-v2/types';
import { StatusCardType } from '../types';

export function useIconConfigV2(
  type: StatusCardType
): [string, keyof IconColors] {
  if (type === 'success') {
    return ['icon-checkfilled', 'success'];
  } else if (type === 'warning') {
    return ['icon-exclamationtrianglefilled1', 'warning'];
  } else if (type === 'error') {
    return ['icon-crossfilled', 'error'];
  }
  return ['icon-checkfilled', 'success'];
}
