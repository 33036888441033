import { themeGet as baseThemeGet } from '@styled-system/theme-get';
import { Theme } from 'theme/types';
import { Theme as ThemeV2 } from 'theme-v2/types';
import { ThemeProps } from 'styled-components';

type ThemeNested = Theme & { v2: ThemeV2 };

export default function themeGet(
  value: string,
  fallback?: string
): (props: ThemeProps<ThemeNested | ThemeV2 | undefined>) => string {
  return (props: ThemeProps<ThemeNested | ThemeV2>) => {
    const { theme } = props;

    return baseThemeGet(
      value,
      fallback
    )({ theme: 'v2' in theme ? theme.v2 : theme });
  };
}
