import React from 'react';
import { FontIcon, Tooltip } from 'tuxedo';
import { Body, FlexBox, Box } from 'tuxedo-v2';

interface Props {
  label: string;
  isActive?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

function PasswordCheckInfo({
  label,
  isActive = false,
  showTooltip = false,
  tooltipText = ''
}: Props): JSX.Element {
  return (
    <FlexBox alignItems='center' mt={showTooltip ? 1 : 2}>
      <FontIcon
        type='icon-Verified'
        size={1}
        color={isActive ? 'green' : 'disabled'}
        mx={1}
      />

      <Body size='small' weight='regular' color='text2'>
        {label}
      </Body>
      {showTooltip && (
        <Tooltip text={tooltipText} placement='bottom'>
          <Box ml={1}>
            <FontIcon type='icon-Info1' size={1} color='light' />
          </Box>
        </Tooltip>
      )}
    </FlexBox>
  );
}

export default PasswordCheckInfo;
