import React from 'react';

import { WithV2Theme } from 'theme-v2/provider/withV2Theme';

import FontIcon from '../../atoms/font-icon';
import Text from '../../atoms/text';

import { flatButtonSizeProps } from './constants';
import { FlatButtonBase } from './styled';
import { FlatButtonBaseStyleProps, FlatButtonProps } from './types';

const FlatButton = ({
  children,
  isDisabled = false,
  leftIcon,
  onClick,
  rightIcon,
  size = 'medium',
  text,
  tabIndex,
  className,
  inline = false
}: FlatButtonProps): JSX.Element => {
  const iconSize = flatButtonSizeProps[size].iconSize;
  const buttonBaseStyleProps: FlatButtonBaseStyleProps = {
    isDisabled: isDisabled,
    size,
    inline
  };

  return (
    <FlatButtonBase
      {...buttonBaseStyleProps}
      onClick={isDisabled ? undefined : onClick}
      tabIndex={tabIndex}
      className={className}
    >
      {leftIcon && <FontIcon type={leftIcon} size={iconSize}></FontIcon>}
      {(text || children) && (
        <Text fontWeight='medium'>{text || children}</Text>
      )}
      {rightIcon && <FontIcon type={rightIcon} size={iconSize}></FontIcon>}
    </FlatButtonBase>
  );
};

export default WithV2Theme(FlatButton);
