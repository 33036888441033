import React from 'react';
import BaseInput from './base';
import { InputProps, InputRef } from './types';
import NumberInputControl from './number-control';
import { sizeProps } from './constant';
import RcInput from 'rc-input';

const InputText = React.forwardRef<InputRef, Omit<InputProps, 'type'>>(
  (props, ref) => <BaseInput {...props} ref={ref} type='text' />
);
const InputTextArea = React.forwardRef<InputRef, Omit<InputProps, 'type'>>(
  (props, ref) => <BaseInput {...props} ref={ref} type='textarea' />
);
const InputSearch = React.forwardRef<
  InputRef,
  Omit<
    InputProps,
    'type' | 'label' | 'helperText' | 'characterLimit' | 'leftIcon'
  > & { hideSearchIcon?: boolean }
>((props, ref) => {
  const searchIcon: InputProps['leftIcon'] = {
    type: 'icon-searchlined',
    color: props.disabled ? 'icon3' : 'icon2'
  };

  return (
    <BaseInput
      type='search'
      ref={ref}
      {...props}
      // todo: remove this after fixing the issue with hideSearchIcon
      leftIcon={props.hideSearchIcon ? undefined : searchIcon}
    />
  );
});

const InputPassword = React.forwardRef<
  InputRef,
  Omit<InputProps, 'type' | 'characterLimit'>
>((props, ref) => <BaseInput {...props} ref={ref} type='password' />);

const InputNumber = React.forwardRef<
  InputRef,
  Omit<InputProps, 'type' | 'characterLimit'> & {
    showNumberControls?: boolean;
  }
>((props, ref) => {
  const {
    disabled,
    onChange,
    value,
    size,
    showNumberControls = true,
    suffix
  } = props;
  const handleNumberControls = (type: 'up' | 'down') => {
    if (!disabled) {
      const inputValue = value || 0;
      onChange &&
        onChange({
          target: { value: type === 'up' ? +inputValue + 1 : +inputValue - 1 }
        } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const inputSuffix = showNumberControls ? (
    <NumberInputControl inputSize={size} handleClick={handleNumberControls} />
  ) : (
    suffix
  );

  return <BaseInput ref={ref} {...props} type='number' suffix={inputSuffix} />;
});

const InputSelectBox = React.forwardRef<
  InputRef,
  Omit<InputProps, 'type' | 'rightIcon'> & { open?: boolean }
>((props, ref) => {
  const { size, open, disabled } = props;
  return (
    <BaseInput
      {...props}
      ref={ref}
      type='select'
      rightIcon={{
        type: open ? 'icon-chevronuplined' : 'icon-chevrondownlined',
        color: disabled ? 'icon3' : open ? 'icon2' : 'icon1',
        size: sizeProps[size].iconSize
      }}
    />
  );
});

export default {
  Text: InputText,
  Password: InputPassword,
  Search: InputSearch,
  Number: InputNumber,
  Select: InputSelectBox,
  TextArea: InputTextArea,
  Base: BaseInput,
  BaseInput: RcInput
  // BaseInput can be used to create custom input components
  // Some extra functionalities in other input components were conflicting so using this
};
