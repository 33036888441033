import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface AuthorizeSessionRequestBody {
  client_id: string;
  code_challenge: string;
  device_info: {
    fingerprint: string;
  };
}

export interface AuthorizeSessionResponse {
  data: {
    code: string;
    synaptic_device_id: string;
    synaptic_session_exp: number;
  };
}

const service = 'auth/sessions/authorize';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useAuthorizeSessionApi(): UseMutationResult {
  return useMutation((requestBody: AuthorizeSessionRequestBody) => {
    return api.post<AuthorizeSessionResponse>(serverURL, requestBody);
  });
}
