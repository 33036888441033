import React, { useContext } from 'react';
import defaultTheme from '..';
import { Theme } from '../types';

export type ThemeMode = 'light' | 'dark';

export const InitialState: ThemeMode = 'light';

export type ThemeContextType = {
  theme: Theme;
  setTheme: (newState: Theme) => void;
};

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: defaultTheme,
  setTheme: () => {
    return;
  }
});

export function useThemeMode(): [
  ThemeMode,
  (newState: Partial<ThemeMode>) => void
] {
  const { theme, setTheme } = useContext(ThemeContext);

  const setThemeMode = (newState: ThemeMode): void => {
    setTheme({
      ...theme,
      themeMode: newState
    });
  };

  return [theme.themeMode, setThemeMode];
}

export function useTheme(): Theme {
  const { theme } = useContext(ThemeContext);
  return theme;
}
