import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface ApiResponseBody {
  message: string;
  sub_message: string;
  additional_messages: {
    attempts: string;
    subtext: string;
    error_text: string;
  };
  is_error: boolean;
}

export interface AuthMetaData {
  code: string;
  synaptic_session_exp: number;
}

export interface GenerateOtpResponse {
  data: {
    incorrect_mfa: ApiResponseBody;
    resend_mfa: ApiResponseBody;
  };
  meta: AuthMetaData;
  login_session_expired?: boolean;
}

const service = 'auth/mfa/generate';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useGenerateOtpApi(): UseMutationResult<GenerateOtpResponse> {
  return useMutation((requestBody: { email: string; code: string }) => {
    return api.get<GenerateOtpResponse>(serverURL, requestBody);
  });
}
