import getQueryString from 'shared/utils/getQueryString';
import { ApiPromise, ApiResponse } from 'shared/types';
import { getHeaders } from '../utils/headers';
import {
  getErrorResponse,
  isErrorResponse,
  handleResponse
} from '../utils/helpers';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_PATCH = 'PATCH';
const METHOD_DELETE = 'DELETE';

// Interceptors allow us to modify the response before it is returned to the caller
type Interceptor = (
  response: ApiResponse,
  options?: Json,
  componentId?: string
) => ApiResponse;
type RequestOptions = {
  url: string;
  options: Json;
};

// Middlewares allow us to modify the request url and options/args before it is sent to the server
type Middleware = (arg: RequestOptions) => Promise<RequestOptions>;

const interceptors: (Interceptor | null)[] = [];
const middlewares: Middleware[] = [];

// sets the fetch request options init
let fetchOptions: Partial<RequestInit> = {};

function getSegmentHeaders(): Record<string, string> {
  const hostName = window.location.hostname;

  // We only support segment on staging and production
  if (hostName !== 'app.synaptic.com' && hostName !== 'app.nvst-staging.com') {
    return {};
  }

  const encodedWriteKey =
    window.location.hostname === 'app.synaptic.com'
      ? 'Qnc2bGRPZFM4U2VrajhQS3V6N1Ayank1U0ZSUUt1T0I='
      : 'ZW8xWFdzb2g4bDJxVzRPMU0ybnFjYW1wR1l0eUpEVmw=';

  return {
    // Convert given write key to base64 using online utility
    authorization: `Basic ${encodedWriteKey}`
  };
}

function isSegmentURL(url: string) {
  return url.indexOf('segment.io') !== -1 || url.indexOf('events-api') !== -1;
}

const api = {
  setFetchOptions(options: Partial<RequestInit>): void {
    fetchOptions = options;
  },

  registerInterceptor(interceptor: Interceptor): number {
    interceptors.push(interceptor);
    return interceptors.length - 1;
  },

  deRegisterInterceptor(id: number): void {
    if (interceptors.length > id) {
      interceptors[id] = null;
    }
  },

  registerMiddleware(middleware: Middleware): void {
    middlewares.push(middleware);
  },

  get<T>(
    url: string,
    options: Json = {},
    abortSignal?: AbortSignal,
    componentId: string = '',
    headers: Record<string, string> = {}
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      // Execute middleware promises one by one to
      // get final url and options
      const middlewarePromise = async () => {
        let requestOptions = { url, options };
        for (const middleware of middlewares) {
          requestOptions = await middleware(requestOptions);
        }

        return requestOptions;
      };

      middlewarePromise().then((reqOptions: RequestOptions) => {
        fetch(
          `${reqOptions.url}${
            reqOptions.url.indexOf('?') === -1 ? '?' : '&'
          }${getQueryString(reqOptions.options)}`,
          {
            signal,
            method: METHOD_GET,
            ...fetchOptions,
            headers: getHeaders(
              isSegmentURL(reqOptions.url)
                ? { ...getSegmentHeaders(), ...headers }
                : { ...(headers || {}) }
            )
          }
        )
          .then((response: Response): Promise<ApiResponse> => {
            return handleResponse(response);
          })
          .then((streamedResponse: ApiResponse) => {
            let response = streamedResponse;

            interceptors.forEach(interceptor => {
              if (interceptor) {
                response = interceptor(response, options, componentId);
              }
            });

            if (isErrorResponse(response)) {
              reject(getErrorResponse(response));
            }

            resolve(response.data as T);
          })
          .catch((error: Error) => {
            if (!error.message.match(/aborted/)) {
              // Ignore abort error
              reject(error);
            }
          });
      });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  },

  put<T>(
    url: string,
    options: Json = {},
    abortSignal?: AbortSignal,
    componentId: string = '',
    headers: Record<string, string> = {}
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      const middlewarePromise = async () => {
        let requestOptions = { url, options };
        for (const middleware of middlewares) {
          requestOptions = await middleware(requestOptions);
        }

        return requestOptions;
      };

      middlewarePromise().then((reqOptions: RequestOptions) => {
        fetch(reqOptions.url, {
          signal,
          method: METHOD_PUT,
          ...fetchOptions,
          headers: getHeaders(
            isSegmentURL(reqOptions.url)
              ? getSegmentHeaders()
              : { ...(headers || {}) }
          ),
          body: JSON.stringify(reqOptions.options)
        })
          .then((response: Response): Promise<ApiResponse> => {
            return handleResponse(response);
          })
          .then((streamedResponse: ApiResponse) => {
            let response = streamedResponse;
            interceptors.forEach(interceptor => {
              if (interceptor) {
                response = interceptor(response, options, componentId);
              }
            });

            if (isErrorResponse(response)) {
              reject(getErrorResponse(response));
            }

            resolve(response.data as T);
          })
          .catch((error: Error) => {
            if (!error.message.match(/aborted/)) {
              // Ignore abort error
              reject(error);
            }
          });
      });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  },

  patch<T>(
    url: string,
    options: Json = {},
    abortSignal?: AbortSignal,
    componentId: string = ''
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      const middlewarePromise = async () => {
        let requestOptions = { url, options };
        for (const middleware of middlewares) {
          requestOptions = await middleware(requestOptions);
        }

        return requestOptions;
      };

      middlewarePromise().then((reqOptions: RequestOptions) => {
        fetch(reqOptions.url, {
          signal,
          method: METHOD_PATCH,
          ...fetchOptions,
          headers: getHeaders(
            isSegmentURL(reqOptions.url) ? getSegmentHeaders() : {}
          ),
          body: JSON.stringify(reqOptions.options)
        })
          .then((response: Response): Promise<ApiResponse> => {
            return handleResponse(response);
          })
          .then((streamedResponse: ApiResponse) => {
            let response = streamedResponse;
            interceptors.forEach(interceptor => {
              if (interceptor) {
                response = interceptor(response, options, componentId);
              }
            });

            if (isErrorResponse(response)) {
              reject(getErrorResponse(response));
            }

            resolve(response.data as T);
          })
          .catch((error: Error) => {
            if (!error.message.match(/aborted/)) {
              // Ignore abort error
              reject(error);
            }
          });
      });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  },

  post<T>(
    url: string,
    options: Json = {},
    abortSignal?: AbortSignal,
    componentId: string = '',
    headers: Record<string, string> = {}
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      const middlewarePromise = async () => {
        let requestOptions = { url, options };
        for (const middleware of middlewares) {
          requestOptions = await middleware(requestOptions);
        }

        return requestOptions;
      };

      middlewarePromise().then((reqOptions: RequestOptions) => {
        fetch(reqOptions.url, {
          signal,
          method: METHOD_POST,
          ...fetchOptions,
          headers: getHeaders(
            isSegmentURL(reqOptions.url)
              ? getSegmentHeaders()
              : { ...(headers || {}) }
          ),
          body: JSON.stringify(reqOptions.options)
        })
          .then((response: Response): Promise<ApiResponse> => {
            return handleResponse(response);
          })
          .then((streamedResponse: ApiResponse) => {
            let response = streamedResponse;

            interceptors.forEach(interceptor => {
              if (interceptor) {
                response = interceptor(response, options, componentId);
              }
            });

            if (isErrorResponse(response)) {
              reject(getErrorResponse(response));
            }

            resolve(response.data as T);
          })
          .catch((error: Error) => {
            if (!error.message.match(/aborted/)) {
              // Ignore abort error
              reject(error);
            }
          });
      });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  },

  postMultiPart<T>(
    url: string,
    formData: FormData,
    abortSignal?: AbortSignal,
    componentId: string = ''
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      const headers = getHeaders(isSegmentURL(url) ? getSegmentHeaders() : {});
      delete headers['Content-Type'];

      fetch(url, {
        signal,
        method: METHOD_POST,
        ...fetchOptions,
        headers,
        body: formData
      })
        .then((response: Response): Promise<ApiResponse> => {
          return handleResponse(response);
        })
        .then((streamedResponse: ApiResponse) => {
          let response = streamedResponse;
          interceptors.forEach(interceptor => {
            if (interceptor) {
              response = interceptor(response, formData, componentId);
            }
          });

          if (isErrorResponse(response)) {
            reject(getErrorResponse(response));
          }

          resolve(response.data as T);
        })
        .catch((error: Error) => {
          if (!error.message.match(/aborted/)) {
            // Ignore abort error
            reject(error);
          }
        });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  },

  delete<T>(
    url: string,
    options: Json = {},
    abortSignal?: AbortSignal,
    componentId: string = ''
  ): ApiPromise<T> {
    const controller = new AbortController();
    const signal = abortSignal || controller.signal;
    const response = new Promise<T>((resolve, reject) => {
      const middlewarePromise = async () => {
        let requestOptions = { url, options };
        for (const middleware of middlewares) {
          requestOptions = await middleware(requestOptions);
        }

        return requestOptions;
      };

      middlewarePromise().then((reqOptions: RequestOptions) => {
        fetch(reqOptions.url, {
          signal,
          method: METHOD_DELETE,
          ...fetchOptions,
          headers: getHeaders(
            isSegmentURL(reqOptions.url) ? getSegmentHeaders() : {}
          ),
          body: JSON.stringify(reqOptions.options)
        })
          .then((response: Response): Promise<ApiResponse> => {
            return handleResponse(response);
          })
          .then((streamedResponse: ApiResponse) => {
            let response = streamedResponse;
            interceptors.forEach(interceptor => {
              if (interceptor) {
                response = interceptor(response, options, componentId);
              }
            });

            if (isErrorResponse(response)) {
              reject(getErrorResponse(response));
            }

            resolve(response.data as T);
          })
          .catch((error: Error) => {
            if (!error.message.match(/aborted/)) {
              // Ignore abort error
              reject(error);
            }
          });
      });
    }) as ApiPromise<T>;

    response.abort = () => {
      controller.abort();
    };

    return response;
  }
};

export default api;
