import React, { useRef, useEffect } from 'react';
import { Body, ButtonV2, Heading, FlexBox, FlatButton } from 'tuxedo-v2';
import {
  useSignIn,
  useHoneypot,
  useRedirectError,
  useIsExcelAddInInstance
} from 'shared/modules/auth-module/hooks';
import TextInput from 'shared/components/password-checker/TextInput';
import { InputRef } from 'tuxedo-v2/components/components/input/types';

import StatusCard from './StatusCard';
import OtpForm from './OtpForm';
import HoneybeeTextInput from './HoneybeeTextInput';
import { StyledMagicLinkContainer } from '../styled';
import { getUiAutomationIdentifierClass } from '../utils';
import { APP_NAME } from '../constants';
import { excelPluginCommunication } from 'shared/modules/auth-module/utils/excel-addin';

interface Props {
  handleTransition: () => void;
  handleForgotPasswordComponent: () => void;
}

function LoginFormComponent({
  handleTransition,
  handleForgotPasswordComponent
}: Props): JSX.Element {
  const emailInput: React.RefObject<InputRef> = useRef(null);
  const { isExcelAddInInstance } = useIsExcelAddInInstance();

  const {
    email,
    setEmail,
    errorTextEmail,
    password,
    setPassword,
    errorTextPassword,
    isEmailInputError,
    isPasswordInputError,
    isLoading,
    signInResponse,
    isOtpFormShown,
    isError,
    isWarning,
    handleSignIn,
    statusLabel,
    statusSubLabel
  } = useSignIn(isExcelAddInInstance ? excelPluginCommunication : undefined);
  const [honeybee, setHoneyBee] = useHoneypot();

  const { isRedirectError, statusConfig, resetErrorState, addSupport } =
    useRedirectError();

  const signInHandler = () => {
    if (!honeybee) {
      resetErrorState();
      handleSignIn();
    }
  };

  useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current?.focusInput();
    }
  }, []);

  return (
    <FlexBox flexDirection='column'>
      {isOtpFormShown ? (
        <OtpForm signInResponse={signInResponse} />
      ) : (
        <>
          <FlexBox justifyContent='center' mb={12}>
            <Heading>Sign in to {APP_NAME}</Heading>
          </FlexBox>
          {isRedirectError && statusConfig && (
            <StatusCard
              type={statusConfig?.type}
              label={statusConfig?.label}
              subLabel={statusConfig?.subLabel}
              mb={6}
              addSupport={addSupport}
            />
          )}
          {isWarning && (
            <StatusCard
              type={isError ? 'error' : 'warning'}
              label={statusLabel}
              subLabel={statusSubLabel}
              mb={isError ? 0 : 6}
              addSupport={isError}
            />
          )}
          {!isError && (
            <FlexBox flexDirection='column'>
              <TextInput
                value={email}
                onChange={setEmail}
                inputHeader='EMAIL'
                placeholder='Enter Email'
                mt={0}
                type='email'
                isError={isEmailInputError}
                errorText={errorTextEmail}
                ref={emailInput}
                tabIndex={1}
                onEnter={signInHandler}
              />
              <TextInput
                value={password}
                onChange={setPassword}
                inputHeader='PASSWORD'
                placeholder='Enter Password'
                password
                type='password'
                isError={isPasswordInputError}
                errorText={errorTextPassword}
                tabIndex={2}
                onEnter={signInHandler}
              />
              <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
              <FlexBox mt={3}>
                <FlatButton
                  onClick={handleForgotPasswordComponent}
                  className={getUiAutomationIdentifierClass(
                    'email-password-login',
                    'Go to forgot password'
                  )}
                  tabIndex={4}
                  text='Forgot Password'
                />
              </FlexBox>
              <FlexBox mt={6} flexDirection='column'>
                <ButtonV2
                  onClick={signInHandler}
                  text='Continue'
                  isLoading={isLoading}
                  tabIndex={3}
                />
              </FlexBox>
            </FlexBox>
          )}
        </>
      )}
      {!isExcelAddInInstance && (
        <StyledMagicLinkContainer>
          <Body weight='regular' color='text2'>
            Alternatively, you can sign in using{' '}
            <FlatButton
              onClick={handleTransition}
              className={getUiAutomationIdentifierClass(
                'email-password-login',
                'Go to magic link'
              )}
              text='Magic Link'
              tabIndex={5}
              inline
            />
          </Body>
        </StyledMagicLinkContainer>
      )}
    </FlexBox>
  );
}

export default LoginFormComponent;
