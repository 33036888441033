import React from 'react';
import { useThemeMode } from 'theme-v2/provider/index';
import { TextAreaProps } from './types';
import classNames from 'classnames';
import { BoxProps } from '../../atoms/box';
import { TextAreaWrapper } from './styled';

export const TextArea = React.forwardRef(
  (
    props: TextAreaProps & Pick<BoxProps, 'onFocus' | 'onBlur'>,
    ref: React.MutableRefObject<HTMLTextAreaElement>
  ) => {
    const {
      className,
      style,
      value,
      placeholder,
      disabled,
      onChange,
      maxLinesToShow,
      hasError,
      inputSize
    } = props;
    const [themeMode] = useThemeMode();
    return (
      <TextAreaWrapper
        style={style}
        value={value}
        inputSize={inputSize}
        className={classNames(className)}
        placeholder={placeholder}
        onChange={onChange}
        ref={ref}
        hasError={hasError}
        disabled={disabled}
        rows={maxLinesToShow}
        themeMode={themeMode}
      />
    );
  }
);
