import {
  generateCodeVerifierAndChallenge,
  setAuthDataToStorage,
  getAuthDataFromStorage
} from 'shared/services';
import { useSsoLogin } from './useSsoLogin';

import { useAuthorizeSessionApi } from 'shared/api/v5/auth/sessions/authorize/post';
import { CLIENT_ID, CODE_VERIFIER_LENGTH } from '../constants';
import { AuthorizeSessionResponse } from '../types';
import { useModuleState } from '../module-state';

export const useInitAuthorization = (): [
  (visitor_id: string) => Promise<void>,
  boolean,
  boolean
] => {
  const [{ isAuthorizationLoading: isLoading }, setModuleState] =
    useModuleState();
  const { mutate: authorizeSessionApi, isError } = useAuthorizeSessionApi();
  const { handleGoogleLogin, google_auth_url } = useSsoLogin();

  const initAuthorization = async (visitor_id: string) => {
    let delta = null;
    const {
      synaptic_device_id,
      code_verifier,
      code,
      synaptic_session_exp: expiration
    } = await getAuthDataFromStorage([
      'synaptic_device_id',
      'code_verifier',
      'code',
      'synaptic_session_exp'
    ]);
    if (expiration) {
      const currentTimestamp = Date.now() / 1000;
      delta = Number(expiration) - currentTimestamp;
    }
    if (
      !synaptic_device_id ||
      !code_verifier ||
      !code ||
      !expiration ||
      !delta ||
      (delta && delta <= 60)
    ) {
      const { code_verifier, code_challenge } =
        generateCodeVerifierAndChallenge(CODE_VERIFIER_LENGTH);

      const requestBody = {
        client_id: CLIENT_ID,
        code_challenge,
        device_info: {
          fingerprint: visitor_id || ''
        }
      };
      authorizeSessionApi(requestBody, {
        onSuccess: (response: AuthorizeSessionResponse) => {
          const { data: sessionResponse } = response;
          if (sessionResponse) {
            setAuthDataToStorage({
              code_verifier,
              code: sessionResponse?.code,
              synaptic_device_id: sessionResponse?.synaptic_device_id,
              synaptic_session_exp: sessionResponse?.synaptic_session_exp
            }).then(() => handleGoogleLogin());
            setModuleState({ isAuthorizationLoading: false });
          }
        },
        onError: () => {
          setModuleState({ isAuthorizationLoading: false });
        }
      });
    } else {
      if (!google_auth_url) {
        handleGoogleLogin();
      }
      setModuleState({ isAuthorizationLoading: false });
    }
  };

  return [initAuthorization, isLoading, isError];
};
