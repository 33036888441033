import React from 'react';
import { css } from 'styled-components';
import themeGet from 'tuxedo-v2/utils/theme-get';
import { width, height, size } from 'styled-system';
import blink from '../../../keyframes/blink';

// Tuxedo imports
import { FlexBoxProps } from 'tuxedo-v2/types';
import { NameIconProps } from '../name-icon';
import NameIcon from '../name-icon';
import cleanComponentProps from '../../../utils/cleanComponentProps';
import FlexBox from '../../atoms/flexbox';
import { ThemeMode } from 'theme-v2/provider/index';

interface ImgProps extends FlexBoxProps {
  src?: string;
  altIcon?: Omit<NameIconProps, 'themeMode'>;
  showLoading?: boolean;
  loading?: boolean;
  themeMode?: ThemeMode;
  placeHolder?: JSX.Element;
  rounded?: boolean;
}

interface ImgState {
  loading?: boolean;
  isError?: boolean;
}

const blinkAnimation = css`
  animation: ${blink} 2s ease-in-out 0s infinite;
`;

const StyledImg = cleanComponentProps<ImgProps>('img', [
  ...(width.propNames || []),
  ...(height.propNames || []),
  ...(size.propNames || []),
  'loading'
])`
  ${size}
  ${width}
  ${height}
  max-width: 100%;
  ${props =>
    props.loading &&
    css`
      background-color: ${themeGet(`colors.cool-grey.500`)};
      ${blinkAnimation};
    `}
`;

/** Image component to show a name icon on image error */
export default class Img extends React.Component<ImgProps, ImgState> {
  constructor(props: ImgProps) {
    super(props);

    this.state = {
      isError: false,
      loading: true
    };

    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
  }

  onError(): void {
    if (this.state.isError === false) {
      // Prevent infinite loop
      this.setState({
        isError: true,
        loading: false
      });
    }
  }

  onLoad(): void {
    this.setState({ loading: false });
  }

  render(): JSX.Element {
    // We can configure the img component to show a name icon on image error
    // by passing alt as an object
    const { altIcon, showLoading, src, placeHolder, ...restProps } = this.props;
    const { isError, loading } = this.state;
    const conditionalProps: Record<string, () => void> = {};
    if (showLoading) {
      conditionalProps.onLoad = this.onLoad;
    }

    if ((isError && !loading) || !src) {
      if (typeof altIcon === 'object') {
        return (
          <FlexBox inline {...restProps}>
            <NameIcon
              size={altIcon.size}
              name={altIcon.name}
              color={altIcon.color}
              themeMode={restProps.themeMode}
              textColor={altIcon.textColor}
              rounded={altIcon.rounded}
              borderRadius={altIcon.borderRadius}
            />
          </FlexBox>
        );
      }
      if (placeHolder) {
        return placeHolder;
      }
    }

    return (
      <StyledImg
        {...restProps}
        src={src}
        loading={loading && showLoading}
        onError={this.onError}
        {...conditionalProps}
      />
    );
  }
}
