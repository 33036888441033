import styled from 'styled-components';

import themeGet from '../../../utils/theme-get';

import {
  flatButtonSizeProps,
  flatButtonThemeVariantStateProps,
  iconButtonSizeProps,
  iconButtonThemeVariantStateProps,
  linkButtonThemeVariantStateProps,
  sizeIconStateProps,
  sizeProps,
  themeVariantButtonStateProps
} from './constants';
import {
  ButtonBaseCommonStyleProps,
  ButtonBaseStyleProps,
  FlatButtonBaseStyleProps,
  IconButtonBaseStyleProps,
  LinkButtonStyleProps
} from './types';

const ButtonCommonBase = styled('button')<ButtonBaseCommonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ButtonBase = styled(ButtonCommonBase)<ButtonBaseStyleProps>`
  border-radius: ${themeGet('radii.2')}px;
  background-color: ${props =>
    themeGet(
      themeVariantButtonStateProps[props.themeMode][props.color][props.variant][
        props.isDisabled ? 'disabled' : props.isPressed ? 'pressed' : 'default'
      ].backgroundColor
    )(props)};
  color: ${props =>
    themeGet(
      themeVariantButtonStateProps[props.themeMode][props.color][props.variant][
        props.isDisabled ? 'disabled' : props.isPressed ? 'pressed' : 'default'
      ].color
    )(props)};
  gap: ${props => themeGet(sizeProps[props.size].gap)(props)}px;
  > span {
    font-size: ${props => themeGet(sizeProps[props.size].fontSize)(props)}px;
    line-height: ${props =>
      themeGet(sizeProps[props.size].lineHeight)(props)}px;
  }
  padding-top: ${props =>
    themeGet(sizeIconStateProps[props.size][props.iconState].paddingTop)}px;
  padding-right: ${props =>
    themeGet(sizeIconStateProps[props.size][props.iconState].paddingRight)}px;
  padding-bottom: ${props =>
    themeGet(sizeIconStateProps[props.size][props.iconState].paddingBottom)}px;
  padding-left: ${props =>
    themeGet(sizeIconStateProps[props.size][props.iconState].paddingLeft)}px;
  &:active {
    background-color: ${props =>
      themeGet(
        themeVariantButtonStateProps[props.themeMode][props.color][
          props.variant
        ][props.isDisabled ? 'disabled' : 'pressed'].backgroundColor
      )(props)};
    color: ${props =>
      themeGet(
        themeVariantButtonStateProps[props.themeMode][props.color][
          props.variant
        ][props.isDisabled ? 'disabled' : 'pressed'].color
      )(props)};
  }
  &:focus-visible {
    box-shadow: ${props =>
      props.isDisabled ? 'none' : '0px 0px 0px 3px #E0EAFF'};
    outline: none;
  }
  &:hover {
    background-color: ${props =>
      themeGet(
        themeVariantButtonStateProps[props.themeMode][props.color][
          props.variant
        ][props.isDisabled ? 'disabled' : 'hover'].backgroundColor
      )(props)};
    color: ${props =>
      themeGet(
        themeVariantButtonStateProps[props.themeMode][props.color][
          props.variant
        ][props.isDisabled ? 'disabled' : 'hover'].color
      )(props)};
  }
`;

export const FlatButtonBase = styled(
  ButtonCommonBase
)<FlatButtonBaseStyleProps>`
  background-color: transparent;
  padding: ${themeGet('space.0')}px;
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  gap: ${themeGet('space.1')}px;
  color: ${props =>
    themeGet(
      flatButtonThemeVariantStateProps[
        props.isDisabled ? 'disabled' : 'default'
      ].color
    )(props)};
  > span {
    font-size: ${props =>
      themeGet(flatButtonSizeProps[props.size].fontSize)(props)}px;
    line-height: ${props =>
      themeGet(flatButtonSizeProps[props.size].lineHeight)(props)}px;
  }
  &:active {
    color: ${props =>
      themeGet(
        flatButtonThemeVariantStateProps[
          props.isDisabled ? 'disabled' : 'pressed'
        ].color
      )(props)};
  }
  &:focus-visible {
    border-radius: ${themeGet('radii.2')}px;
    box-shadow: ${props =>
      props.isDisabled ? 'none' : '0px 0px 0px 3px #E0EAFF'};
    outline: none;
  }
  &:hover {
    color: ${props =>
      themeGet(
        flatButtonThemeVariantStateProps[
          props.isDisabled ? 'disabled' : 'hover'
        ].color
      )(props)};
  }
`;

export const IconButtonBase = styled(
  ButtonCommonBase
)<IconButtonBaseStyleProps>`
  background-color: transparent;
  padding: ${themeGet('space.0')}px;
  color: ${props =>
    themeGet(
      iconButtonThemeVariantStateProps[
        props.isDisabled ? 'disabled' : 'default'
      ].color
    )(props)};
  > span {
    font-size: ${props =>
      themeGet(iconButtonSizeProps[props.size].fontSize)(props)}px;
    line-height: ${props =>
      themeGet(iconButtonSizeProps[props.size].lineHeight)(props)}px;
  }
  &:active {
    color: ${props =>
      themeGet(
        iconButtonThemeVariantStateProps[
          props.isDisabled ? 'disabled' : 'pressed'
        ].color
      )(props)};
  }
  &:focus-visible {
    border-radius: ${themeGet('radii.1')}px;
    box-shadow: ${props =>
      props.isDisabled ? 'none' : '0px 0px 0px 3px #E0EAFF'};
    outline: none;
  }
  &:hover {
    color: ${props =>
      themeGet(
        iconButtonThemeVariantStateProps[
          props.isDisabled ? 'disabled' : 'hover'
        ].color
      )(props)};
  }
`;

export const LinkButtonBase = styled(ButtonCommonBase)<LinkButtonStyleProps>`
  background-color: transparent;
  padding: 0;
  color: ${props =>
    themeGet(
      linkButtonThemeVariantStateProps[
        props.isDisabled ? 'disabled' : 'default'
      ].color
    )(props)};
  > span {
    font-size: ${themeGet('fontSizes.1')}px;
    line-height: ${themeGet('longformLineHeights.1')}px;
  }
  &:focus-visible {
    border-radius: ${themeGet('radii.2')}px;
    box-shadow: ${props =>
      props.isDisabled ? 'none' : '0px 0px 0px 3px #E0EAFF'};
    outline: none;
  }
  &:hover {
    color: ${props =>
      themeGet(
        linkButtonThemeVariantStateProps[
          props.isDisabled ? 'disabled' : 'hover'
        ].color
      )(props)};
    text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
  }
`;
