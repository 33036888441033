import { useEffect } from 'react';
import {
  useResetPasswordInvitationApi,
  ResetPasswordRequest,
  ResetPasswordResponseInvitation
} from 'shared/api/v5/auth/invitations/post';
import { SetInput } from '../types';
import { getAuthDataFromStorage, isInternetAvailable } from 'shared/services';
import { useInternetConnection } from './useInternetConnection';
import { useGenerateToken } from './useGenerateToken';
import { usePasswordChecker } from './usePasswordChecker';
import { useInput } from './useInput';
import { useModuleState } from '../module-state';
interface Return {
  isLoading: boolean;
  resetPasswordResponse: ResetPasswordResponseInvitation | undefined;
  isLoginComplete: boolean;
  isError: boolean;
  password1: string;
  password2: string;
  isErrorInputPassword1: boolean;
  isErrorInputPassword2: boolean;
  errorTextInputPassword1: string;
  errorTextInputPassword2: string;
  setPassword1: SetInput;
  setPassword2: SetInput;
  handleResetPassword: (requestBody: ResetPasswordRequest) => void;
  isMinimumChar: boolean;
  isUpperChar: boolean;
  isLowerChar: boolean;
  isNumericChar: boolean;
  isSpecialChar: boolean;
  showPasswordChecker: boolean;
  isButtonDisabled: boolean;
  defaultMessage: string;
  defaultSubText: string;
}

export const useResetPasswordInvitation = (): Return => {
  const defaultMessage = `Password set successfully!`;
  const defaultSubText = `You can now proceed to your Synaptic account.`;

  const [
    {
      isErrorResetPasswordInvitation: isError,
      isErrorPassword1ResetPasswordInvitation: isErrorPassword1,
      isErrorPassword2ResetPasswordInvitation: isErrorPassword2,
      resetError1ResetPasswordInvitation: resetError1,
      resetError2ResetPasswordInvitation: resetError2
    },
    setModuleState
  ] = useModuleState();

  const {
    mutate,
    data: resetPasswordResponse,
    isLoading
  } = useResetPasswordInvitationApi();

  const [password1, setPassword1, isValidPassword1, errorTextPassword1] =
    useInput('password1');
  const [
    password2,
    setPassword2,
    isValidPassword2,
    errorTextPassword2,
    touchedPassword2,
    setTouchedPassword2
  ] = useInput('password2');
  const { setIsOfflineErrorShown } = useInternetConnection();

  const {
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar
  } = usePasswordChecker();

  const {
    isLoading: isTokenLoading,
    handleGenerateToken,
    isLoginComplete
  } = useGenerateToken(false);

  const handleResetPassword = async (requestBody: ResetPasswordRequest) => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      setTouchedPassword2(true);
      const { code } = await getAuthDataFromStorage(['code']);
      const validAction = password1 !== '' && password1 === password2;
      if (validAction) {
        mutate(
          { ...requestBody, code },
          {
            onSuccess: (response: ResetPasswordResponseInvitation) => {
              if (response?.data?.authorization_code) {
                handleGenerateToken({
                  authorization_code: response?.data?.authorization_code,
                  signInMedium: 'invitation'
                });
              } else {
                setModuleState({ isErrorResetPasswordInvitation: true });
              }
            },
            onError: (error: { data: ResetPasswordResponseInvitation }) => {
              const { data: errorResponse } = error;
              const message = errorResponse?.errors || '';
              setModuleState({
                isErrorResetPasswordInvitation: true,
                resetError1ResetPasswordInvitation: message
              });
            }
          }
        );
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  useEffect(() => {
    if (password1 && password2 && password1 !== password2) {
      setModuleState({
        isErrorPassword2ResetPasswordInvitation: true,
        resetError2ResetPasswordInvitation: 'Passwords do not match. Try again.'
      });
    }
    if (password1 === password2) {
      setModuleState({
        isErrorPassword2ResetPasswordInvitation: false,
        resetError2ResetPasswordInvitation: ''
      });
    }
    setModuleState({
      resetError1ResetPasswordInvitation: '',
      isErrorPassword1ResetPasswordInvitation: false
    });

    setTouchedPassword2(false);
  }, [password1, password2]);

  useEffect(() => {
    if (isError) {
      setModuleState({
        isErrorPassword1ResetPasswordInvitation: true,
        resetError1ResetPasswordInvitation:
          resetPasswordResponse?.data?.errors ||
          resetPasswordResponse?.errors ||
          ''
      });
    }
  }, [isError, resetPasswordResponse]);

  // Should we extract this into a separate hook or use 'useState'?
  const showErrorPassword1 = !!password1 && !isValidPassword1;
  const showErrorPassword2 = !!password2 && !isValidPassword2;
  const showPasswordChecker = !!password1 && !showErrorPassword1;
  const isErrorInputPassword1 = showErrorPassword1 || isErrorPassword1;
  const isErrorInputPassword2 =
    showErrorPassword2 || (touchedPassword2 && isErrorPassword2);
  const errorTextInputPassword1 = errorTextPassword1 || resetError1;
  const errorTextInputPassword2 = errorTextPassword2 || resetError2;
  const isButtonDisabled =
    !password1 ||
    !password2 ||
    errorTextPassword1 !== '' ||
    errorTextPassword2 !== '' ||
    !isMinimumChar ||
    !isUpperChar ||
    !isLowerChar ||
    !isNumericChar ||
    !isSpecialChar;

  return {
    isLoading: isLoading || isTokenLoading,
    resetPasswordResponse,
    isLoginComplete,
    isError,
    password1,
    password2,
    isErrorInputPassword1,
    isErrorInputPassword2,
    errorTextInputPassword1,
    errorTextInputPassword2,
    setPassword1,
    setPassword2,
    handleResetPassword,
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar,
    showPasswordChecker,
    isButtonDisabled,
    defaultMessage,
    defaultSubText
  };
};
