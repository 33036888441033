const commonEventParams: string[] = [
  'product_source',
  'product_version',
  'analytics_context',
  'user_id',
  'user_email',
  'user_status',
  'org_status',
  'org_id',
  'org_name'
];

export default commonEventParams;
