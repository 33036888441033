import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from '../../../atoms/box';
import TippyHeadless from '@tippyjs/react/headless';
import { BoxProps } from '../../../atoms/box';
import { TooltipProps } from './index';

const ARROW_SIZE = 11;
const ARROW_OFFSET = 4;
const ARROW_HALF_WIDTH = ARROW_SIZE / 2;

export const StyledTooltip = TippyHeadless;

export const StyledTooltipContainer = styled(Box)<
  BoxProps & Pick<TooltipProps, 'variant'>
>`
  background-color: ${props =>
    props.variant === 'idle'
      ? themeGet('colors.blue.6')
      : themeGet('colors.black.7')};
  color: ${themeGet('colors.text.white')};
  border-radius: ${themeGet('radii.1')}px;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  position: relative;
  max-width: 240px;
  font-size: ${themeGet('fontSizes.1')}px;
  line-height: ${themeGet('longformLineHeights.1')}px;
  font-weight: ${themeGet('fontWeights.medium')};
  min-height: 20px;
`;

export const Arrow = styled(Box)<BoxProps & Pick<TooltipProps, 'placement'>>`
  display: block;
  height: ${ARROW_SIZE}px;
  width: ${ARROW_SIZE}px;
  background-color: inherit;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 0 ${themeGet('radii.1')}px;
  ${props =>
    props.placement === 'right' &&
    css`
      left: -${ARROW_OFFSET}px;
      top: calc(50% - ${ARROW_HALF_WIDTH}px);
      transform: rotate(45deg);
    `}

  ${props =>
    props.placement === 'right-start' &&
    css`
      left: -${ARROW_OFFSET}px;
      top: 10px;
      transform: rotate(45deg);
    `}

    ${props =>
    props.placement === 'right-end' &&
    css`
      left: -${ARROW_OFFSET}px;
      bottom: ${themeGet('space.2')}px;
      transform: rotate(45deg);
    `}

  ${props =>
    props.placement === 'top' &&
    css`
      bottom: -${ARROW_OFFSET}px;
      left: calc(50% - ${ARROW_HALF_WIDTH}px);
      transform: rotate(-45deg);
    `}

    ${props =>
    props.placement === 'top-start' &&
    css`
      bottom: -${ARROW_OFFSET}px;
      left: ${themeGet('space.2')}px;
      transform: rotate(-45deg);
    `}

      ${props =>
    props.placement === 'top-end' &&
    css`
      bottom: -${ARROW_OFFSET}px;
      right: ${themeGet('space.2')}px;
      transform: rotate(-45deg);
    `}

    ${props =>
    props.placement === 'bottom' &&
    css`
      top: -${ARROW_OFFSET}px;
      left: calc(50% - ${ARROW_HALF_WIDTH}px);
      transform: rotate(135deg);
    `}

      ${props =>
    props.placement === 'bottom-start' &&
    css`
      top: -${ARROW_OFFSET}px;
      left: ${themeGet('space.2')}px;
      transform: rotate(135deg);
    `}


      ${props =>
    props.placement === 'bottom-end' &&
    css`
      top: -${ARROW_OFFSET}px;
      right: ${themeGet('space.2')}px;
      transform: rotate(135deg);
    `}

      ${props =>
    props.placement === 'left' &&
    css`
      right: -${ARROW_OFFSET}px;
      top: calc(50% - ${ARROW_HALF_WIDTH}px);
      transform: rotate(225deg);
    `}

        ${props =>
    props.placement === 'left-start' &&
    css`
      right: -${ARROW_OFFSET}px;
      top: ${themeGet('space.2')}px;
      transform: rotate(225deg);
    `}

        ${props =>
    props.placement === 'left-end' &&
    css`
      right: -${ARROW_OFFSET}px;
      bottom: ${themeGet('space.2')}px;
      transform: rotate(225deg);
    `}
`;
