import { useModuleState } from '../module-state';
import { InputLabel, SetInputValue, SetInput } from '../types';
import { validateEmail } from 'shared/utils/stringUtils';

const validate = (label: InputLabel, value: string): [boolean, string] => {
  let isValid = true;
  let errorText = '';
  if (label === 'email') {
    if (!value || value === '') {
      isValid = false;
      errorText = 'Please enter your email address';
    } else {
      isValid = validateEmail(value);
      if (!isValid) {
        errorText = 'Please enter a valid email address';
      }
    }
  }
  if (label === 'password') {
    if (!value || value === '') {
      isValid = false;
      errorText = 'Please enter your password';
    }
  }
  if (label === 'password1' || label === 'password2') {
    if (!value || value === '') {
      isValid = false;
      errorText = 'Please enter your password';
    }
    if (/^\s|\s$/.test(value)) {
      isValid = false;
      errorText = `Your password can’t start or end with a blank space`;
    }
  }
  return [isValid, errorText];
};

export const useInput = (
  label: InputLabel
): [string, SetInput, boolean, string, boolean, (arg: boolean) => void] => {
  const [moduleState, setModuleState] = useModuleState();

  const handleSetInput = (e: SetInputValue): void => {
    let inputValue = '';
    // This approach makes it generic and 'useInput' can be used in mobile as well (To be verified!).
    if (typeof e === 'string') {
      inputValue = e;
    } else {
      const { value } = e.target;
      inputValue = value;
    }
    const [isValid, errorText] = validate(label, inputValue);
    const touched = moduleState['touched'];
    touched[label] = false;
    setModuleState({
      [label]: inputValue,
      errors: { ...moduleState.errors, [label]: { isValid, errorText } },
      touched
    });
  };

  const setTouched = (value: boolean): void => {
    const touched = moduleState['touched'];
    touched[label] = value;
    setModuleState({
      touched
    });
  };

  const isValidInput = moduleState['errors'][label]['isValid'];
  const errorText = moduleState['errors'][label]['errorText'];
  const touched = moduleState['touched'][label];

  return [
    moduleState[label],
    handleSetInput,
    isValidInput,
    errorText,
    touched,
    setTouched
  ];
};
