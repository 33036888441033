import React from 'react';

import { View, FlexBox } from 'tuxedo-v2';

import { useInternetConnection } from 'shared/modules/auth-module/hooks';
import FooterComponent from '../../components/FooterComponent';
import {
  ContentWrapper,
  ImageContainer,
  ContentWrapperChild
} from '../../styled';
import OfflineError from '../../components/OfflineError';
import { imageConfig } from 'config';

interface Props {
  children: React.ReactNode;
}

function LoginViewWrapper({ children }: Props): JSX.Element {
  const { isOfflineErrorShown } = useInternetConnection();

  return isOfflineErrorShown ? (
    <OfflineError />
  ) : (
    <View
      themeMode='light'
      style={{ backgroundColor: '#fff', height: '100vh' }}
    >
      <FlexBox overflow='hidden'>
        <ImageContainer flex={1}>{imageConfig.sideImage}</ImageContainer>
        <ContentWrapper>
          <ContentWrapperChild>
            <FlexBox flexDirection='column' width='400px'>
              {children}
            </FlexBox>
          </ContentWrapperChild>
          <FooterComponent />
        </ContentWrapper>
      </FlexBox>
    </View>
  );
}

export default LoginViewWrapper;
