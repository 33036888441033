import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired,
  isInternetAvailable
} from 'shared/services';

import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import { useGenerateOtpApi } from 'shared/api/v5/auth/mfa/generate/get';
import { GenerateOtpResponse } from '../types';
import { useModuleState } from '../module-state';

interface Return {
  isLoading: boolean;
  generateOtpResponse: GenerateOtpResponse | undefined;
  handleGenerateOtp: () => void;
  isError: boolean;
  resendStatusLabel: string;
  resendStatusSubLabel: string;
  setIsError: (arg: boolean) => void;
}

export const useGenerateOtp = (): Return => {
  const [
    {
      isErrorGenerateOtp: isError,
      resendStatusLabelGenerateOtp: resendStatusLabel,
      resendStatusSubLabelGenerateOtp: resendStatusSubLabel
    },
    setModuleState
  ] = useModuleState();
  const { setIsOfflineErrorShown } = useInternetConnection();
  const { mutate, data: generateOtpResponse, isLoading } = useGenerateOtpApi();
  const [email] = useInput('email');

  const setIsError = (arg: boolean) => {
    setModuleState({ isErrorForgotPassword: arg });
  };

  const handleGenerateOtp = async () => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      const { code } = await getAuthDataFromStorage(['code']);
      mutate(
        { email, code },
        {
          onSuccess: (response: GenerateOtpResponse) => {
            if (response?.login_session_expired === true) {
              handleSessionExpired();
            } else {
              if (response?.data?.resend_mfa?.is_error) {
                setModuleState({ isErrorGenerateOtp: true });
              }
              if (
                response?.data?.resend_mfa?.additional_messages?.attempts !== ''
              ) {
                setModuleState({
                  resendStatusLabelGenerateOtp:
                    response?.data?.resend_mfa?.additional_messages?.attempts ||
                    '',
                  resendStatusSubLabelGenerateOtp:
                    response?.data?.resend_mfa?.additional_messages?.subtext ||
                    ''
                });
              }
              if (
                response?.meta?.code &&
                response?.meta?.synaptic_session_exp
              ) {
                setAuthDataToStorage({
                  code: response?.meta?.code,
                  synaptic_session_exp: response?.meta?.synaptic_session_exp
                });
              }
            }
          },
          onError: (error: { data: GenerateOtpResponse }) => {
            const { data: errorResponse } = error;
            if (errorResponse?.login_session_expired === true) {
              handleSessionExpired();
            } else {
              if (
                errorResponse?.meta?.code &&
                errorResponse?.meta?.synaptic_session_exp
              ) {
                setAuthDataToStorage({
                  code: errorResponse?.meta?.code,
                  synaptic_session_exp:
                    errorResponse?.meta?.synaptic_session_exp
                });
              }
              setModuleState({
                resendStatusLabelGenerateOtp:
                  errorResponse?.data?.resend_mfa?.additional_messages
                    ?.attempts || '',
                resendStatusSubLabelGenerateOtp:
                  errorResponse?.data?.resend_mfa?.additional_messages
                    ?.subtext || '',
                isErrorGenerateOtp: true
              });
            }
          }
        }
      );
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  return {
    isLoading,
    generateOtpResponse,
    handleGenerateOtp,
    isError,
    resendStatusLabel,
    resendStatusSubLabel,
    setIsError
  };
};
