import styled, { css } from 'styled-components';
import { FlexBox, Box, Text } from 'tuxedo-v2';
import cleanComponentProps from 'tuxedo-v2/utils/cleanComponentProps';
import themeGet from 'tuxedo-v2/utils/theme-get';

export const StyledHeaderText = styled(Text)`
  font-size: ${themeGet('fontSizes.5')}px;
  font-weight: ${themeGet('fontWeights.medium')};
  margin-bottom: ${themeGet('space.12')}px;
  text-align: center;
`;

export const StyledLogoContainer = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  height: ${themeGet('heights.9')}px;
  width: ${themeGet('heights.9')}px;
  padding: ${themeGet('space.4')}px;
  border: ${themeGet('borders.1')}px solid
    ${themeGet('colors.outline.light.outline2')};
  border-radius: ${themeGet('radii.2')}px;
  :hover {
    background-color: ${themeGet('colors.brand.25')};
  }
  :focus {
    border: 2px solid ${themeGet('colors.brand.100')};
  }
`;

export const StyledMagicLinkContainer = styled(FlexBox)`
  margin-top: ${themeGet('space.6')}px;
  padding: ${themeGet('space.3')}px;
  border: ${themeGet('borders.1')}px solid
    ${themeGet('colors.outline.light.outline2')};
  border-radius: ${themeGet('radii.2')}px;
`;

// 141.5px width in design
export const StyledHorizontalLine = styled(Box)`
  height: 1px;
  background-color: ${themeGet('colors.outline.light.outline2')};
  width: 141.5px;
`;

// Use value from design system - 41px in design
export const StyledFooterContainer = styled(FlexBox)`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${themeGet('heights.5')}px;
  width: 100%;
  border-top: ${themeGet('borders.1')}px solid
    ${themeGet('colors.outline.light.outline3')};
`;

export const ContentWrapper = styled(Box)`
  flex: 3;
  overflow: auto;
  flex-direction: column-reverse;
`;

export const ContentWrapperChild = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  min-height: calc(100vh - 40px);
`;

export const StyledStatusCard = cleanComponentProps(FlexBox, ['type'])<{
  type: string;
}>`
  border: ${themeGet('borders.1')}px solid
    ${css((props: { type: string }) =>
      props.type === 'success'
        ? themeGet('colors.outline.light.success')
        : props.type === 'error'
        ? themeGet('colors.outline.light.error')
        : themeGet('colors.outline.light.warning')
    )};
  border-radius: ${themeGet('radii.2')}px;
  padding: ${themeGet('space.4')}px ${themeGet('space.3')}px;
`;

// Use value from design system - 26px in design
export const StyledIconContainer = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  width: ${themeGet('space.4')}px;
  height: ${themeGet('space.4')}px;
  border-radius: 50%;
`;

export const ImageContainer = styled(FlexBox)`
  height: 100vh;
  flex: 1;
  @media screen and (max-width: 1280px) {
    display: none !important;
  }
`;

export const StyledAnchorTag = styled.a`
  color: ${themeGet('colors.brand.600')};
  :hover {
    color: ${themeGet('colors.brand.600')};
  }
`;

export const StyledErrorPageImageContainer = styled(FlexBox)`
  margin-top: 130px;
`;
