import cleanComponentProps from '../../../utils/cleanComponentProps';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import { FONT_COLOR_TYPE, FONT_WEIGHT_TYPE } from 'theme-v2/types';
import {
  TextOverflowProp,
  WhiteSpaceProps,
  WordBreakProps
} from '../../../props';

interface BodyBasePropTypes {
  weight: FONT_WEIGHT_TYPE;
  color: FONT_COLOR_TYPE;
  textOverflow?: TextOverflowProp;
  whiteSpace?: WhiteSpaceProps;
  wordBreak?: WordBreakProps;
  isItalic?: boolean;
  title?: string;
}

const bodyGenerator = (
  el: keyof JSX.IntrinsicElements,
  fontSize: number,
  lineHeight: number
) => {
  const Component = cleanComponentProps<BodyBasePropTypes>(el, [
    'theme',
    'weight',
    'color',
    'textOverflow',
    'whiteSpace',
    'wordBreak',
    'isItalic'
  ])`
    ${props => `font-size: ${themeGet(`text.fontSizes.${fontSize}`)(props)};`}
    ${props =>
      `font-weight: ${themeGet(`text.fontWeights.${props.weight}`)(props)};`}
    ${props =>
      `line-height: ${themeGet(`text.lineHeights.${lineHeight}`)(props)};`}
    ${props =>
      `color: ${themeGet(`colors.text.${props.theme.themeMode}.${props.color}`)(
        props
      )};`}
    ${({ textOverflow }) =>
      textOverflow &&
      `overflow: hidden; white-space:nowrap; text-overflow: ${textOverflow};`}
    ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
    ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak};`}
    ${({ isItalic }) => isItalic && `font-style: italic;`}
  `;
  return WithV2Theme(Component);
};

const SizeMap = {
  large: bodyGenerator('span', 200, 200), // font-size: 16px; line-height: 24px;
  medium: bodyGenerator('span', 100, 100), // font-size: 14px; line-height: 20px;
  small: bodyGenerator('span', 75, 50) // font-size: 12px; line-height: 16px;
};

interface BodyProps extends Omit<BodyBasePropTypes, 'weight' | 'color'> {
  children: React.ReactNode;
  size?: keyof typeof SizeMap;
  weight?: FONT_WEIGHT_TYPE;
  color?: FONT_COLOR_TYPE;
}

const Body = ({
  size = 'medium',
  weight = 'semi-bold',
  color = 'text1',
  textOverflow,
  whiteSpace,
  wordBreak,
  children,
  isItalic,
  title
}: BodyProps): JSX.Element => {
  const Component = SizeMap[size] as React.ElementType;
  return (
    <Component
      weight={weight}
      color={color}
      textOverflow={textOverflow}
      whiteSpace={whiteSpace}
      wordBreak={wordBreak}
      isItalic={isItalic}
      title={title}
    >
      {children}
    </Component>
  );
};

Body.displayName = 'Body';

export default Body;
