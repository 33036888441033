import { useGenerateTokenApi } from 'shared/api/v5/auth/sessions/token_post/post';
import { GenerateTokenResponse } from '../types';
import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleLoginComplete
} from 'shared/services';
import { CLIENT_ID } from '../constants';

import { useModuleState } from '../module-state';
import { useEventTracker } from 'shared/events';
import { UserSignedInEvent } from 'shared/modules/events/platform-events';
import { UserSessionStartedEvent } from 'shared/modules/events/platform-events';

interface Return {
  isLoading: boolean;
  generateTokenResponse: GenerateTokenResponse | undefined;
  handleGenerateToken: ({
    authorization_code,
    signInMedium
  }: {
    authorization_code: string;
    signInMedium?: string;
  }) => void;
  isLoginComplete: boolean;
  isError: boolean;
}

export const useGenerateToken = (
  allowAutoRedirection = true,
  customRedirectionOnLoginComplete?: () => void
): Return => {
  const [{ isLoginComplete }, setModuleState] = useModuleState();
  const {
    mutate,
    data: generateTokenResponse,
    isLoading,
    isError
  } = useGenerateTokenApi();

  const [trackEvent] = useEventTracker();

  const handleGenerateToken = async ({
    authorization_code,
    signInMedium = 'sign_in'
  }: {
    authorization_code: string;
    signInMedium?: string;
  }) => {
    setModuleState({ isLoginComplete: false });
    const { code_verifier } = await getAuthDataFromStorage(['code_verifier']);
    mutate(
      { authorization_code, code_verifier, client_id: CLIENT_ID },
      {
        onSuccess: (response: GenerateTokenResponse) => {
          if (response?.data?.access_token) {
            trackEvent(UserSignedInEvent, {
              event_module: 'misc',
              event_source: 'sign_in',
              event_medium: null,
              sign_in_medium: signInMedium
            });
            trackEvent(UserSessionStartedEvent);
            setAuthDataToStorage({
              access_token: response?.data?.access_token,
              refresh_token: response?.data?.refresh_token,
              authorization_code
            }).then(() => {
              if (allowAutoRedirection) {
                setTimeout(
                  customRedirectionOnLoginComplete || handleLoginComplete,
                  1500
                );
              }
              setModuleState({ isLoginComplete: true });
            });
          }
        }
      }
    );
  };

  return {
    isLoginComplete,
    isLoading,
    generateTokenResponse,
    handleGenerateToken,
    isError
  };
};
