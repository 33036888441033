import { useEffect } from 'react';

import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired,
  isInternetAvailable
} from 'shared/services';

import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import { useGenerateOtp } from './useGenerateOtp';
import { useGenerateToken } from './useGenerateToken';
import {
  useValidateOtpApi,
  SignInResponse
} from 'shared/api/v5/auth/mfa/validate/get';
import { SetInput, GenerateOtpResponse } from '../types';
import { useModuleState } from '../module-state';

interface Return {
  defaultMessage: string;
  defaultSubText: string;
  isLoading: boolean;
  validateOtpResponse: SignInResponse | undefined;
  handleValidateOtp: () => void;
  isError: boolean;
  isLoginComplete: boolean;
  securityCode: string;
  setSecurityCode: SetInput;
  validateStatusLabel: string;
  validateStatusSubLabel: string;
  isWarning: boolean;
  errorText: string;
  isErrorTextShown: boolean;

  // Resend
  isResendLoading: boolean;
  generateOtpResponse: GenerateOtpResponse | undefined;
  handleGenerateOtp: () => void;
  isResendError: boolean;
  resendStatusLabel: string;
  resendStatusSubLabel: string;

  // MFA attempts already exhausted
  isMfaAttemptExceeded?: boolean;
  showMfaAttemptsExhaustedError?: boolean;
  mfaAttemptExceededLabel: string;
  mfaAttemptExceededSubLabel: string;
  hideInput?: boolean;
  hideErrorState: () => void;
}

export const useValidateOtp = (
  signInResponse: SignInResponse | undefined
): Return => {
  const defaultMessage = `Security Code sent to your inbox`;
  const defaultSubText = `If you have an account with us, you'll receive a temporary security code. Paste it below to sign in.`;

  const [
    {
      isErrorValidateOtp: isError,
      isErrorTextShownValidateOtp: isErrorTextShown,
      isWarningValidateOtp: isWarning,
      validateStatusLabelValidateOtp: validateStatusLabel,
      validateStatusSubLabelValidateOtp: validateStatusSubLabel,
      errorTextValidateOtp: errorText
    },
    setModuleState
  ] = useModuleState();
  const { mutate, data: validateOtpResponse, isLoading } = useValidateOtpApi();
  const {
    isLoading: isTokenLoading,
    handleGenerateToken,
    isLoginComplete,
    isError: isGenerateTokenError
  } = useGenerateToken();
  const [securityCode, setSecurityCode] = useInput('mfaCode');
  const { setIsOfflineErrorShown } = useInternetConnection();

  const {
    generateOtpResponse,
    handleGenerateOtp,
    isError: isResendError,
    resendStatusLabel,
    resendStatusSubLabel,
    isLoading: isResendLoading
  } = useGenerateOtp();

  const hideErrorState = () => {
    setModuleState({
      isGoogleLoginError: false,
      googleLoginErrorText: '',
      isWarningValidateOtp: false,
      isErrorValidateOtp: false
    });
    setSecurityCode('');
  };

  const handleValidateOtp = async () => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      setModuleState({
        isErrorValidateOtp: false,
        isErrorTextShownValidateOtp: false,
        isWarningValidateOtp: false
      });
      const { code } = await getAuthDataFromStorage(['code']);
      if (securityCode && securityCode !== 'null') {
        mutate(
          { mfa_code: securityCode, code },
          {
            onSuccess: (response: SignInResponse) => {
              if (response?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                if (response?.data?.incorrect_mfa?.is_error) {
                  setModuleState({
                    isErrorValidateOtp: true,
                    isErrorTextShownValidateOtp: true,
                    validateStatusLabelValidateOtp:
                      response?.data?.incorrect_mfa?.additional_messages
                        ?.attempts || '',
                    validateStatusSubLabelValidateOtp:
                      response?.data?.incorrect_mfa?.additional_messages
                        ?.subtext || ''
                  });
                }
                if (
                  response?.data?.incorrect_mfa?.additional_messages
                    ?.error_text !== ''
                ) {
                  setModuleState({
                    errorTextValidateOtp:
                      response?.data?.incorrect_mfa?.additional_messages
                        ?.error_text,
                    isWarningValidateOtp: true
                  });
                }
                if (
                  response?.meta?.code &&
                  response?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: response?.meta?.code,
                    synaptic_session_exp: response?.meta?.synaptic_session_exp
                  });
                }
                if (response?.data?.authorization_code) {
                  handleGenerateToken({
                    authorization_code: response?.data?.authorization_code
                  });
                }
              }
            },
            onError: (error: { data: SignInResponse }) => {
              const { data: errorResponse } = error;
              if (errorResponse?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                if (
                  errorResponse?.meta?.code &&
                  errorResponse?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: errorResponse?.meta?.code,
                    synaptic_session_exp:
                      errorResponse?.meta?.synaptic_session_exp
                  });
                }
                setModuleState({
                  isErrorValidateOtp: true,
                  isErrorTextShownValidateOtp: true,
                  validateStatusLabelValidateOtp:
                    errorResponse?.data?.incorrect_mfa?.additional_messages
                      ?.attempts || '',
                  validateStatusSubLabelValidateOtp:
                    errorResponse?.data?.incorrect_mfa?.additional_messages
                      ?.subtext || ''
                });
              }
            }
          }
        );
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  useEffect(() => {
    setModuleState({
      isErrorTextShownValidateOtp: false
    });
  }, [securityCode]);

  const isMfaAttemptExceeded =
    signInResponse !== null && signInResponse?.data?.resend_mfa?.is_error;
  const mfaAttemptExceededLabel =
    signInResponse?.data?.resend_mfa?.additional_messages?.attempts || '';
  const mfaAttemptExceededSubLabel =
    signInResponse?.data?.resend_mfa?.additional_messages?.subtext || '';
  const showMfaAttemptsExhaustedError =
    !isResendError && !generateOtpResponse && isMfaAttemptExceeded;
  const hideInput =
    (isResendError || isMfaAttemptExceeded) && isError && !isWarning;

  return {
    defaultMessage,
    defaultSubText,
    isLoading: isLoading || isTokenLoading,
    validateOtpResponse,
    handleValidateOtp,
    isError: isError || isGenerateTokenError,
    isLoginComplete,
    securityCode,
    setSecurityCode,
    validateStatusLabel,
    validateStatusSubLabel,
    isWarning,
    errorText,
    isErrorTextShown,
    generateOtpResponse,
    handleGenerateOtp,
    isResendError,
    resendStatusLabel,
    resendStatusSubLabel,
    isResendLoading,
    isMfaAttemptExceeded,
    mfaAttemptExceededLabel,
    mfaAttemptExceededSubLabel,
    showMfaAttemptsExhaustedError,
    hideInput,
    hideErrorState
  };
};
