/* eslint-disable no-redeclare */
import React from 'react';
import numberWithCommas from './numberWithCommas';

export function convertUnderscoreToCapitalize(string: string): string {
  const stringSplitArr = string.split('_');
  const capStringArr = stringSplitArr.map(strPart => {
    return strPart.charAt(0).toUpperCase() + strPart.slice(1);
  });

  return capStringArr.join(' ');
}

export function splitCamelCase(str: string): string {
  let returnStr = '';
  for (let i = 0; i < str.length; i++) {
    if (
      i > 0 &&
      str[i] === str[i].toUpperCase() &&
      str[i] !== str[i].toLowerCase()
    ) {
      returnStr += ' ';
    }
    returnStr += str[i];
  }

  return returnStr;
}

// Overloads of safeGet function that return actual type from passed parameters
// Path length 1
// Pass path as a constant array of string so that TypeScript considers it
// static that wouldn't change at runtime.
// -> safeGet(object, [p1, p2] as const)
// Note: If along a path is key can be undefined, these overloads will always
// return undefined.
// -> safeGet<expectedType>(object, [p1, p2]) (without const)
export function safeGet<T, P extends readonly [string | number]>(
  obj: T,
  path: P
): P[0] extends keyof T ? T[P[0]] : undefined;

// Path length 2
export function safeGet<
  T,
  P extends readonly [string | number, string | number]
>(
  obj: T,
  path: P
): P[0] extends keyof T
  ? P[1] extends keyof T[P[0]]
    ? T[P[0]][P[1]]
    : undefined
  : undefined;

// Path length 3
export function safeGet<
  T,
  P extends readonly [string | number, string | number, string | number]
>(
  obj: T,
  path: P
): P[0] extends keyof T
  ? P[1] extends keyof T[P[0]]
    ? P[2] extends keyof T[P[0]][P[1]]
      ? T[P[0]][P[1]][P[2]]
      : undefined
    : undefined
  : undefined;

// Path length 4
export function safeGet<
  T,
  P extends readonly [
    string | number,
    string | number,
    string | number,
    string | number
  ]
>(
  obj: T,
  path: P
): P[0] extends keyof T
  ? P[1] extends keyof T[P[0]]
    ? P[2] extends keyof T[P[0]][P[1]]
      ? P[3] extends keyof T[P[0]][P[1]][P[2]]
        ? T[P[0]][P[1]][P[2]][P[3]]
        : undefined
      : undefined
    : undefined
  : undefined;

// Generic overload for rest of the cases
export function safeGet<R>(
  obj: { [key: string]: unknown } | Array<unknown>,
  path: (string | number)[]
): R | undefined;

export function safeGet<R>(
  obj: { [key: string]: unknown } | Array<unknown> = {},
  path: (string | number)[]
): R | undefined {
  return Array.prototype.reduce.call(
    path,
    (object: unknown, el: string) => {
      if (object !== null && object !== undefined) {
        if (typeof el === 'string' && el.trim() === '') {
          return object;
        }
        return (object as { [key: string]: unknown })[el];
      }
    },
    obj
  );
}

export function safeToFixed(str: string | number, decimal: number): string {
  return Number(str).toFixed(decimal);
}

export function trimZeroes(str: string): number | null {
  if (str) {
    return parseFloat(String(Number(str)));
  }
  return null;
}

export function hashRateConvertor(value: number, decimal = 2): string {
  let hashRate = '';
  if (value === 0) {
    hashRate = '0';
  } else if (value > 0 && value < 1000) {
    hashRate = `${numberWithCommas(safeToFixed(value, decimal))}  H/s`;
  } else if (value >= 1000 && value < 1000000) {
    hashRate = `${numberWithCommas(safeToFixed(value / 1000, decimal))} KH/s`;
  } else if (value >= 1000000 && value < 1000000000) {
    hashRate = `${numberWithCommas(
      safeToFixed(value / 1000000, decimal)
    )}  MH/s`;
  } else if (value >= 1000000000 && value < 1000000000000) {
    hashRate = `${numberWithCommas(
      safeToFixed(value / 1000000000, decimal)
    )}  GH/s`;
  } else if (value >= 1000000000000) {
    hashRate = `${numberWithCommas(
      safeToFixed(value / 1000000000000, decimal)
    )}  TH/s`;
  }
  return hashRate;
}

export function insertNewLineChar(string: string, breakLength: number): string {
  if (breakLength < string.length) {
    const strArr = Array.from(
      Array(parseInt(String(string.length / breakLength + 1), 10))
    ).map((el, index) => {
      return string.slice(
        index * breakLength,
        index * breakLength + breakLength
      );
    });
    return strArr.join(' <br> ');
  }
  return string;
}

export function highlightKeyword(
  str: string,
  keyword: string,
  highlightConfig: Record<string, unknown> = {}
): React.ReactNode {
  const { highlightClass = 'blue-text text-b', caseSensitive = false } =
    highlightConfig;
  const regexMode = `g${caseSensitive ? '' : 'i'}`;
  const processedKeyword = `${keyword}`.replace(
    /[\-\[\]\/\{\}\(\)\+\?\.\\\^\$\|]/g,
    '\\$&'
  );
  const reg = new RegExp(`${processedKeyword}`, regexMode);
  const matches = str.match(reg) || [];

  return str.split(reg).map((node, index) => {
    return index === 0
      ? node
      : [
          React.createElement(
            'span',
            {
              className: highlightClass,
              key: 'seperator'
            },
            matches[index - 1]
          ),
          node
        ];
  });
}

/*
 * Transformed JSX written here at https://babeljs.io/repl. Ideally we should not have
 * JSX inside util functions
 */
export function joinStringsArr(
  strings: string[],
  classN = false
): React.ReactNode {
  return strings.map((el, index) => {
    if (index === 0) {
      return React.createElement(
        'span',
        {
          key: el,
          className: classN
        },
        el
      );
    }
    if (index === strings.length - 1) {
      return React.createElement(
        'span',
        {
          key: el
        },
        ' ',
        'and ',
        React.createElement(
          'span',
          {
            className: classN
          },
          el
        )
      );
    }
    return React.createElement(
      'span',
      {
        key: el
      },
      ', ',
      React.createElement(
        'span',
        {
          className: classN
        },
        el
      )
    );
  });
}

export function isValidPath(
  obj: { [key: string]: unknown } | Array<unknown>,
  path: (string | number)[]
): boolean {
  if (!(path instanceof Array) || path.length === 0) {
    return false;
  }
  const deepObj = safeGet(obj, path.slice(0, path.length - 1));
  if (deepObj) {
    return Object.hasOwnProperty.call(deepObj, path[path.length - 1]);
  }
  return false;
}

/**
 * Convert an array of items to string: Liked by Synaptic and 4 others
 * @param  {any}  items        array of items
 * @param  {[type]}  displayKey   which property of the items to use in string (Synaptic)
 * @param  {[type]}  endText      text to append at the end in case showAllItems is false
 * @param  {Boolean} showAllItems Should we show all items or simply append 'others' at the end
 */
export function convertArrayToCombinedString(
  items: Record<string, string>[],
  displayKey: string,
  endText: string,
  showAllItems = false
): string | null {
  if (items.length === 1) {
    return items[0][displayKey];
  } else if (items.length === 2) {
    return `${items[0][displayKey]} and ${items[1][displayKey]}`;
  } else if (items.length > 2) {
    if (showAllItems) {
      const text = items.reduce((result, item, index) => {
        if (index === items.length - 1) {
          return `${result} and ${item[displayKey]}`;
        } else {
          return `${result}${result === '' ? '' : ', '}${item[displayKey]}`;
        }
      }, '');

      return text;
    } else {
      return `${items[0][displayKey]} and ${items.length - 1} ${endText}`;
    }
  }

  return null;
}

export const newLine2Br = (text: string): string => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

import { get } from 'lodash';
import {
  AllMetricRollupData,
  HeaderKey,
  SheetData,
  WidthObject
} from 'shared/modules/portfolio-iq/types';
import { MIN_COLUMN_WIDTH } from '../constants/metrics-table';

export const getWidthOfText = (text: string, font?: string): number => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return 0;
  }
  ctx.font = font || '24px Arial';
  const width = ctx.measureText(text).width;
  return width;
};

const findMaxWidth = (
  key: string,
  headerLabel: string,
  data: (AllMetricRollupData | SheetData)[]
): number => {
  let width = 0;
  const keyArr = key.split('.');
  data.forEach(val => {
    const value = get(val, keyArr) as string;
    let label = value;
    if (typeof value !== 'string') {
      label =
        get(value, [0, 'value', 0, 'formatted_value']) ||
        get(value, [0, 'formatted_value']);
    }
    const biggerText =
      headerLabel?.length > label?.length ? headerLabel : label;
    width = Math.max(
      width,
      Math.floor(getWidthOfText(biggerText, '12px BasierSquare')) + 32
    );
  });
  return width;
};

export const getWidthObject = (
  data: (AllMetricRollupData | SheetData)[],
  headers: HeaderKey[]
): WidthObject => {
  const tempWidthObject: WidthObject = {};
  headers?.forEach(header => {
    const maxWidth = findMaxWidth(header.key, header.label, data);
    tempWidthObject[header.key] = Math.max(maxWidth, MIN_COLUMN_WIDTH);
  });
  return tempWidthObject;
};

/**
 * Transforms a file path by changing "/" to " / " and removing the first "/".
 * @param {string} filePath The original file path.
 * @return {string} The transformed file path.
 */
export const transformFilePath = (filePath: string): string => {
  // Check if filePath is not empty or undefined
  if (!filePath) {
    return '';
  }
  // Remove the first "/" if it exists and replace all "/" with "/"
  return filePath.replace(/^\//, '').replace(/\//g, ` / `);
};
