import { flexbox } from 'styled-system';
import { FlexboxProps } from '../../../props';
import cleanComponentProps from '../../../utils/cleanComponentProps';
// Tuxedo imports
import Box, { BoxProps } from '../box';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import themeGet from '../../../utils/theme-get';
import { css } from 'styled-components';
import { Spacings } from 'theme-v2/types';

export interface FlexBoxProps extends BoxProps, FlexboxProps {
  inline?: boolean;
  gap?: keyof Spacings;
  rowGap?: keyof Spacings;
  colGap?: keyof Spacings;
}

const FlexBox = cleanComponentProps<FlexBoxProps>(Box, [
  'inline',
  'gap',
  'rowGap',
  'colGap',
  ...(flexbox.propNames ? flexbox.propNames : []),
  'theme'
])`
  ${flexbox}
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${themeGet(`space.${gap}`)}px;
    `}
  ${({ rowGap }) =>
    rowGap &&
    css`
      row-gap: ${themeGet(`space.${rowGap}`)}px;
    `}
  ${({ colGap }) =>
    colGap &&
    css`
      column-gap: ${themeGet(`space.${colGap}`)}px;
    `}
`;

FlexBox.displayName = 'FlexBox';

export default WithV2Theme(FlexBox);
