import React from 'react';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const HoneybeeTextInput = ({ value, onChange }: Props): JSX.Element => {
  return (
    <input type='hidden' name='honeybee' value={value} onChange={onChange} />
  );
};

export default HoneybeeTextInput;
