import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      // We don't support retry by default. If you want, you can enable on query level
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

export default queryClient;
