import { IDProvider } from '../types';

import { IDP_LIST } from '../constants';

interface Return {
  isLoading: boolean;
  isError: boolean;
  idpList?: IDProvider[];
}

export const useIdpList = (): Return => {
  return {
    isError: false,
    isLoading: false,
    idpList: IDP_LIST
  };
};
