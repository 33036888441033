import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { Body, ButtonV2, FlexBox, Box } from 'tuxedo-v2';
import {
  useInitAuthorization,
  usePasswordAccept,
  useResetPassword,
  useHoneypot
} from 'shared/modules/auth-module/hooks';
import TextInput from 'shared/components/password-checker/TextInput';
import PasswordCheckerComponent from 'shared/components/password-checker';
import { InputRef } from 'tuxedo-v2/components/components/input/types';
import RedirectComponent from '../../components/RedirectComponent';
import LoginViewWrapper from '../LoginViewWrapper';
import StatusCard from '../../components/StatusCard';
import HoneybeeTextInput from '../../components/HoneybeeTextInput';
import { StyledHeaderText } from '../../styled';
import { getUiAutomationIdentifierClass } from '../../utils';

interface Props {
  location: {
    search: string;
  };
  [key: string]: unknown;
}

function ResetPasswordView(props: Props): JSX.Element {
  const passwordInputRef: React.RefObject<InputRef> = useRef(null);
  const signInNowBtnRef: React.MutableRefObject<null | HTMLButtonElement> =
    useRef(null);

  const { data } = useVisitorData();
  const [honeybee, setHoneyBee] = useHoneypot();

  const [
    initAuthorization,
    isInitAuthorizationLoading,
    isInitAuthorizationError
  ] = useInitAuthorization();

  const {
    isError,
    handlePasswordAccept,
    isLoading: isPasswordAcceptLoading,
    passwordAcceptResponse
  } = usePasswordAccept();

  const {
    password1,
    password2,
    setPassword1,
    setPassword2,
    isPasswordResetStatusShown,
    isErrorPassword1,
    isErrorPassword2,
    errorText1,
    errorText2,
    isLoading,
    handleResetPassword,
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar,
    showPasswordChecker,
    isButtonDisabled,
    isInvalidUrl,
    defaultMessage,
    defaultSubText
  } = useResetPassword();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (passwordInputRef && passwordInputRef.current) {
      passwordInputRef.current?.focusInput();
    }
  }, [isInitAuthorizationLoading, isPasswordAcceptLoading, isError]);

  useEffect(() => {
    if (data?.visitorId) {
      initAuthorization(data?.visitorId);
    }
  }, [data]);

  useEffect(() => {
    const { invitation_token, email, link_type } = queryString.parse(
      props.location.search
    );
    if (data?.visitorId && !isInitAuthorizationLoading) {
      const requestBody = {
        invitation_token,
        email,
        link_type
      };
      handlePasswordAccept(requestBody);
    }
  }, [data, isInitAuthorizationLoading]);

  useEffect(() => {
    if (!isInitAuthorizationLoading && isInitAuthorizationError) {
      window.location.href = '/login?error=access';
    }
    if (isInvalidUrl || isError) {
      window.location.href = '/login?error=broken';
    }
  }, [
    initAuthorization,
    isInitAuthorizationLoading,
    isInitAuthorizationError,
    isInvalidUrl,
    isError
  ]);

  useEffect(() => {
    if (
      isPasswordResetStatusShown &&
      signInNowBtnRef &&
      signInNowBtnRef.current
    ) {
      signInNowBtnRef.current?.focus();
    }
  }, [isPasswordResetStatusShown]);

  useEffect(() => {
    if (passwordAcceptResponse && isError === false) {
      setIsPageLoading(false);
    }
  }, [passwordAcceptResponse, isError]);

  const resetPasswordHandler = () => {
    if (!honeybee && !isButtonDisabled) {
      const { invitation_token, email, link_type } = queryString.parse(
        props.location.search
      );
      const requestBody = {
        invitation_token,
        email,
        password: password1,
        password_confirmation: password2,
        link_type
      };
      handleResetPassword(requestBody);
    }
  };

  const handleRedirection = () => {
    window.location.href = '/login';
  };

  if (isPageLoading) {
    return <RedirectComponent />;
  }

  return (
    <LoginViewWrapper>
      <StyledHeaderText>Reset Password</StyledHeaderText>
      {isPasswordResetStatusShown ? (
        <FlexBox flexDirection='column'>
          <StatusCard
            type='success'
            label={defaultMessage}
            subLabel={defaultSubText}
            mb={6}
          />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              onClick={handleRedirection}
              text='Sign In Now'
              tabIndex={4}
              className={getUiAutomationIdentifierClass(
                'forgot-password',
                'Sign in now button'
              )}
              ref={signInNowBtnRef}
            />
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox flexDirection='column'>
          <Body size='large' weight='medium'>
            Create new password
          </Body>
          <Box mt={1}>
            <Body weight='regular' color='text2'>
              Set up a new password for your account
            </Body>
          </Box>

          <TextInput
            value={password1}
            onChange={setPassword1}
            inputHeader='CREATE PASSWORD'
            placeholder='New password'
            password
            type='password'
            isError={isErrorPassword1}
            errorText={errorText1}
            ref={passwordInputRef}
            tabIndex={1}
            onEnter={resetPasswordHandler}
          />
          {showPasswordChecker && (
            <PasswordCheckerComponent
              isMinimumChar={isMinimumChar}
              isUpperChar={isUpperChar}
              isLowerChar={isLowerChar}
              isNumericChar={isNumericChar}
              isSpecialChar={isSpecialChar}
            />
          )}
          <TextInput
            value={password2}
            onChange={setPassword2}
            inputHeader='CONFIRM PASSWORD'
            placeholder='Confirm new password'
            password
            type='password'
            isError={isErrorPassword2}
            errorText={errorText2}
            tabIndex={2}
            onEnter={resetPasswordHandler}
          />
          <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              isLoading={isLoading}
              onClick={resetPasswordHandler}
              text='Set Password'
              tabIndex={3}
              className={getUiAutomationIdentifierClass(
                'forgot-password',
                'Password reset button'
              )}
              ref={signInNowBtnRef}
            />
          </FlexBox>
        </FlexBox>
      )}
    </LoginViewWrapper>
  );
}

export default ResetPasswordView;
