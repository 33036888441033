import { useModuleState } from '../module-state';

export const usePasswordChecker = (): {
  isMinimumChar: boolean;
  isUpperChar: boolean;
  isLowerChar: boolean;
  isNumericChar: boolean;
  isSpecialChar: boolean;
} => {
  const [moduleState] = useModuleState();
  const password1 = moduleState['password1'];

  const isMinimumChar = /.{8,}/.test(password1);
  const isUpperChar = /[A-Z]/.test(password1);
  const isLowerChar = /[a-z]/.test(password1);
  const isNumericChar = /[0-9]/.test(password1);
  const isSpecialChar = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password1);

  return {
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar
  };
};
