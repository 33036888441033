import React from 'react';
import { SelectBoxProps } from './types';
import classNames from 'classnames';
import { StyledPlaceHolder } from './styled';
import { BoxProps } from '../../atoms/box';

// dummy rc input html to mimic the style and structure
const SelectBox = ({
  className,
  style,
  value,
  placeholder,
  disabled,
  addonBefore,
  addonAfter,
  prefix,
  suffix,
  onChange
}: SelectBoxProps & Pick<BoxProps, 'onFocus' | 'onBlur'>): JSX.Element => {
  const handleClick = () => {
    if (!disabled) {
      onChange &&
        onChange({
          target: { value }
        } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  return (
    <span
      className={classNames(className, 'rc-input-group-wrapper')}
      style={style}
    >
      <span className='rc-input-wrapper rc-input-group'>
        {addonBefore && (
          <span className='rc-input-group-addon'>{addonBefore}</span>
        )}
        <span
          className={classNames(
            'rc-input-affix-wrapper',
            disabled && 'rc-input-affix-wrapper-disabled'
          )}
        >
          {prefix && <span className='rc-input-prefix'>{prefix}</span>}
          {value ? (
            <span
              className={classNames(
                'rc-input',
                disabled && 'rc-input-disabled'
              )}
              onClick={handleClick}
            >
              {value}
            </span>
          ) : (
            <StyledPlaceHolder> {placeholder}</StyledPlaceHolder>
          )}

          {suffix && <span className='rc-input-suffix'>{suffix}</span>}
        </span>
        {addonAfter && (
          <span className='rc-input-group-addon'>{addonAfter}</span>
        )}
      </span>
    </span>
  );
};

export default SelectBox;
