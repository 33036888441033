import userAnalytics from 'shared/services/analytics';
import { EventTrackingParams, TrackableEvent } from '../types/event-data';
import useEventContext from './use-event-context';
import commonEventParams from '../commonParams';
import { getTrackEventFlag } from 'shared/services/trackEvent';

type EventTracker = (
  eventName: typeof TrackableEvent,
  additionalParams?: EventTrackingParams,
  excludeParams?: string[]
) => void;

/**
 * Returns a function that can be called to track an event. With the returned `trackEvent`
 * function is called, all the event tracking params in EventContext (added using withEventContext hooks)
 * will automatically be included
 *
 * Since this function is a react hook, it should only be called at top level in a function component
 *
 * @param eventName            Name of the event
 * @param extraTrackingParams  Any extra tracking params that need to be added specific to this event
 * @param excludeParams        List of params that need to be removed from final event object
 * @returns A function that can be called to track and event
 */
export default function useEventTracker(legacy?: boolean): [EventTracker] {
  // Get current event tracking params
  const eventContext = useEventContext();

  const trackEvent = function (
    event = TrackableEvent,
    additionalParams?: EventTrackingParams,
    excludeParams?: string[]
  ): void {
    const trackEventFlag = getTrackEventFlag();
    if (!trackEventFlag) return;

    if (!event.NAME) {
      throw new Error('Event name is required');
    }

    // Merge with extra tracking params if passed
    const mergedContext = additionalParams
      ? eventContext.merge(additionalParams)
      : eventContext;

    const trackingParams: Json = {};
    // Final tracking params to be sent
    const finalTrackingParams = mergedContext.excludeEventParams(excludeParams);

    // Validate if all required params were passed
    for (const key in event.trackingParams) {
      if (
        !Object.prototype.hasOwnProperty.call(
          finalTrackingParams.trackingParams,
          key
        )
      ) {
        console.warn(`${key} missing in event tracking params`);
      } else {
        trackingParams[key] = mergedContext.trackingParams[key];
      }
    }

    for (const key of commonEventParams) {
      if (
        Object.prototype.hasOwnProperty.call(mergedContext.trackingParams, key)
      )
        trackingParams[key] = mergedContext.trackingParams[key];
    }

    userAnalytics(legacy).track(
      event.NAME,

      excludeParams && excludeParams.length
        ? finalTrackingParams.getFinalTrackingParams()
        : mergedContext.getFinalTrackingParams(trackingParams)
    );
  };

  return [trackEvent];
}
