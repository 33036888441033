export default function hasValue<T = unknown>(
  val: T
): val is Exclude<T, undefined | null> {
  if (
    val === null ||
    val === undefined ||
    (!val && !Number.isFinite(val) && typeof val !== 'boolean')
  ) {
    return false;
  }

  return true;
}
