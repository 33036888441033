import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface ResetPasswordRequest {
  invitation_token: string | string[] | null;
  email: string | string[] | null;
  password: string | string[] | null;
  password_confirmation: string | string[] | null;
  code?: string | string[] | null;
  link_type?: string | string[] | null;
}

export interface ResetPasswordResponse {
  data: {
    message: string;
    errors: string;
  };
  errors: string;
  invalid_url?: boolean;
}

const service = 'auth/passwords';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useResetPasswordApi(): UseMutationResult<ResetPasswordResponse> {
  return useMutation((requestBody: ResetPasswordRequest) => {
    return api.put<ResetPasswordResponse>(serverURL, requestBody);
  });
}
