import React, { useEffect, useRef } from 'react';
import { ButtonV2, FlatButton } from 'tuxedo-v2';
import { FlexBox } from 'tuxedo-v2';
import TextInput from 'shared/components/password-checker/TextInput';
import { useSsoLogin, useHoneypot } from 'shared/modules/auth-module/hooks';

import StatusCard from './StatusCard';
import HoneybeeTextInput from './HoneybeeTextInput';
import { StyledHeaderText } from '../styled';
import { IDProvider } from 'shared/modules/auth-module/types';
import { getUiAutomationIdentifierClass } from '../utils';
import { APP_NAME } from '../constants';
import { InputRef } from 'tuxedo-v2/components/components/input/types';

interface Props {
  handleTransition: (arg: IDProvider | null) => void;
  provider: string;
  providerName: string;
}

function SSOLoginForm({
  handleTransition,
  provider,
  providerName
}: Props): JSX.Element {
  const emailInput: React.RefObject<InputRef> = useRef(null);

  const ssoLoginRedirectCallback = (auth_url: string) => {
    window.location.href = auth_url;
  };

  const {
    email,
    setEmail,
    errorTextEmail,
    isInputError,
    isLoading,
    handleSsoLogin,
    isError,
    statusLabel,
    statusSubLabel
  } = useSsoLogin(ssoLoginRedirectCallback);
  const [honeybee, setHoneyBee] = useHoneypot();

  const handleLogin = () => {
    if (!honeybee) {
      handleSsoLogin({ provider: provider.toLocaleLowerCase() });
    }
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    const activeElement = document.activeElement as HTMLElement;
    const currentTabIndex = activeElement.tabIndex;
    if (event.key === 'Enter') {
      event.preventDefault();
      switch (currentTabIndex) {
        case 2:
          handleLogin();
          return;
        case 3:
          handleTransition(null);
          return;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current?.focusInput();
    }
  }, []);

  return (
    <FlexBox flexDirection='column'>
      <StyledHeaderText>Sign in to {APP_NAME}</StyledHeaderText>
      {isError && (
        <StatusCard
          type='error'
          label={statusLabel}
          subLabel={statusSubLabel}
          mb={6}
          addSupport
        />
      )}
      <TextInput
        value={email}
        onChange={setEmail}
        inputHeader='EMAIL'
        placeholder='Enter Email'
        mt={0}
        type='email'
        isError={isInputError}
        errorText={errorTextEmail}
        ref={emailInput}
        tabIndex={1}
        onEnter={handleLogin}
      />
      <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
      <FlexBox mt={6} flexDirection='column'>
        <ButtonV2
          isLoading={isLoading}
          onClick={handleLogin}
          text={`Continue with ${providerName}`}
          tabIndex={2}
          className={getUiAutomationIdentifierClass('sso-form', 'Button')}
        />
      </FlexBox>
      <FlexBox justifyContent='center' mt={8}>
        <FlexBox
          onClick={() => handleTransition(null)}
          alignItems='center'
          className={getUiAutomationIdentifierClass('sso-form', 'Back button')}
          tabIndex={3}
          onKeyDown={keyDownHandler}
        >
          <FlatButton
            text='See other sign-in options'
            inline
            leftIcon='icon-arrowleftlined'
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default SSOLoginForm;
