import React from 'react';
import { useTheme } from 'styled-components';
import { Theme } from 'theme/types';
import { Theme as ThemeV2 } from 'theme-v2/types';

export function WithV2Theme<T, R = HTMLElement>(
  Component: React.ComponentType<T & { theme?: ThemeV2 }>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<T & { theme?: ThemeV2 }> & React.RefAttributes<R>
> {
  return React.forwardRef<R, T & { theme?: ThemeV2 }>((props, ref) => {
    const theme = useTheme() as Theme & { v2: ThemeV2 };
    return <Component {...props} theme={theme.v2} ref={ref} />;
  });
}
