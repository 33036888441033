import React, { useEffect, useRef } from 'react';
import { ButtonV2, FlexBox, Body, Box, FlatButton } from 'tuxedo-v2';
import { useMagicLink, useHoneypot } from 'shared/modules/auth-module/hooks';
import TextInput from 'shared/components/password-checker/TextInput';
import { InputRef } from 'tuxedo-v2/components/components/input/types';
import StatusCard from './StatusCard';
import HoneybeeTextInput from './HoneybeeTextInput';
import { StyledHeaderText, StyledMagicLinkContainer } from '../styled';
import { getUiAutomationIdentifierClass } from '../utils';
import { APP_NAME } from '../constants';

interface Props {
  handleTransition: () => void;
}

function MagicLinkForm({ handleTransition }: Props): JSX.Element {
  const emailInput: React.RefObject<InputRef> = useRef(null);

  const {
    email,
    setEmail,
    errorTextEmail,
    isLoading,
    handleSendMagicLink,
    isError,
    isWarning,
    statusLabel,
    statusSubLabel,
    responseMessage,
    responseSubMessage
  } = useMagicLink();
  const [honeybee, setHoneyBee] = useHoneypot();

  const magicLinkSendHandler = () => {
    if (!honeybee) {
      handleSendMagicLink();
    }
  };

  useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current?.focusInput();
    }
  }, []);

  return (
    <FlexBox flexDirection='column'>
      <StyledHeaderText>Sign in to {APP_NAME}</StyledHeaderText>
      {responseMessage ? (
        <FlexBox flexDirection='column' width='400px' p={0}>
          <Body size='large' weight='medium' color='text1'>
            {responseMessage}
          </Body>
          <Box mt={2}>
            <Body weight='regular' color='text2'>
              {responseSubMessage}
            </Body>
          </Box>

          {isWarning && (
            <StatusCard
              mt={4}
              type={isError ? 'error' : 'warning'}
              label={statusLabel}
              subLabel={statusSubLabel}
            />
          )}

          {isError ? (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam.
              </Body>
            </Box>
          ) : (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam or{' '}
                {isLoading ? (
                  'resend link'
                ) : (
                  <FlatButton
                    onClick={magicLinkSendHandler}
                    className={getUiAutomationIdentifierClass(
                      'magic-link',
                      'Resend button'
                    )}
                    text='resend link'
                    inline
                  />
                )}
              </Body>
            </Box>
          )}
          <StyledMagicLinkContainer>
            <Body weight='regular'>
              Alternatively, you can {''}
              <FlatButton
                onClick={handleTransition}
                className={getUiAutomationIdentifierClass(
                  'magic-link',
                  'Back button'
                )}
                tabIndex={5}
                text='sign in with password'
                inline
              />
            </Body>
          </StyledMagicLinkContainer>
        </FlexBox>
      ) : (
        <>
          <TextInput
            value={email}
            onChange={setEmail}
            inputHeader='EMAIL'
            placeholder='Enter Email'
            mt={0}
            type='email'
            isError={isError}
            errorText={errorTextEmail}
            ref={emailInput}
            tabIndex={1}
            onEnter={magicLinkSendHandler}
          />
          <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              onClick={magicLinkSendHandler}
              isLoading={isLoading}
              text='Continue'
              className={getUiAutomationIdentifierClass(
                'magic-link',
                'Send button'
              )}
              tabIndex={2}
            />
          </FlexBox>

          <StyledMagicLinkContainer>
            <Body weight='regular'>
              We’ll email you a magic link for an easy sign-in. Or{' '}
              <FlatButton
                onClick={handleTransition}
                className={getUiAutomationIdentifierClass(
                  'magic-link',
                  'Back button'
                )}
                tabIndex={3}
                text='sign in with password'
              />
            </Body>
          </StyledMagicLinkContainer>
        </>
      )}
    </FlexBox>
  );
}

export default MagicLinkForm;
