import { useModuleState } from '../module-state';
import { StatusCardType, ErrorStateType } from '../types';

interface Return {
  isRedirectError: boolean;
  statusConfig:
    | { type: StatusCardType; label: string; subLabel: string }
    | undefined;

  setRedirectError: (arg: string | null, provider: string | null) => void;
  resetErrorState: () => void;
  addSupport: boolean;
  type?: ErrorStateType;
}

const LOGIN_ERROR_STATE = [
  'login_session',
  'access',
  'broken',
  'internal',
  'down',
  'internal_sso'
];

export const useRedirectError = (): Return => {
  const [moduleState, setModuleState] = useModuleState();

  const setRedirectError = (
    value: string | null,
    provider: string | null
  ): void => {
    setModuleState({
      redirectError: value
    });
    if (provider && provider !== 'undefined') {
      setModuleState({ redirectErrorProvider: provider });
    }
  };

  const resetErrorState = () => {
    setModuleState({
      isOtpFormShown: false,
      touched: {
        email: false,
        password: false,
        mfaCode: false,
        password1: false,
        password2: false
      },
      redirectError: null,
      redirectErrorSsoProvider: null,
      isErrorForgotPassword: false,
      isWarningForgotPassword: false,
      statusLabelForgotPassword: '',
      statusSubLabelForgotPassword: '',
      responseMessageForgotPassword: '',
      responseSubMessageForgotPassword: '',
      isErrorMagicLink: false,
      isWarningMagicLink: false,
      statusLabelMagicLink: '',
      statusSubLabelMagicLink: '',
      responseMessageMagicLink: '',
      responseSubMessageMagicLink: '',
      statusLabelSignIn: '',
      statusSubLabelSignIn: '',
      isErrorSignIn: false,
      isWarningSignIn: false
    });
  };

  if (moduleState.redirectError === 'sso') {
    const provider = moduleState.redirectErrorProvider;
    if (provider === 'okta') {
      return {
        isRedirectError: true,
        statusConfig: {
          type: 'error',
          label: 'Authentication failed',
          subLabel:
            'Looks like you entered the wrong credentials or your organization hasn’t added your identity in Okta application. Please try again'
        },
        setRedirectError,
        resetErrorState,
        addSupport: true
      };
    }
    if (provider === 'saml') {
      return {
        isRedirectError: true,
        statusConfig: {
          type: 'error',
          label: 'Authentication failed',
          subLabel:
            'Looks like you entered the wrong credentials or your organization hasn’t added your identity in SAML application. Please try again'
        },
        setRedirectError,
        resetErrorState,
        addSupport: true
      };
    }
    if (provider === 'google') {
      return {
        isRedirectError: true,
        statusConfig: {
          type: 'error',
          label: 'Authentication failed',
          subLabel:
            'Looks like you entered the wrong credentials or your organization does not support login via Google. Please try again'
        },
        setRedirectError,
        resetErrorState,
        addSupport: true
      };
    }
    return {
      isRedirectError: true,
      statusConfig: {
        type: 'error',
        label: 'Authentication failed',
        subLabel:
          'Looks like you entered the wrong credentials or your organization does not support the login method. Please try again'
      },
      setRedirectError,
      resetErrorState,
      addSupport: true
    };
  } else if (moduleState.redirectError === 'internal_sso') {
    const provider = moduleState.redirectErrorProvider;
    if (provider === 'okta') {
      return {
        isRedirectError: true,
        statusConfig: {
          type: 'error',
          label: 'Authentication failed',
          subLabel:
            'Looks like we’re having trouble getting your secured identity from Okta. Please try again'
        },
        setRedirectError,
        resetErrorState,
        addSupport: true
      };
    }
    if (provider === 'google') {
      return {
        isRedirectError: true,
        statusConfig: {
          type: 'error',
          label: 'Authentication failed',
          subLabel:
            'Looks like we’re having trouble getting your secured identity from Google. Please try again'
        },
        setRedirectError,
        resetErrorState,
        addSupport: true
      };
    }
    return {
      isRedirectError: true,
      statusConfig: {
        type: 'error',
        label: 'Authentication failed',
        subLabel:
          'Looks like we’re having trouble getting your secured identity. Please try again'
      },
      setRedirectError,
      resetErrorState,
      addSupport: true
    };
  } else if (moduleState.redirectError === 'session') {
    return {
      isRedirectError: true,
      statusConfig: {
        type: 'warning',
        label: 'Your session has expired',
        subLabel:
          'You were signed out of your account. Please sign in again to continue using Synaptic.'
      },
      setRedirectError,
      resetErrorState,
      addSupport: false
    };
  } else if (
    moduleState.redirectError &&
    LOGIN_ERROR_STATE.includes(moduleState.redirectError)
  ) {
    return {
      isRedirectError: true,
      statusConfig: undefined,
      setRedirectError,
      resetErrorState,
      addSupport: false,
      type: moduleState.redirectError as ErrorStateType
    };
  }
  return {
    isRedirectError: false,
    statusConfig: undefined,
    setRedirectError,
    resetErrorState,
    addSupport: false
  };
};
