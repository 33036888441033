import { useEffect } from 'react';
import { getAuthDataFromStorage, isInternetAvailable } from 'shared/services';

import { usePasswordChecker } from './usePasswordChecker';
import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import { SetInput } from '../types';
import {
  useResetPasswordApi,
  ResetPasswordRequest,
  ResetPasswordResponse
} from 'shared/api/v5/auth/passwords/put';
import { useModuleState } from '../module-state';

interface Return {
  isLoading: boolean;
  resetPasswordResponse: ResetPasswordResponse | undefined;
  isError: boolean;
  password1: string;
  password2: string;
  isErrorInputPassword2: boolean;
  isPasswordResetStatusShown: boolean;
  errorTextInputPassword2: string;
  errorTextInputPassword1: string;
  isErrorPassword1: boolean;
  isErrorPassword2: boolean;
  errorText1: string;
  errorText2: string;
  setPassword1: SetInput;
  setPassword2: SetInput;
  setIsError: (arg: boolean) => void;
  handleResetPassword: (requestBody: ResetPasswordRequest) => void;
  isMinimumChar: boolean;
  isUpperChar: boolean;
  isLowerChar: boolean;
  isNumericChar: boolean;
  isSpecialChar: boolean;
  showPasswordChecker: boolean;
  isButtonDisabled: boolean;
  isInvalidUrl: boolean;
  defaultMessage: string;
  defaultSubText: string;
}

export const useResetPassword = (): Return => {
  const defaultMessage = `Password updated successfully!`;
  const defaultSubText = `You can now proceed to sign-in to your Synaptic account.`;

  const [
    {
      isErrorResetPassword: isError,
      isErrorInputPassword2ResetPassword: isErrorInputPassword2,
      errorTextInputPassword1ResetPassword: errorTextInputPassword1,
      errorTextInputPassword2ResetPassword: errorTextInputPassword2,
      isPasswordResetStatusShownResetPassword: isPasswordResetStatusShown,
      isInvalidUrlResetPassword: isInvalidUrl
    },
    setModuleState
  ] = useModuleState();

  const {
    mutate,
    data: resetPasswordResponse,
    isLoading
  } = useResetPasswordApi();

  const [password1, setPassword1, isValidPassword1, errorTextPassword1] =
    useInput('password1');
  const [
    password2,
    setPassword2,
    isValidPassword2,
    errorTextPassword2,
    touchedPassword2,
    setTouchedPassword2
  ] = useInput('password2');
  const {
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar
  } = usePasswordChecker();

  const { setIsOfflineErrorShown } = useInternetConnection();

  const setIsError = (arg: boolean) => {
    setModuleState({ isErrorResetPassword: arg });
  };

  const handleResetPassword = async (requestBody: ResetPasswordRequest) => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      setTouchedPassword2(true);
      setModuleState({ isInvalidUrlResetPassword: false });
      const { code } = await getAuthDataFromStorage(['code']);
      const validAction = password1 !== '' && password1 === password2;
      if (validAction) {
        mutate(
          { ...requestBody, code },
          {
            onSuccess: (response: ResetPasswordResponse) => {
              if (response?.data?.message && response?.data?.message !== '') {
                setModuleState({
                  isPasswordResetStatusShownResetPassword: true
                });
              } else if (response?.errors && response?.errors !== '') {
                const errorText = response?.errors;
                setModuleState({
                  isErrorResetPassword: true,
                  errorTextInputPassword1ResetPassword: errorText || ''
                });
              } else if (response?.invalid_url) {
                setModuleState({
                  isErrorResetPassword: true,
                  isInvalidUrlResetPassword: response?.invalid_url
                });
              }
            },
            onError: (error: { data: ResetPasswordResponse }) => {
              const { data: errorResponse } = error;
              const errorText = errorResponse?.errors;
              setModuleState({
                isErrorResetPassword: true,
                errorTextInputPassword1ResetPassword: errorText || ''
              });
              if (errorResponse?.invalid_url) {
                setModuleState({
                  isErrorResetPassword: true,
                  isInvalidUrlResetPassword: errorResponse?.invalid_url
                });
              }
            }
          }
        );
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  useEffect(() => {
    if (password1 && password2 && password1 !== password2) {
      setModuleState({
        isErrorInputPassword2ResetPassword: true,
        errorTextInputPassword2ResetPassword:
          'Passwords do not match. Try again.'
      });
    }
    if (password1 === password2) {
      setModuleState({
        isErrorInputPassword2ResetPassword: false,
        errorTextInputPassword2ResetPassword: ''
      });
    }
    setModuleState({
      errorTextInputPassword1ResetPassword: '',
      isErrorResetPassword: false
    });
    setTouchedPassword2(false);
  }, [password1, password2]);

  const showErrorPassword1 = !!password1 && !isValidPassword1;
  const showErrorPassword2 = !!password2 && !isValidPassword2;
  const showPasswordChecker = !!password1 && !showErrorPassword1;
  const isErrorPassword1 = showErrorPassword1 || isError;
  const isErrorPassword2 =
    showErrorPassword2 || (touchedPassword2 && isErrorInputPassword2);
  const errorText1 = errorTextPassword1 || errorTextInputPassword1;
  const errorText2 = errorTextPassword2 || errorTextInputPassword2;
  const isButtonDisabled =
    !password1 ||
    !password2 ||
    errorTextPassword1 !== '' ||
    errorTextPassword2 !== '' ||
    !isMinimumChar ||
    !isUpperChar ||
    !isLowerChar ||
    !isNumericChar ||
    !isSpecialChar;

  return {
    isLoading,
    resetPasswordResponse,
    isError,
    password1,
    password2,
    errorTextInputPassword1,
    errorTextInputPassword2,
    isErrorInputPassword2,
    isPasswordResetStatusShown,
    isErrorPassword1,
    isErrorPassword2,
    errorText1,
    errorText2,
    setPassword1,
    setPassword2,
    setIsError,
    handleResetPassword,
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar,
    showPasswordChecker,
    isButtonDisabled,
    isInvalidUrl,
    defaultMessage,
    defaultSubText
  };
};
