import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';

import LoginView from '../views/LoginView';
import InvitationAcceptView from '../views/InvitationAcceptView';
import ResetPasswordView from '../views/ResetPasswordView';
import AuthRedirectView from '../views/AuthRedirectView';
import ErrorPage from '../views/ErrorPage';
import api from 'shared/network/api';

import {
  ModuleStateContext,
  InitialState
} from 'shared/modules/auth-module/module-state';

const Routes = ({
  isExcelAddInInstance
}: {
  isExcelAddInInstance: boolean;
}): JSX.Element => {
  const [moduleState, setModuleState] = useState({
    ...InitialState,
    isExcelAddInInstance
  });

  useEffect(() => {
    api.registerInterceptor(response => {
      if (response.status === 500) {
        window.location.href = '/login?error=internal';
      }
      if (response.status === 504) {
        window.location.href = '/login?error=down';
      }
      return response;
    });
  }, []);

  return (
    <ModuleStateContext.Provider
      value={{ state: moduleState, setState: setModuleState }}
    >
      <Switch>
        <Route exact path='/' component={() => <Redirect to='/login' />} />
        <Route exact path='/login' component={LoginView} />
        <Route exact path='/reset-password' component={ResetPasswordView} />
        <Route
          exact
          path='/invitation/accept'
          component={InvitationAcceptView}
        />
        <Route exact path='/auth-redirect' component={AuthRedirectView} />
        <Route exact path='/*' component={ErrorPage} />
      </Switch>
    </ModuleStateContext.Provider>
  );
};

export default Routes;
