import React from 'react';
import { EventData } from './types/event-data';

/**
 * This is single event tracking context we use throughout application
 */
const EventTrackingContext = React.createContext<EventData>(new EventData());

EventTrackingContext.displayName = 'EventTrackingContext';

export default EventTrackingContext;
