import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import {
  useInitAuthorization,
  useValidateMagicLink,
  useGenerateToken,
  useIsExcelAddInInstance
} from 'shared/modules/auth-module/hooks';

import RedirectComponent from '../../components/RedirectComponent';
import { Window } from 'shared/types';
import { excelPluginCommunication } from 'shared/modules/auth-module/utils/excel-addin';

interface Props {
  location: {
    search: string;
  };
  [key: string]: unknown;
}

declare const window: Window;

const AuthRedirectView = (props: Props): JSX.Element => {
  const { data } = useVisitorData();
  const [
    initAuthorization,
    isInitAuthorizationLoading,
    isInitAuthorizationError
  ] = useInitAuthorization();
  const { isExcelAddInInstance } = useIsExcelAddInInstance();
  const {
    isLoading,
    isError: isMagicLinkError,
    handleValidateMagicLink
  } = useValidateMagicLink();
  const { handleGenerateToken, isError } = useGenerateToken(
    true,
    isExcelAddInInstance ? excelPluginCommunication : undefined
  );

  useEffect(() => {
    if (data?.visitorId) {
      initAuthorization(data?.visitorId);
    }
  }, [data]);

  useEffect(() => {
    const { invitation_token, uuid, link_type, authorization_code } =
      queryString.parse(props.location.search);
    if (data?.visitorId && !isInitAuthorizationLoading) {
      if (link_type === 'magic_link') {
        handleValidateMagicLink({
          uuid: String(uuid),
          invitation_token: String(invitation_token)
        });
      }
      if (link_type === 'sso') {
        handleGenerateToken({
          authorization_code: String(authorization_code),
          signInMedium: 'sso'
        });
      }
    }
  }, [data, isInitAuthorizationLoading]);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: true });
      window.Intercom('hide');
    }
  }, [
    data,
    isInitAuthorizationLoading,
    isInitAuthorizationError,
    isMagicLinkError,
    isLoading,
    isError
  ]);

  useEffect(() => {
    if (!isInitAuthorizationLoading && isInitAuthorizationError) {
      window.location.href = '/login?error=access';
    }
  }, [isInitAuthorizationLoading, isInitAuthorizationError]);

  useEffect(() => {
    if (isMagicLinkError) {
      window.location.href = '/login?error=broken';
    }
    if (isError) {
      window.location.href = '/login?error=login_session';
    }
  }, [isMagicLinkError, isLoading, isError]);

  return <RedirectComponent />;
};

export default AuthRedirectView;
