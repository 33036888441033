import { useModuleState } from '../module-state';
import { SetInputValue, SetInput } from '../types';

export const useHoneypot = (): [string, SetInput] => {
  const [{ honeyBee }, setModuleState] = useModuleState();

  const handleSetInput = (e: SetInputValue) => {
    let inputValue = '';
    if (typeof e === 'string') {
      inputValue = e;
    } else {
      const { value } = e.target;
      inputValue = value;
    }
    setModuleState({ honeyBee: inputValue });
  };

  return [honeyBee, handleSetInput];
};
