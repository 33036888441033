export const FONT_SIZE_75 = '12px';
export const FONT_SIZE_100 = '14px';
export const FONT_SIZE_200 = '16px';
export const FONT_SIZE_300 = '18px';
export const FONT_SIZE_400 = '20px';
export const FONT_SIZE_500 = '24px';
export const FONT_SIZE_600 = '32px';

export const LINE_HEIGHT_50 = '16px';
export const LINE_HEIGHT_100 = '20px';
export const LINE_HEIGHT_200 = '24px';
export const LINE_HEIGHT_300 = '28px';
export const LINE_HEIGHT_500 = '32px';
export const LINE_HEIGHT_700 = '44px';

export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_SEMI_BOLD = 600;
export const FONT_WEIGHT_BOLD = 700;
