import { useModuleState } from '../module-state';

export const useInternetConnection = (): {
  isOfflineErrorShown: boolean;
  setIsOfflineErrorShown: (isOffline: boolean) => void;
} => {
  const [moduleState, setModuleState] = useModuleState();

  const setIsOfflineErrorShown = (isOffline: boolean): void => {
    setModuleState({
      isOffline
    });
  };

  const isOfflineErrorShown = moduleState['isOffline'];

  return { isOfflineErrorShown, setIsOfflineErrorShown };
};
