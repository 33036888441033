import React from 'react';

import { CSSProperties } from 'styled-components';
import { useThemeMode } from 'theme-v2/provider/index';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';

import FontIcon from '../../atoms/font-icon';
import Text from '../../atoms/text';

import { sizeProps } from './constants';
import { ButtonBase } from './styled';
import { ButtonBaseStyleProps, ButtonProps, IconState } from './types';

const Button = ({
  color = 'brand',
  leftIcon,
  iconColor,
  isDisabled = false,
  isLoading = false,
  isPressed = false,
  onClick,
  onMouseDown,
  rightIcon,
  size = 'medium',
  text,
  variant = 'primary',
  tabIndex,
  className
}: ButtonProps): JSX.Element => {
  const [themeMode] = useThemeMode();

  const iconState: IconState =
    leftIcon && text
      ? 'left'
      : rightIcon && text
      ? 'right'
      : text
      ? 'none'
      : 'only';

  const iconSize = sizeProps[size].iconSize;

  const disabled = isDisabled || isLoading;

  const buttonBaseStyleProps: ButtonBaseStyleProps = {
    color,
    iconState,
    isDisabled: disabled,
    isPressed,
    isLoading,
    themeMode,
    size,
    variant,
    tabIndex,
    className
  };

  const buttonContentStyle: CSSProperties | undefined = isLoading
    ? { visibility: 'hidden' }
    : undefined;

  return (
    <ButtonBase
      {...buttonBaseStyleProps}
      onClick={disabled ? undefined : onClick}
      onMouseDown={disabled ? undefined : onMouseDown}
    >
      {isLoading && (
        <FontIcon
          type='icon-spinnerlined'
          size={iconSize}
          spin
          style={{ position: 'absolute' }}
        />
      )}
      {leftIcon && (
        <FontIcon
          type={leftIcon}
          color={isDisabled ? 'icon3' : iconColor}
          size={iconSize}
          style={buttonContentStyle}
        />
      )}
      {text && (
        <Text fontWeight='medium' style={buttonContentStyle}>
          {text}
        </Text>
      )}
      {rightIcon && (
        <FontIcon
          type={rightIcon}
          color={isDisabled ? 'icon3' : iconColor}
          size={iconSize}
          style={buttonContentStyle}
        />
      )}
    </ButtonBase>
  );
};

export default WithV2Theme(Button);
