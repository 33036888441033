import React, { useEffect, useRef } from 'react';
import { ButtonV2, FlexBox, Body, Box, FlatButton } from 'tuxedo-v2';
import {
  useForgotPassword,
  useHoneypot
} from 'shared/modules/auth-module/hooks';
import TextInput from 'shared/components/password-checker/TextInput';

import HoneybeeTextInput from './HoneybeeTextInput';
import StatusCard from './StatusCard';
import { StyledHeaderText } from '../styled';
import { getUiAutomationIdentifierClass } from '../utils';
import { InputRef } from 'tuxedo-v2/components/components/input/types';
interface Props {
  handleTransition: () => void;
}

function ForgotPasswordForm({ handleTransition }: Props): JSX.Element {
  const emailInput: React.RefObject<InputRef> = useRef(null);

  const {
    email,
    errorTextEmail,
    isLoading,
    isError,
    setEmail,
    handleForgotPassword,
    isWarning,
    statusLabel,
    statusSubLabel,
    responseMessage,
    responseSubMessage
  } = useForgotPassword();
  const [honeybee, setHoneyBee] = useHoneypot();

  const forgotPasswordHandler = () => {
    if (!honeybee) {
      handleForgotPassword();
    }
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    const activeElement = document.activeElement as HTMLElement;
    const currentTabIndex = activeElement.tabIndex;
    if (event.key === 'Enter') {
      event.preventDefault();
      switch (currentTabIndex) {
        case 2:
          forgotPasswordHandler();
          return;
        case 3:
          handleTransition();
          return;
        case 4:
          forgotPasswordHandler();
          return;
        case 5:
          handleTransition();
          return;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current?.focusInput();
    }
  }, []);

  return (
    <FlexBox flexDirection='column'>
      <StyledHeaderText>Forgot Password</StyledHeaderText>
      {responseMessage ? (
        <FlexBox flexDirection='column' width='400px'>
          <Body size='large' weight='medium' color='text1'>
            {responseMessage}
          </Body>
          <Box mt={2}>
            <Body weight='regular' color='text2'>
              {responseSubMessage}
            </Body>
          </Box>
          {isWarning && (
            <StatusCard
              type={isError ? 'error' : 'warning'}
              label={statusLabel}
              subLabel={statusSubLabel}
              mt={4}
            />
          )}

          {isError ? (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam.
              </Body>
            </Box>
          ) : (
            <Box mt={4}>
              <Body weight='regular' color='text2'>
                Having trouble? Check your spam or{' '}
                {isLoading ? (
                  'resend link'
                ) : (
                  <FlatButton
                    onClick={forgotPasswordHandler}
                    text='resend link'
                    className={getUiAutomationIdentifierClass(
                      'forgot-password',
                      'Resend link'
                    )}
                    tabIndex={4}
                    inline
                  />
                )}
              </Body>
            </Box>
          )}

          <FlexBox justifyContent='center' mt={8}>
            <FlexBox
              onClick={handleTransition}
              alignItems='center'
              className={getUiAutomationIdentifierClass(
                'forgot-password',
                'Back button'
              )}
              tabIndex={5}
              onKeyDown={keyDownHandler}
            >
              <FlatButton
                text='Back to sign-in'
                inline
                leftIcon='icon-arrowleftlined'
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ) : (
        <>
          <TextInput
            value={email}
            onChange={setEmail}
            inputHeader='EMAIL'
            placeholder='Enter Email'
            mt={0}
            type='email'
            isError={isError}
            errorText={errorTextEmail}
            ref={emailInput}
            tabIndex={1}
            onEnter={forgotPasswordHandler}
          />
          <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              onClick={forgotPasswordHandler}
              isLoading={isLoading}
              text='Continue'
              tabIndex={2}
            />
          </FlexBox>
          <FlexBox justifyContent='center' mt={8}>
            <FlexBox
              onClick={handleTransition}
              alignItems='center'
              className={getUiAutomationIdentifierClass(
                'forgot-password',
                'Back button'
              )}
              tabIndex={3}
              onKeyDown={keyDownHandler}
            >
              <FlatButton
                text='Back to sign-in'
                inline
                leftIcon='icon-arrowleftlined'
              />
            </FlexBox>
          </FlexBox>
        </>
      )}
    </FlexBox>
  );
}

export default ForgotPasswordForm;
