import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { Body, Box, ButtonV2, FlexBox } from 'tuxedo-v2';
import PasswordCheckerComponent from 'shared/components/password-checker';
import TextInput from 'shared/components/password-checker/TextInput';
import {
  useInitAuthorization,
  useResetPasswordInvitation,
  useInvitationAccept,
  useHoneypot
} from 'shared/modules/auth-module/hooks';
import { handleLoginComplete } from 'shared/services';

import LoginViewWrapper from '../LoginViewWrapper';
import HoneybeeTextInput from '../../components/HoneybeeTextInput';
import StatusCard from '../../components/StatusCard';
import RedirectComponent from '../../components/RedirectComponent';
import { StyledHeaderText } from '../../styled';
import { getUiAutomationIdentifierClass } from '../../utils';
import { APP_NAME } from '../../constants';
import { InputRef } from 'tuxedo-v2/components/components/input/types';

interface Props {
  location: {
    search: string;
  };
  [key: string]: unknown;
}

function InvitationAcceptView(props: Props): JSX.Element {
  const passwordInputRef: React.RefObject<InputRef> = useRef(null);
  const beginOnboardingRef: React.MutableRefObject<null | HTMLButtonElement> =
    useRef(null);

  const { data } = useVisitorData();
  const [honeybee, setHoneyBee] = useHoneypot();
  const [
    initAuthorization,
    isInitAuthorizationLoading,
    isInitAuthorizationError
  ] = useInitAuthorization();
  const { isError, invitationAcceptResponse, handleInvitationAccept } =
    useInvitationAccept();

  const {
    isLoading,
    handleResetPassword,
    isLoginComplete,
    password1,
    password2,
    isErrorInputPassword1,
    isErrorInputPassword2,
    errorTextInputPassword1,
    errorTextInputPassword2,
    setPassword1,
    setPassword2,
    isMinimumChar,
    isUpperChar,
    isLowerChar,
    isNumericChar,
    isSpecialChar,
    showPasswordChecker,
    isButtonDisabled,
    defaultMessage,
    defaultSubText
  } = useResetPasswordInvitation();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string>('');

  const resetPasswordHandler = () => {
    if (!honeybee && !isButtonDisabled) {
      const { invitation_token, email, link_type } = queryString.parse(
        props.location.search
      );
      const requestBody = {
        invitation_token,
        email,
        password: password1,
        password_confirmation: password2,
        link_type
      };
      handleResetPassword(requestBody);
    }
  };

  useEffect(() => {
    if (data?.visitorId) {
      initAuthorization(data?.visitorId);
    }
  }, [data]);

  useEffect(() => {
    if (passwordInputRef && passwordInputRef.current) {
      passwordInputRef.current?.focusInput();
    }
  }, [isPageLoading]);

  useEffect(() => {
    const { invitation_token, email, link_type, provider, sso_enabled } =
      queryString.parse(props.location.search);
    setUsername(String(email));
    if (data?.visitorId && !isInitAuthorizationLoading) {
      const requestBody = {
        sso_enabled,
        invitation_token,
        email,
        link_type,
        provider
      };
      handleInvitationAccept(requestBody);
    }
  }, [data, isInitAuthorizationLoading]);

  useEffect(() => {
    if (!isInitAuthorizationLoading && isInitAuthorizationError) {
      window.location.href = '/login?error=access';
    }
  }, [initAuthorization, isInitAuthorizationLoading, isInitAuthorizationError]);

  useEffect(() => {
    if (isError) {
      window.location.href = '/login?error=broken';
    } else if (
      invitationAcceptResponse &&
      invitationAcceptResponse?.data?.sso_enabled === true &&
      invitationAcceptResponse?.data?.auth_url
    ) {
      window.location.href = invitationAcceptResponse?.data?.auth_url;
    } else if (
      invitationAcceptResponse &&
      invitationAcceptResponse?.data?.sso_enabled === false
    ) {
      setIsPageLoading(false);
    }
  }, [isError, invitationAcceptResponse]);

  useEffect(() => {
    if (isLoginComplete && beginOnboardingRef && beginOnboardingRef.current) {
      beginOnboardingRef.current?.focus();
    }
  }, [isLoginComplete]);

  const handleBeginOnboarding = () => {
    handleLoginComplete();
  };

  if (isPageLoading) {
    return <RedirectComponent />;
  }

  return (
    <LoginViewWrapper>
      <StyledHeaderText>Sign in to {APP_NAME}</StyledHeaderText>
      {isLoginComplete ? (
        <FlexBox flexDirection='column'>
          <StatusCard
            type='success'
            label={defaultMessage}
            subLabel={defaultSubText}
            mb={6}
          />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              onClick={handleBeginOnboarding}
              text='Begin Onboarding'
              tabIndex={4}
              className={getUiAutomationIdentifierClass(
                'invitation',
                'Begin onboarding button'
              )}
              ref={beginOnboardingRef}
            />
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox flexDirection='column'>
          <Body size='large' weight='medium'>
            Hello {username}, welcome to {APP_NAME}!
          </Body>
          <Box mt={1}>
            <Body weight='regular'>Create a password for your account.</Body>
          </Box>
          <TextInput
            value={password1}
            onChange={setPassword1}
            inputHeader='CREATE PASSWORD'
            placeholder='New password'
            password
            type='password'
            isError={isErrorInputPassword1}
            errorText={errorTextInputPassword1}
            ref={passwordInputRef}
            tabIndex={1}
            onEnter={resetPasswordHandler}
          />
          {showPasswordChecker && (
            <PasswordCheckerComponent
              isMinimumChar={isMinimumChar}
              isUpperChar={isUpperChar}
              isLowerChar={isLowerChar}
              isNumericChar={isNumericChar}
              isSpecialChar={isSpecialChar}
            />
          )}
          <TextInput
            value={password2}
            onChange={setPassword2}
            inputHeader='CONFIRM PASSWORD'
            placeholder='Confirm new password'
            password
            type='password'
            isError={isErrorInputPassword2}
            errorText={errorTextInputPassword2}
            tabIndex={2}
            onEnter={resetPasswordHandler}
          />
          <HoneybeeTextInput value={honeybee} onChange={setHoneyBee} />
          <FlexBox mt={6} flexDirection='column'>
            <ButtonV2
              isLoading={isLoading}
              isDisabled={isButtonDisabled}
              onClick={resetPasswordHandler}
              text='Set Password'
              tabIndex={3}
              className={getUiAutomationIdentifierClass(
                'invitation',
                'Password reset button'
              )}
            />
          </FlexBox>
        </FlexBox>
      )}
    </LoginViewWrapper>
  );
}

export default InvitationAcceptView;
