import { RefObject, useEffect, useRef, useState } from 'react';
import { InputProps, InputValue } from './types';
import hasValue from 'shared/utils/hasValue';

export const useInput = (
  initialValue?: InputValue,
  type?: InputProps['type']
): {
  inputValue: InputValue;
  setInputValue: (newValue?: InputValue) => void;
  clearInputValue: () => void;
} => {
  const [inputValue, setValue] = useState(() => {
    if (
      (type === 'number' && !isNaN(Number(initialValue))) ||
      type !== 'number'
    ) {
      return initialValue || '';
    }
    return '';
  });
  const setInputValue = (newValue?: InputValue) =>
    setValue(hasValue(newValue) ? newValue : '');
  const clearInputValue = () => setValue('');
  return { inputValue, setInputValue, clearInputValue };
};

export const useOutsideClickListener = (
  listener?: () => void
): { wrapperRef: RefObject<HTMLElement> } => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        listener
      ) {
        listener();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, listener]);

  return { wrapperRef: ref };
};
