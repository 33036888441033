import { Placement } from 'tippy.js';

function getOffset(placement: Placement): [number, number] | undefined {
  switch (placement) {
    case 'right-start':
      return [-8, 8];
    case 'right-end':
      return [0, 10];
    case 'left-start':
      return [-8, 10];
    case 'left-end':
      return [0, 10];
    case 'bottom':
      return [-3, 4];
    default:
      return undefined;
  }
}

export default getOffset;
