import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired,
  isInternetAvailable
} from 'shared/services';

import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import { useGenerateToken } from './useGenerateToken';
import {
  useSignInApi,
  SignInResponse
} from 'shared/api/v5/auth/sessions/sign_in/post';
import { SetInput } from '../types';

import { useModuleState } from 'shared/modules/auth-module/module-state';

interface Return {
  isLoading: boolean;
  signInResponse: SignInResponse | undefined;
  isOtpFormShown: boolean;
  isError: boolean;
  handleSignIn: () => void;
  isLoginComplete: boolean;
  email: string;
  setEmail: SetInput;
  isValidEmail: boolean;
  errorTextEmail: string;
  password: string;
  setPassword: SetInput;
  isValidPassword: boolean;
  errorTextPassword: string;
  isEmailInputError: boolean;
  isPasswordInputError: boolean;
  isWarning: boolean;
  statusLabel: string;
  statusSubLabel: string;
  hideErrorState: () => void;
}

export const useSignIn = (
  customRedirectionOnLoginComplete?: () => void
): Return => {
  const { mutate, data: signInResponse, isLoading } = useSignInApi();

  const {
    isLoading: isTokenLoading,
    handleGenerateToken,
    isLoginComplete,
    isError: isGenerateTokenError
  } = useGenerateToken(true, customRedirectionOnLoginComplete);

  const [
    email,
    setEmail,
    isValidEmail,
    errorTextEmail,
    touchedEmail,
    setTouchedEmail
  ] = useInput('email');
  const [
    password,
    setPassword,
    isValidPassword,
    errorTextPassword,
    touchedPassword,
    setTouchedPassword
  ] = useInput('password');
  const { setIsOfflineErrorShown } = useInternetConnection();

  const [
    {
      isOtpFormShown,
      statusLabelSignIn: statusLabel,
      statusSubLabelSignIn: statusSubLabel,
      isErrorSignIn: isError,
      isWarningSignIn: isWarning
    },
    setModuleState
  ] = useModuleState();

  const resetErrorState = () => {
    setModuleState({
      isWarningSignIn: false,
      isErrorSignIn: false
    });
    setTouchedEmail(true);
    setTouchedPassword(true);
    handleOtpFormShown(false);
  };

  const hideErrorState = () => {
    setModuleState({
      isGoogleLoginError: false,
      googleLoginErrorText: '',
      isWarningSignIn: false,
      isErrorSignIn: false,
      responseMessageMagicLink: '',
      responseMessageForgotPassword: ''
    });
    setTouchedEmail(false);
    setTouchedPassword(false);
  };

  const handleOtpFormShown = (arg: boolean) => {
    setModuleState({
      isOtpFormShown: arg
    });
  };

  const handleSignIn = async () => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      resetErrorState();
      const { code } = await getAuthDataFromStorage(['code']);
      if (isValidEmail && isValidPassword) {
        const requestBody = { email, password, code };
        mutate(requestBody, {
          onSuccess: (response: SignInResponse) => {
            if (response?.login_session_expired === true) {
              handleSessionExpired();
            } else {
              if (response?.data?.is_error) {
                setModuleState({ isErrorSignIn: true });
              }
              if (
                response?.data?.additional_messages?.attempts &&
                response?.data?.additional_messages?.attempts !== ''
              ) {
                setModuleState({
                  statusLabelSignIn:
                    response?.data?.additional_messages?.attempts || '',
                  statusSubLabelSignIn:
                    response?.data?.additional_messages?.subtext || '',
                  isWarningSignIn: true
                });
              }
              if (
                response?.meta?.code &&
                response?.meta?.synaptic_session_exp
              ) {
                setAuthDataToStorage({
                  code: response?.meta?.code,
                  synaptic_session_exp: response?.meta?.synaptic_session_exp
                });
              }
              if (
                response?.data?.resend_mfa?.message &&
                response?.data?.resend_mfa?.message !== ''
              ) {
                handleOtpFormShown(true);
              }
              if (response?.data?.authorization_code) {
                handleGenerateToken({
                  authorization_code: response?.data?.authorization_code,
                  signInMedium: 'password'
                });
              }
            }
          },
          onError: (error: { data: SignInResponse }) => {
            const { data: errorResponse } = error;
            if (errorResponse?.login_session_expired === true) {
              handleSessionExpired();
            } else {
              setModuleState({ isErrorSignIn: true });
              if (
                errorResponse?.meta?.code &&
                errorResponse?.meta?.synaptic_session_exp
              ) {
                setAuthDataToStorage({
                  code: errorResponse?.meta?.code,
                  synaptic_session_exp:
                    errorResponse?.meta?.synaptic_session_exp
                });
              }
            }
          }
        });
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  const isEmailInputError =
    touchedEmail && (isError || isWarning || !isValidEmail);
  const isPasswordInputError =
    touchedPassword && (isError || isWarning || !isValidPassword);

  return {
    isLoginComplete,
    isLoading: isLoading || isTokenLoading,
    signInResponse,
    isOtpFormShown,
    isError: isError || isGenerateTokenError,
    handleSignIn,
    email,
    setEmail,
    isValidEmail,
    errorTextEmail,
    password,
    setPassword,
    isValidPassword,
    errorTextPassword,
    isEmailInputError,
    isPasswordInputError,
    isWarning,
    statusLabel,
    statusSubLabel,
    hideErrorState
  };
};
