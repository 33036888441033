import { Window } from 'shared/types';

declare const window: Window;

export function getSessionId(): string | null {
  return window.sessionId;
}

export function setSessionId(sessionId: string | null): void {
  window.sessionId = sessionId;
}

export function getSessionIdFromStorage(): Promise<string | null> {
  return new Promise((resolve, reject): void => {
    try {
      const fromStorage = window.localStorage.getItem('authtoken');
      const sessionId =
        fromStorage === 'null' || fromStorage === 'undefined'
          ? null
          : fromStorage;
      // Store in global storage
      window.sessionId = sessionId;
      resolve(sessionId);
    } catch (e) {
      reject(e);
    }
  });
}

export function setSessionIdToStorage(sessionId: string | null): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      window.localStorage.setItem('authtoken', String(sessionId));
      window.sessionId = sessionId;
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
