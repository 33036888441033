import { FilterValue } from '../../types';
import { getAnalyticsSessionId } from 'shared/services/analytics-session-id';

export class TrackableEvent {
  static NAME: string = '';

  static trackingParams: Record<string, unknown> = {};
}

export interface EventTrackingParams {
  [key: string]: string | number | null | undefined | FilterValue | boolean;
}

export class EventData {
  /**
   * Path of the component at which event was triggered. A component is added to sourceHierarchy
   * only if it's exported using `withEventContext` HOC
   */
  sourceHierarchy: string = 'Root';

  /**
   * Name of the component where the event was triggered
   */
  sourceComponent: string = 'Root';

  /**
   * Tracking Params
   */
  trackingParams: EventTrackingParams = {};

  constructor(data?: Json) {
    if (data !== undefined) {
      this.sourceHierarchy = data.sourceHierarchy;
      this.sourceComponent = data.sourceComponent;

      Object.keys(data).forEach((key: string) => {
        if (key !== 'sourceHierarchy' && key !== 'sourceComponent') {
          this.trackingParams[key] = data[key];
        }
      });
    }
  }

  /**
   * Gets all tracking params that need to sent to event tracking library in form
   * of a Json Object
   * @returns JSON
   */
  getFinalTrackingParams(trackingParams?: Json): Json {
    return {
      ...(trackingParams || this.trackingParams),
      sourceHierarchy: this.sourceHierarchy,
      sourceComponent: this.sourceComponent,
      session_id: getAnalyticsSessionId()
    };
  }

  /**
   * Exclude params that are not required in final event object
   * @param  excludeParams:     string[]    List of params that need to be removed from final event object
   * @returns EventData
   */
  excludeEventParams(excludeParams?: string[]): EventData {
    const trackingParams = { ...this.trackingParams };
    if (excludeParams) {
      excludeParams.forEach(param => delete trackingParams[param]);
    }
    return new EventData({
      ...trackingParams
    });
  }

  /**
   * As we move deeper into the hierarchy, contexts get more refined.
   * At top level: User
   * On Feed Card: Feed id
   * On individual insight: Insight ID
   *
   * This function merges context received from higher level and passes
   * the combined one to lower hierarchy. For example,
   * Top Level: { userId: 1 }
   * Feed Card: { feedId: 12344, userId: 1 }
   * Insight Card: { insightId: 11212, feedId: 12344, userId: 1}
   *
   * @param  newParams:        EventTrackingParams          New Params to add to the tracking params of current object
   * @param  sourceComponent:  string                       Source component at this level, which will be added to sourceHierarchy
   * @return                   EventContext                 Combined context
   */
  merge(
    newParams: EventTrackingParams,
    sourceComponent: string | null = null
  ): EventData {
    const newSourceHierarchy = this.sourceHierarchy.split(' > ');
    let newSourceComponent =
      newSourceHierarchy.length > 0
        ? newSourceHierarchy[newSourceHierarchy.length - 1]
        : '';

    if (sourceComponent !== null) {
      newSourceHierarchy.push(sourceComponent);
      newSourceComponent = sourceComponent;
    }

    return new EventData({
      ...this.trackingParams,
      ...newParams,
      sourceHierarchy: newSourceHierarchy.join(' > '),
      sourceComponent: newSourceComponent
    });
  }
}
