export default {
  SUPPORT_EMAIL: 'support@synaptic.com',
  FEEDBACK_EMAIL_SUBJECT: 'Synaptic Feedback',
  APP_STORE_APP_ID: '1445655088', // Replace with actual app id,
  ONE_SIGNAL_KEY: 'bad18d20-1975-49d2-8c72-a5b685c1e7a8',
  // APPSEE_KEY: '89339a05731142ffa4cdf1d90184a7d0',
  ROHIT_EMAIL: 'rohit@synaptic.com',
  ADD_TO_UNIVERSE_EMAIL_SUBJECT: 'Demo account: add to universe request',
  PRIVACY_POLICY_URL: 'https://synaptic.com/privacy-policy',
  CONTACT_EMAIL: 'contact@synaptic.com'
};
