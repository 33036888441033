import React from 'react';
import themeGet from 'tuxedo-v2/utils/theme-get';
import styled from 'styled-components';

// Tuxedo imports
import FlexBox, { FlexBoxProps } from '../flexbox';
import { ThemeMode } from 'theme-v2/provider/index';

export interface ViewProps extends FlexBoxProps {
  variant?: 'dark' | undefined;
  themeMode: ThemeMode;
}

const View = styled<React.ComponentType<ViewProps>>(FlexBox)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: ${props =>
    props.color || themeGet(`colors.text.${props.themeMode}.text1`)}};
  background: ${props =>
    props.variant === 'dark'
      ? themeGet('colors.surface.light.surface2')(props)
      : 'transparent'};
`;

export default View;
