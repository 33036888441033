import { TrackableEvent } from 'shared/events';

export class NotificationMarkAllReadClickedEvent extends TrackableEvent {
  static NAME = 'Notification Mark All Read Clicked';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    total_unread_notification_count: ''
  };
}

export class UserSignedOutEvent extends TrackableEvent {
  static NAME = 'User Signed Out';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: ''
  };
}

export class UserSignedInEvent extends TrackableEvent {
  static NAME = 'User Signed In';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    sign_in_medium: ''
  };
}
export class UserSessionStartedEvent extends TrackableEvent {
  static NAME = 'User Session Started';

  static trackingParams = {};
}

export class UserSessionEndedEvent extends TrackableEvent {
  static NAME = 'User Session Ended';

  static trackingParams = {};
}

export class PageTabBookmarkedEvent extends TrackableEvent {
  static NAME = 'Page Tab Bookmarked';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    bookmarked_name: '',
    bookmarked_page_module: '',
    bookmarked_page_source: ''
  };
}

export class PageTabBookmarkedRemovedEvent extends TrackableEvent {
  static NAME = 'Page Tab Bookmarked Removed';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    bookmarked_name: '',
    bookmarked_page_module: '',
    bookmarked_page_source: ''
  };
}

export class PageTabClosedEvent extends TrackableEvent {
  static NAME = 'Page Tab Closed';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    page_module: '',
    page_source: '',
    page_tab_number: ''
  };
}

export class AllPageTabClosedEvent extends TrackableEvent {
  static NAME = 'All Page Tab Closed';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    page_module: '',
    page_source: '',
    total_page_tab_count: ''
  };
}

export class PageTabActivatedEvent extends TrackableEvent {
  static NAME = 'Page Tab Activated';

  static trackingParams = {
    event_module: '',
    event_source: '',
    event_medium: '',
    current_tab: '',
    previous_tab: ''
  };
}
