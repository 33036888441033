// import { IntrinsicAttributes } from 'react';
import {
  space,
  flex,
  flexBasis,
  layout,
  textAlign,
  width,
  minWidth,
  maxWidth,
  position
} from 'styled-system';
import {
  SpaceProps,
  FlexProps,
  FlexBasisProps,
  TextAlignProps,
  LayoutProps,
  WidthProps,
  MinWidthProps,
  MaxWidthProps,
  PositionProps
} from '../../../props';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import cleanComponentProps from '../../../utils/cleanComponentProps';
import * as CSS from 'csstype';
import { css } from 'styled-components';

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    SpaceProps,
    FlexProps,
    FlexBasisProps,
    LayoutProps,
    PositionProps,
    TextAlignProps,
    WidthProps,
    MinWidthProps,
    PositionProps,
    MaxWidthProps {
  _height?: CSS.Property.Height;
  _maxHeight?: CSS.Property.MaxHeight;
  _minHeight?: CSS.Property.MinHeight;
}

const BoxBase = cleanComponentProps<BoxProps, HTMLDivElement>('div', [
  ...(flex.propNames ? flex.propNames : []),
  ...(flexBasis.propNames ? flexBasis.propNames : []),
  ...(space.propNames ? space.propNames : []),
  ...(layout.propNames ? layout.propNames : []),
  ...(textAlign.propNames ? textAlign.propNames : []),
  ...(width.propNames ? width.propNames : []),
  ...(minWidth.propNames ? minWidth.propNames : []),
  ...(maxWidth.propNames ? maxWidth.propNames : []),
  ...(position.propNames ? position.propNames : []),
  '_height',
  '_maxHeight',
  '_minHeight',
  'theme'
])`
    ${space}
    ${flex}
    ${flexBasis}
    ${layout}
    ${textAlign}
    ${width}
    ${minWidth}
    ${position}

    ${({ _height }) =>
      _height &&
      css`
        height: ${_height};
      `}
    ${({ _minHeight }) =>
      _minHeight &&
      css`
        min-height: ${_minHeight};
      `}
    ${({ _maxHeight }) =>
      _maxHeight &&
      css`
        max-height: ${_maxHeight};
      `}
`;

BoxBase.displayName = 'Box';

export default WithV2Theme(BoxBase);
