import { ErrorStateType } from '../types';

interface Return {
  isGeneric: boolean;
  isBroken: boolean;
  isSession: boolean;
  isAccess: boolean;
  is500: boolean;
  is504: boolean;
  isOffline: boolean;
}

export function useErrorState(type?: ErrorStateType): Return {
  const isGeneric =
    !type ||
    ![
      'broken',
      'login_session',
      'access',
      'internal',
      'down',
      'offline'
    ].includes(type);
  const isBroken = !!(type && type === 'broken');
  const isSession = !!(type && type === 'login_session');
  const isAccess = !!(type && type === 'access');
  const is500 = !!(type && type === 'internal');
  const is504 = !!(type && type === 'down');
  const isOffline = !!(type && type === 'offline');

  return {
    isGeneric,
    isBroken,
    isSession,
    isAccess,
    is500,
    is504,
    isOffline
  };
}
