import APP_ENV from 'shared/network/build-env';
import { getServer } from 'shared/services/server';
import { PRODUCTION_URL } from 'shared/network/config';

let fpEndpoint = 'https://fp.nvst-staging.com';
let clientId =
  '733315021460beefdcb40de69409ab57a33af0471b6dfc74ebc2213b630152ea';

const SERVER = getServer();

if ((APP_ENV === 'production' && !SERVER) || SERVER === PRODUCTION_URL) {
  fpEndpoint = 'https://fp.synaptic.com';
  clientId = '1148d98c6ae90bbcca0c8baf9f0e556b6e82075b4f7456240ac1c6308d774748';
}

export const CLIENT_ID = clientId;
export const FPJS_ENDPOINT = fpEndpoint;
export const FIREBASE_WEB_CLIENT_ID =
  '896930683139-lrkfn816solau4j3m82ufjngeilt49ul.apps.googleusercontent.com';
