import { useModuleState } from '../module-state';

export const useIsExcelAddInInstance = (): {
  isExcelAddInInstance: boolean;
} => {
  const [{ isExcelAddInInstance }] = useModuleState();

  return {
    isExcelAddInInstance
  };
};
