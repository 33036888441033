import { useMutation, UseMutationResult } from 'react-query';
import api from 'shared/network/api';
import { API_URL, API_VERSION_V5 } from 'shared/network/config';

export interface InvitationAcceptRequestBody {
  invitation_token: string | string[] | null;
  email: string | string[] | null;
  code?: string | string[] | null;
  provider: string | string[] | null;
  link_type: string | string[] | null;
  sso_enabled: string | string[] | null;
}

export interface InvitationAcceptResponse {
  data: {
    sso_enabled: boolean;
    auth_url: string;
  };
}

const service = 'auth/invitations/accept';
const serverURL = `${API_URL}${API_VERSION_V5}${service}`;

export function useInvitationAcceptApi(): UseMutationResult<InvitationAcceptResponse> {
  return useMutation((requestBody: InvitationAcceptRequestBody) => {
    return api.get<InvitationAcceptResponse>(serverURL, requestBody);
  });
}
