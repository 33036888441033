import { Window } from 'shared/types';
import { SegmentAnalytics } from 'shared/types';

declare const window: Window;

export default function userAnalytics(legacy?: boolean): SegmentAnalytics {
  const emptyFns = {
    track: () => {
      return;
    },
    screen: () => {
      return;
    },
    identify: () => {
      return;
    },
    group: () => {
      return;
    },
    page: () => {
      return;
    }
  };

  // Test run is set to true for qa automation. Do not track usage data for qa automation scripts
  if (window.testrun || !window.analytics) {
    return emptyFns;
  }

  if (!legacy) {
    return window.analytics;
  } else {
    return emptyFns;
    // return window.analytics;
  }
}
