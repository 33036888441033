import React from 'react';
import { FlexBox, Img, Body, FontIcon } from 'tuxedo-v2';
import SynapticLogo from 'theme/assets/images/logomark-v2.png';

const RedirectComponent = (): JSX.Element => {
  return (
    <FlexBox
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      _height={'100vh'}
    >
      <Img src={SynapticLogo} width='40px' _height='40px' />
      <FlexBox alignItems='center' mt={6}>
        <FontIcon type='icon-spinnerlined' color='icon2' size={3} mr={2} spin />
        <Body weight='medium' size='large' color='text2'>
          Loading Synaptic
        </Body>
      </FlexBox>
    </FlexBox>
  );
};

export default RedirectComponent;
