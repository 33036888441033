import React from 'react';
import FontIcon from '../../atoms/font-icon';
import Flexbox from '../../atoms/flexbox';
import { StyledHorizontalLine } from './styled';
import { useThemeMode } from 'theme-v2/provider/index';
import { InputProps } from './types';
import { sizeProps } from './constant';

const NumberInputControl = ({
  inputSize,
  handleClick
}: {
  inputSize: InputProps['size'];
  handleClick: (type: 'up' | 'down') => void;
}): JSX.Element => {
  const [themeMode] = useThemeMode();

  const handleUpClick = () => handleClick('up');
  const handleDownClick = () => handleClick('down');

  return (
    <Flexbox flexDirection='column'>
      <FontIcon
        type='icon-chevronupfilled'
        color='icon2'
        size={sizeProps[inputSize].iconSize}
        onClick={handleUpClick}
        className='clickable'
      />
      <StyledHorizontalLine themeMode={themeMode} inputSize={inputSize} />
      <FontIcon
        type='icon-chevrondownfilled'
        color='icon2'
        size={sizeProps[inputSize].iconSize}
        onClick={handleDownClick}
        className='clickable'
      />
    </Flexbox>
  );
};

export default NumberInputControl;
