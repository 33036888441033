import React from 'react';
import getOffset from './utils/offset';
import { StyledTooltip, StyledTooltipContainer, Arrow } from './styled';
import Text from '../../../atoms/text';
import { Placement, Props } from 'tippy.js';

export interface TooltipProps
  extends Partial<
    Pick<Props, 'appendTo' | 'onMount' | 'interactive' | 'zIndex'>
  > {
  text?: string | number;
  disabled?: boolean;
  // type of placement comes from tippy.js
  placement: Placement;
  variant?: 'idle' | 'default';
  delay?: [number, number];
  children?: React.ReactElement;
  contentRenderer?: React.ReactNode;
  offset?: [number, number];
  offsetY?: number;
  maxWidth?: string;
}

interface RenderFunctionArgs {
  'data-placement': Placement;
}

export interface RenderTooltipProps {
  render: (arg: RenderFunctionArgs) => JSX.Element;
  delay: [number, number];
  offset?: [number, number];
}

const Tooltip = (props: TooltipProps): JSX.Element => {
  const { text, contentRenderer, offset, offsetY, variant, ...restProps } =
    props;

  const textContent = (
    <Text fontSize={1} fontWeight='medium'>
      {text}
    </Text>
  );

  const renderContent = (attrs: RenderFunctionArgs): JSX.Element => {
    return (
      <StyledTooltipContainer variant={variant}>
        <>
          <Arrow placement={attrs['data-placement']} />
          {text ? textContent : contentRenderer}
        </>
      </StyledTooltipContainer>
    );
  };

  const tooltipProps: RenderTooltipProps = {
    render: renderContent,
    delay: [0, 0]
  };

  if (
    (offset && offset.length === 2) ||
    ['right-start', 'right-end', 'left-start', 'left-end', 'bottom'].includes(
      restProps.placement
    )
  ) {
    const offsetProp =
      offset && offset.length === 2 ? offset : getOffset(restProps.placement);

    tooltipProps.offset = offsetProp;
  }

  return (
    <StyledTooltip {...tooltipProps} {...restProps}>
      {props.children}
    </StyledTooltip>
  );
};

Tooltip.defaultProps = {
  placement: 'right',
  variant: 'default'
};

export default Tooltip;
