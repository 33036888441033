import { ThemeMode } from 'theme-v2/provider/index';
import { InputProps } from './types';
import { IconColors, IconSizeKeys } from 'theme-v2/types';

export const sizeProps: {
  [key in InputProps['size']]: {
    height: string;
    borderRadius: string;
    fontSize: string;
    lineHeight: string;
    iconSize: IconSizeKeys;
    padding: string;
  };
} = {
  small: {
    height: 'space.8',
    borderRadius: 'radii.2',
    fontSize: 'fontSizes.1',
    lineHeight: 'longformLineHeights.1',
    iconSize: 1,
    padding: 'space.2'
  },
  medium: {
    height: 'space.10',
    borderRadius: 'radii.2',
    fontSize: 'fontSizes.1',
    lineHeight: 'longformLineHeights.1',
    iconSize: 2,
    padding: 'space.3'
  },
  large: {
    height: 'space.12',
    borderRadius: 'radii.2',
    fontSize: 'fontSizes.2',
    lineHeight: 'longformLineHeights.2',
    iconSize: 2,
    padding: 'space.4'
  }
};

export const themeModeProps: {
  [key in ThemeMode]: {
    borderColor: string;
    hoverBorderColor: string;
    focusedBorderColor: string;
    iconColor: keyof IconColors;
    errorBorderColor: string;
    searchBgColor: string;
    searchHoverBgColor: string;
  };
} = {
  light: {
    borderColor: 'colors.outline.light.outline2',
    hoverBorderColor: 'colors.brand.200',
    focusedBorderColor: 'colors.outline.light.brand',
    errorBorderColor: 'colors.outline.light.error',
    iconColor: 'icon1',
    searchBgColor: 'colors.surface.light.surface1',
    searchHoverBgColor: 'colors.cool-grey.50'
  },
  dark: {
    borderColor: 'colors.outline.dark.outline1',
    hoverBorderColor: 'colors.warm-grey.100',
    focusedBorderColor: 'colors.outline.dark.brand',
    errorBorderColor: 'colors.outline.dark.error',
    iconColor: 'icon1',
    searchBgColor: 'colors.surface.dark.surface2',
    searchHoverBgColor: 'colors.surface.dark.surface3'
  }
};

export const CHAR_LIMIT_ERROR_TEXT = 'Character limit exceeded';
