import {
  useValidateMagicLinkApi,
  SignInResponse
} from 'shared/api/v5/auth/magic_link/validate/get';
import { ValidateMagicLinkRequest } from '../types';

import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired
} from 'shared/services';
import { useGenerateToken } from './useGenerateToken';

interface Return {
  isLoading: boolean;
  validateMagicLinkResponse: SignInResponse | undefined;
  handleValidateMagicLink: ({
    uuid,
    invitation_token
  }: ValidateMagicLinkRequest) => void;
  isError: boolean;
  isLoginComplete: boolean;
  resetErrorState: () => void;
}

export const useValidateMagicLink = (): Return => {
  const {
    mutate,
    data: validateMagicLinkResponse,
    isLoading,
    isError
  } = useValidateMagicLinkApi();
  const {
    isError: isGenerateTokenError,
    isLoading: isTokenLoading,
    handleGenerateToken,
    isLoginComplete
  } = useGenerateToken();

  const setIsError = (arg: boolean) => {
    console.log(arg);
  };

  const resetErrorState = () => {
    setIsError(false);
  };

  const handleValidateMagicLink = async ({
    uuid,
    invitation_token
  }: ValidateMagicLinkRequest) => {
    const { code } = await getAuthDataFromStorage(['code']);
    mutate(
      { uuid, invitation_token, code },
      {
        onSuccess: (response: SignInResponse) => {
          if (response?.login_session_expired === true) {
            handleSessionExpired();
          } else {
            if (response?.data?.errors) {
              setIsError(true);
            }
            if (response?.meta?.code && response?.meta?.synaptic_session_exp) {
              setAuthDataToStorage({
                code: response?.meta?.code,
                synaptic_session_exp: response?.meta?.synaptic_session_exp
              });
            }
            if (response?.data?.authorization_code) {
              handleGenerateToken({
                authorization_code: response?.data?.authorization_code,
                signInMedium: 'magic_link'
              });
            }
          }
        }
      }
    );
  };

  return {
    isLoading: isLoading || isTokenLoading,
    validateMagicLinkResponse,
    handleValidateMagicLink,
    isError: isError || isGenerateTokenError,
    isLoginComplete,
    resetErrorState
  };
};
