// Tuxedo imports
import Text, { TextProps } from '../text';
import themeGet from '../../../utils/theme-get';
import cleanComponentProps from '../../../utils/cleanComponentProps';

export interface ParagraphProps extends TextProps {
  maxLines?: number;
}

const Paragraph = cleanComponentProps(Text, ['maxLines'])<ParagraphProps>`
  line-height: ${props =>
    themeGet(`longformLineHeights.${props.fontSize}`) ||
    themeGet(`singleLineHeights.${props.fontSize}`)}px;
  ${props =>
    props.textOverflow === 'ellipsis' &&
    `
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    vertical-align: middle;
    -webkit-line-clamp: ${props.maxLines};
    -webkit-box-orient: vertical;
    `}
`;

Paragraph.defaultProps = {
  fontSize: 1,
  maxLines: 1
};

Paragraph.displayName = 'Paragraph';

export default Paragraph;
