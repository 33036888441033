import getRandomId from 'shared/utils/getRandomId';
import moment from 'moment';
import { Window } from 'shared/types';

declare const window: Window;

const SESSION_ID_LIFETIME_MIN = 15;
const SESSION_ID_LIFETIME = SESSION_ID_LIFETIME_MIN * 60 * 1000;

let refreshTimer: NodeJS.Timeout | null = null;

export function generateAnalyticsSessionId(): void {
  const analyticsSessionIdExpire = moment(
    window.localStorage.getItem('analyticsSessionIdExpire') ||
      moment().format(moment.defaultFormat)
  );

  if (moment().isAfter(analyticsSessionIdExpire)) {
    // Session has expired
    const analyticsSessionId = getRandomId();

    window.localStorage.setItem('analyticsSessionId', analyticsSessionId);
    const event = new Event('newSessionStarted');
    document.dispatchEvent(event);
  }
  window.localStorage.setItem(
    'analyticsSessionIdExpire',
    moment().add(SESSION_ID_LIFETIME_MIN, 'minute').format(moment.defaultFormat)
  );
  if (refreshTimer) {
    clearTimeout(refreshTimer);
  }
  refreshTimer = setTimeout(() => {
    const event = new Event('sessionEnded');
    document.dispatchEvent(event);
    window.localStorage.setItem('analyticsSessionId', 'null');
  }, SESSION_ID_LIFETIME);
}

export function getAnalyticsSessionId(): string | null {
  return window.localStorage.getItem('analyticsSessionId');
}

export function resetAnalyticsSessionIdTimer(): void {
  // not to be implemented
}

export function clearAnalyticsSessionIdTimer(): void {
  if (refreshTimer) {
    clearTimeout(refreshTimer);
  }
}

export function startAnalyticsSessionIdTimer(): void {
  // not to be implemented
}
