import cleanComponentProps from '../../../utils/cleanComponentProps';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import { FONT_COLOR_TYPE, FONT_WEIGHT_TYPE } from 'theme-v2/types';
import { TextOverflowProp } from '../../../props';

interface HeadingBasePropTypes {
  weight: FONT_WEIGHT_TYPE;
  color: FONT_COLOR_TYPE;
  textOverflow?: TextOverflowProp;
}

const headingGenerator = (
  el: keyof JSX.IntrinsicElements,
  fontSize: number,
  lineHeight: number
) => {
  const Component = cleanComponentProps<HeadingBasePropTypes>(el, [
    'theme',
    'color',
    'weight',
    'textOverflow'
  ])`
    ${props => `font-size: ${themeGet(`text.fontSizes.${fontSize}`)(props)};`}
    ${props =>
      `font-weight: ${themeGet(`text.fontWeights.${props.weight}`)(props)};`}
    ${props =>
      `line-height: ${themeGet(`text.lineHeights.${lineHeight}`)(props)};`}
    ${props =>
      `color: ${themeGet(`colors.text.${props.theme.themeMode}.${props.color}`)(
        props
      )};`}
      ${({ textOverflow }) =>
        textOverflow &&
        `overflow: hidden; white-space:nowrap; text-overflow: ${textOverflow};`}
  `;
  return WithV2Theme(Component);
};

const SizeMap = {
  xLarge: headingGenerator('h1', 600, 700),
  large: headingGenerator('h2', 500, 500),
  medium: headingGenerator('h3', 400, 300),
  small: headingGenerator('h4', 300, 200)
};

const Heading = ({
  size = 'large',
  weight = 'semi-bold',
  color = 'text1',
  textOverflow,
  title,
  children
}: {
  children: React.ReactNode;
  size?: keyof typeof SizeMap;
  weight?: FONT_WEIGHT_TYPE;
  color?: FONT_COLOR_TYPE;
  textOverflow?: TextOverflowProp;
  title?: string;
}): JSX.Element => {
  const Component = SizeMap[size] as React.ElementType;
  return (
    <Component
      title={title}
      weight={weight}
      color={color}
      textOverflow={textOverflow}
    >
      {children}
    </Component>
  );
};

Heading.displayName = 'Heading';

export default Heading;
