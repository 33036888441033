import {
  setAuthDataToStorage,
  getAuthDataFromStorage,
  handleSessionExpired,
  isInternetAvailable
} from 'shared/services';

import { useInput } from './useInput';
import { useInternetConnection } from './useInternetConnection';
import {
  useSSOLoginApi,
  SSOLoginResponse
} from 'shared/api/v5/auth/authorizations/auth_url/get';
import { SetInput } from '../types';
import { useModuleState } from '../module-state';
import { useEventTracker } from 'shared/events';
import { UserSignedInEvent } from 'shared/modules/events/platform-events';
import { UserSessionStartedEvent } from 'shared/modules/events/platform-events';

interface Return {
  isLoading: boolean;
  ssoLoginResponse: SSOLoginResponse | undefined;
  handleSsoLogin: ({ provider }: { provider: string }) => void;
  handleGoogleLogin: (callback?: (arg: string) => void) => void;
  isError: boolean;
  isInputError: boolean;
  email: string;
  setEmail: SetInput;
  isValidEmail: boolean;
  errorTextEmail: string;
  statusLabel: string;
  statusSubLabel: string;
  hideErrorState: () => void;
  google_auth_url: string;
}

export const useSsoLogin = (
  successCallback?: (arg: string) => void
): Return => {
  const [
    {
      isErrorSsoLogin: isError,
      statusLabelSsoLogin: statusLabel,
      statusSubLabelSsoLogin: statusSubLabel,
      google_auth_url
    },
    setModuleState
  ] = useModuleState();
  const { mutate, data: ssoLoginResponse, isLoading } = useSSOLoginApi();
  const [email, setEmail, isValidEmail, errorTextEmail, touched, setTouched] =
    useInput('email');
  const { setIsOfflineErrorShown } = useInternetConnection();

  const hideErrorState = () => {
    setModuleState({ isErrorSsoLogin: false });
    setTouched(false);
  };

  const handleGoogleLogin = async (callback?: (auth_url: string) => void) => {
    if (google_auth_url && callback) {
      callback(google_auth_url);
    } else {
      const { code } = await getAuthDataFromStorage(['code']);
      mutate(
        { code, provider: 'google' },
        {
          onSuccess: (response: SSOLoginResponse) => {
            if (response?.login_session_expired === true) {
              handleSessionExpired();
            } else if (response?.data?.auth_url) {
              const auth_url = response?.data?.auth_url;
              setModuleState({ google_auth_url: auth_url });
              if (callback) {
                callback(auth_url);
              }
            }
          }
        }
      );
    }
  };

  const [trackEvent] = useEventTracker();

  const handleSsoLogin = async ({ provider }: { provider: string }) => {
    const isOnline = await isInternetAvailable();
    if (isOnline) {
      setTouched(true);
      const { code } = await getAuthDataFromStorage(['code']);
      if (isValidEmail) {
        mutate(
          { email, code, provider },
          {
            onSuccess: (response: SSOLoginResponse) => {
              if (response?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                if (
                  response?.meta?.code &&
                  response?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: response?.meta?.code,
                    synaptic_session_exp: response?.meta?.synaptic_session_exp
                  });
                }
                if (response?.data?.is_error === true) {
                  setModuleState({
                    isErrorSsoLogin: true,
                    statusLabelSsoLogin:
                      response?.data?.additional_messages?.attempts ||
                      'Authentication failed',
                    statusSubLabelSsoLogin:
                      response?.data?.additional_messages?.subtext ||
                      `Looks like you entered the wrong credentials or your organization does not support login via ${provider}. Please try again`
                  });
                }
                if (response?.data?.auth_url) {
                  const auth_url = response?.data?.auth_url;

                  if (successCallback) {
                    successCallback(auth_url);
                    trackEvent(UserSessionStartedEvent);
                    trackEvent(UserSignedInEvent, {
                      event_module: 'misc',
                      event_source: 'sign_in',
                      event_medium: null,
                      sign_in_medium: provider
                    });
                  }
                }
              }
            },
            onError: (error: { data: SSOLoginResponse }) => {
              const { data: errorResponse } = error;
              if (errorResponse?.login_session_expired === true) {
                handleSessionExpired();
              } else {
                if (
                  errorResponse?.meta?.code &&
                  errorResponse?.meta?.synaptic_session_exp
                ) {
                  setAuthDataToStorage({
                    code: errorResponse?.meta?.code,
                    synaptic_session_exp:
                      errorResponse?.meta?.synaptic_session_exp
                  });
                }
                setModuleState({
                  isErrorSsoLogin: true,
                  statusLabelSsoLogin:
                    errorResponse?.data?.additional_messages?.attempts ||
                    'Authentication failed',
                  statusSubLabelSsoLogin:
                    errorResponse?.data?.additional_messages?.subtext ||
                    `Looks like you entered the wrong credentials or your organization does not support login via ${provider}. Please try again`
                });
              }
            }
          }
        );
      }
    } else {
      setIsOfflineErrorShown(true);
    }
  };

  const isInputError = touched && (isError || !isValidEmail);

  return {
    isLoading,
    ssoLoginResponse,
    handleSsoLogin,
    isError,
    email,
    setEmail,
    isValidEmail,
    errorTextEmail,
    isInputError,
    statusLabel,
    statusSubLabel,
    hideErrorState,
    google_auth_url,
    handleGoogleLogin
  };
};
